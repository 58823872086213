import React, { useState } from 'react'
import {Link} from 'react-router-dom';
import './home.css';
import logo_png from '../../Components/Assets/logo.png';
import {Footer} from '../../Components/Layout/footer';


export const FAQ = () =>{
    return(
        <>
            <section className="p-5 header-section">
                <div className="container header-container">
                    <Link to="/" className="flex">
                        <div className="logo-container mr-3">
                            <img src={logo_png}/>
                        </div>
                        <h4 className="flex-5 text-bold">TOKENFY</h4>
                    </Link>
                </div>
            </section>
            <section className="p-5">
                <div className="container">
                    <h1 className="text-center mb-5">FAQ</h1>
                    <div className="flex">
                        <div className="flex-1" id="terms-and-conditions">
                            <div id="accordion">
                                {
                                    [
                                    {
                                        que : "What is an Online Coupon?",
                                            ans : `Online coupons are a set of alphabets or numbers that are used only over the Internet at e-retailers to help consumers receive a discount on a specific product or on the entire order value. Coupons are issued by manufacturers/stores/brands to give consumers immediate discounts on the purchase of the specified items, which may be virtually any product found in retail stores. These coupons are usually a combination of letters, numbers or any special characters. Consumers enter this coupon code on a site’s checkout page to avail a discount (such as a percentage off, free shipping or any other promotional offer) on their order(s).
                                            NOTE: They are only valid online and not in any retail store. These are also known as promo codes, digital coupon, promotional code, e-coupon or voucher code.`
                                    } ,
                                    {
                                            que :   `How can I use coupons?`,
                                            ans : `On any store coupon page at Tokenfy – you have to click on the “SHOW COUPON CODE” button. We’ll copy the code for you and open the respective offer page on the store’s website. While you check out on the store’s website, paste the code in the promo/coupon code field and click on apply. Review your updated cart and final price to ensure it reflects the coupon savings.
                                            NOTE: In some cases, a particular discount can be availed by going to a special offer page and doesn’t need a specific coupon code. In such cases, clicking on ‘SHOW COUPON CODE’ will automatically activate the offer and we’ll take you to the store’s specific offer page.`
                                    },
                                    {
                                        que : 'What if a coupon doesn’t work?',
                                        ans : `We make our best effort to ensure that all our coupons are tested and valid. However, there are certain situations which could lead to a non-working coupon code:
                                        A coupon code may be limited to one-time use per user.
                                        A coupon code might have a limited number of uses which causes it to expire before the set expiry date.
                                        Expiration of coupons earlier than the original expiration date.
                                        The participating vendor, merchant or store terminates the coupon without prior notification
                                        In order to provide the most pleasant & satisfying experience to you, we at Tokenfy, rely on your inputs using our rating and commenting systems. Every coupon can be rated and commented by any visitor and typically a non-working coupon would get a bunch of negative ratings (or comments) with a few hours. Our system will automatically demote such coupons over time, but it should also help you identify and avoid them.
                                        NOTE: It is important to note that we are not responsible for the accuracy of any coupons listed on our site. We rely on the individual merchants, vendors and stores to maintain the accuracy of these coupons. To learn more, please read our disclaimer.`
                                    },
                                    {
                                        que :  `Are the deals posted by Tokenfy legit?`,
                                        ans : `Absolutely! We personally test every deal, promotion,	or coupon before posting to ensure the offer is valid. This means there are no scams or questionable offers on Tokenfy (and if there are deals we’re iffy on, we mark them with a Warning label so you can use your own judgement).
                                        However, please note that we are a team of imperfect human beings that are posting deals behind our computer screens, so errors may happen on occasion. We’re human. But we strive to ensure that every deal is something that we would each be personally interested in snagging ourselves.
                                        Also, on occasion, some deals do not work out as anticipated (i.e. orders get canceled, coupon codes do not work, etc.). Although this can be extremely frustrating, Tokenfy cannot be held responsible when this happens.`  
                                    },
                                    {
                                            que : `What is a single use code?`,
                                            ans : `Some coupons may be for single use only. These unique codes are codes that are generated for a one time use.`
                                    },
                                    {
                                            que : `I found an offer that is no longer listed. What happened?`,
                                            ans  : `Offers may be removed if they become invalid. This is rare, but it can happen. Generally it's because the coupon is no longer valid and our staff removed it.`
                                    },
                                    {
                                        que : `What does it mean when a coupon doesn't have a code?`,
                                        ans : `Some coupons may not have listed codes and you can receive that discount by just clicking the "Get Deal" button, then being directed to the retailers site.
                                        If you see an offer without a coupon code, this offer does not require a code to receive the discount. This could be free shipping or a sale notification. 
    To use these offers: 
    Click the blue "Get Deal" button next to the offer details. You will be directed to the retailers page to complete your purchase.
    If the offer is a sale, the discount will already be factored into the listed product price. If the offer is for free shipping, it will be factored in if you meet the offer requirements.

                                        `
                                    },
                                    {
                                            que : `How do I add a comment?`,
                                            ans : `Every coupon/offer on our site has a comment section – where you can share your experience by leaving a comment. We strongly encourage you to comment about your experience and help us improve our system with your valuable feedback. Remember that sharing your experience is a way to care about the community of other online shoppers. Typical comments include feedback like how much you saved on your order using this coupon, or simple appreciation on what you saved.`
                                    },
                                    {
                                            que : `How does Tokenfy make money?`,
                                            ans : `Our team researches and validates all deals and offerings posted on Tokenfy. Note that some deals involve products or services sold by merchants that have affiliate programs. If we find a product or service, which is a great deal, we will refer you to the product or service with an affiliate link.
                                            Tokenfy may get paid a commission if you use the link and/or buy the product.`
                                    },
                                    {
                                            que : `How do I find coupons for a specific store?`,
                                            ans : `There are two ways you can find coupons for your favorite store.
                                            Using the Explore search box at the top section of our mobile Application – you can search by store name, product name, specific deal/offer or coupon code.
                                            Using the Explore Page and navigating to your favorite store.`
                                    },
                                    {
                                        que :  `I found a great deal. How can I send it to you?`,
                                        ans : `If you’ve found a great deal while out shopping or have a money-saving tip, we want to hear about it! Just shoot us an email to deals@tokenfy.io and let us know about it!
                                        If you’re interested in working with Tokenfy to advertise or feature your brand, please submit your advertising inquiries or press requests by emailing us at advertise@tokenfy.io`
                                    }
                                    ].map((item,index)=>(
                    
                        <div className="br-3 mb-3">
                            <div className="card-header" id="headingOne">
                            <h5 className="mb-0">
                                <button className="btn btn-link" data-toggle="collapse" data-target={`#collapseOne-${index}`} aria-expanded="true" aria-controls="collapseOne">
                                        {item.que}
                                </button>
                            </h5>
                            </div>

                            <div id={`collapseOne-${index}`} className={`collapse ${index === 0 ? '' : ''}`} aria-labelledby="headingOne" data-parent="#accordion">
                            <div className="card-body">
                                {item.ans}
                            </div>
                            </div>
                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
           <Footer />
        </>
    )
}