import React,{useState} from 'react'
import {Layout} from '../../Components/Layout/layout';
import "react-datepicker/dist/react-datepicker.css";
import {httpGet, httpPatch, httpPostFormData,httpPost, httpDelete, httpPut} from '../../Components/helpers/httpMethods'
import {hideLoader, showLoader} from '../../Components/helpers/loader'
import {NotificationManager} from 'react-notifications'
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

export default function CreateCategory(props) {
    const [category, setCategory] = useState({
        category: "",
        description: ""
    })
    const {category_id} = useParams();
    const handleSubmit=async(e)=>{
        e.preventDefault();
                
           e.preventDefault();
           try {
                showLoader();
                if(category_id){
                    await httpPut(`/admin/categories/${category_id}`,category);
                    hideLoader();
                    return NotificationManager.success('Category has been updated');
                }
                let res = await httpPost("admin/create/category",category)
                console.log("res status",res) 
                hideLoader()
                setCategory({
                    category: "",
                    description: ""
                })
                NotificationManager.success(
                    "category created successfully",
                    "Yepp",
                    4000
                );
               hideLoader()
         } catch (error) {
             error.data ? NotificationManager.error(
                 error.data.message,
                "Opps",
                3000
            ) : NotificationManager.error("Network Error. Please retry");
             hideLoader()
        }
    }
       
    const  handleChange=(e)=>{
        setCategory({...category, [e.target.name]: e.target.value })
    }
    const getCategory = async () =>{
        try{
            showLoader();
            let res = await httpGet(`/admin/categories/${category_id}`);
            setCategory(res.categoryExist);
            hideLoader();
        }catch(error){
            hideLoader();
            error.data ? NotificationManager.error(error.data.message) : NotificationManager.error('Opps! Please check your internet');
        }
    }

    useEffect(()=>{
        if(category_id){
            getCategory();
        }
    },[]);
    
    return (
        <form onSubmit={handleSubmit}>
            <Layout pageName={category_id ? 'Edit': 'Create'} subPageName="Category">
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="">Category Title<span>*</span></label>
                            <div className="inputbox-cupon">
                                <input value={category.category} name="category" onChange={handleChange} required type="text" className="form-control"/>
                            </div>
                        </div>
                    </div>
                   <div className="col-md-6">
                       <div className="form-group">
                            <label htmlFor="">Descriptions<span>*</span></label>
                            <div className="inputbox-cupon">
                                <textarea value={category.description}  name="description" onChange={handleChange} required type="text" className="form-control" rows='5' cols='6' />
                                <span className="text-primary">500 <span>Characters left.</span></span>
                            </div>
                       </div>
                   </div>
                   <div className="col-md-12 pr-5">
                        <button className="btn btn-primary mr-3 pull-right">Submit</button>
                    </div>

                </div>
        
            </Layout>
        </form>
    )
}


