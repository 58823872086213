import axios from "axios";
import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router";

const DefaultLayout = ({ children }) => {
  const history = useHistory();
  const authUser = async () => {
    try {
      console.log("local_storage",localStorage.api_token)
      let token = JSON.parse(localStorage.getItem("api_token"));
      if (!token) return history.push("/login");
    } catch (error) {
      // localStorage.clear();
      // return history.push("/login");
    }
  };
  useEffect(() => {
    authUser();
  },[]);
  return (
    <React.Fragment>
        {children}
    </React.Fragment>
  );
};

export default DefaultLayout;