import React,{useState,useEffect} from 'react'
import {Layout} from '../../Components/Layout/layout';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Link, useParams} from 'react-router-dom';
import User from '../../Components/Assets/user.png'
import EditPNG from '../../Components/Assets/icons/white_edit.png';
import { httpGet,httpDelete,httpPatch } from '../../Components/helpers/httpMethods';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import { hideLoader, showLoader } from '../../Components/helpers/loader';
import ComfirmBlock from '../../Components/Modals/blockModal';
import moment from 'moment';

import {
    ComposedChart,Bar, XAxis, YAxis, Tooltip,
    Legend,
} from 'recharts';

export default function UserDetails(props) {
    const {user_id} = useParams();
    const getUserInfomation = async () => {
        try{
            showLoader();
            let res = await httpGet(`admin/user/${user_id}`);
            setUser(res);
            setTopClaims([...new Set(res.claims)].splice(0,4).map((value)=>{
                return {
                    name : value.Coupon.Category.category,
                    claims : res.claims.filter(item=>item.id === value.id).length
                }
            }))
            hideLoader();
        }catch(error){
            hideLoader();
            error.data ? NotificationManager.error(error.data.message) : NotificationManager.error("Network Error. Please retry again");
        }
    }
    const [user,setUser] = useState({});
    const [topClaims,setTopClaims] = useState([]);
    const [activities,setActivities] = useState([]);

    const DeleteData = async()=>{
        showLoader()
        try {
            const res = await httpDelete(`admin/user/${user_id}`)
            hideLoader()
            NotificationManager.success(
                "deleted successfully",
                "Yepp",
                3000
            );
            props.history.goBack()
        } catch (error) {
            hideLoader()
            console.log(error)
        }
    }

    const DeactivateOrActivate = async () =>{
        try{
            let fd = {
                action : user.activeAsUser ? Number(0) : Number(1),
                userId : user.id
            }
            let res = await httpPatch('admin/activate_or_deactivate/user',fd);
           if(res.status === 'success'){
            setUser({...user,activeAsUser : !user.activeAsUser})
           }
        }catch(error){
            NotificationManager.error(error.message);
        }
    }

    const getActivities = async ()=>{
        try{
            showLoader();
          let res = await httpGet(`/admin/user/${user_id}/recent_activities`);
          setActivities(res);
          hideLoader();
        }catch(error){
            console.log(error);
            NotificationManager.error("Network Error. Please try again");
        }
    }

    useEffect(()=>{
        getUserInfomation();
        getActivities()
    },[]);
    return (
        <div>
            <Layout pageName="Overview" subPageName="User">
            <div className="viewCupon-actions">
                    <div className="edit-btn-cupon">
                        {/* <Link to="/edit_coupon">
                        <button>Edit  <div class="icon-image-container"><img src={EditPNG}/></div></button>
                        </Link> */}
                    </div>

                    <div className="Deactivate-btn-cupon">
                        <button 
                            className={!user.activeAsUser && 'activate-button'}
                            onClick={DeactivateOrActivate}
                        >{user.activeAsUser ? 'Deactivate' : 'Activate'} <span className={`fa ${user.activeAsUser ? 'fa-times-circle-o' : 'fa-check'}`}></span></button>
                        <button data-toggle="modal" data-target="#BlockModal">Delete <span className="fa fa-trash"></span></button>
                    </div>
                </div>

                <div className="cupon_statics">
                    <div className="cuponImg">
                        <img src={user.avatar}/>
                            <div className="about-viewCupon">
                                <p>{user.firstName} {user.lastName}</p>
                                    {
                                        user.activeAsUser && (
                                            <button>Active</button> 
                                        )
                                    }
                            </div>
                    </div>
                    <div className="cupon-detailsbe7">
                        <p> <span>Phone Number:</span>  {user.phone}</p>
                        <p style={{color:"#2D4EF5"}}> <span >Email:</span>   {user.email}</p>
                    </div>

                    <div className="cupon-detailsbe7">
                        <p><span>Location:</span>  {user.location}</p>
                        <p><span>Tokenfy ID:</span>   {user.tokenfyId}</p>
                    </div>

                   
                </div>

                <section className="page-section">
                    <div className="row">
                        <div className="col-md-6 col-xs-12 p-0">
                            <div className="card bg-dark mt-3">
                                <h3 className="text-white">Wallet Balance</h3>
                                <h1 className="bold-550 text-white mt-5 mb-5 text-center"><span className="bold-550">
                                &#x20A6;</span>{new Intl.NumberFormat('en-US').format(user.wallet ? user.wallet.balance : 0)}
                                </h1>
                            </div>
                            <Link to={`/wallet/${user.id}`} className="btn btn-warning btn-block mt-3">View Wallet</Link>
                        </div>
                        <div className="col-md-6 col-xs-12">
                            <div className="card mt-3 top-claims">
                                <h5 className="text-muted bold-550">Top Claimed Categories</h5>
                                <ComposedChart
                                    layout="vertical"
                                    width={550}
                                    height={400}
                                    data={topClaims}
                                    margin={{
                                    top: 20, right: 20, bottom: 20, left: 20,
                                    }}
                                >
                                    <XAxis type="number" />
                                    <YAxis dataKey="name" type="category" />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="claims" barSize={20} fill="#2D4EF5" />
                                </ComposedChart>
                            </div>
                        </div>
                    </div>

                </section>

                <div className="cupon-claimers-overview mt-3">
                    <span>Coupons</span>
                </div>
                <div className="Table-sec">
                  <table id="dtBasicExample" class="table table-striped table-bordered" cellspacing="0" width="100%">
                    <thead className="mb-20">
                        <tr>
                            <th className="th-sm">MERCHANT
                            </th>
                            <th className="th-sm">TYPE
                            </th>
                            <th className="th-sm">
                                CATEGORY
                            </th>
                            <th className="th-sm">
                                OFFER
                            </th>
                            <th className="th-sm">
                                TAKE DATE
                            </th>
                            <th className="th-sm">
                                EXPIRING
                            </th>
                            <th className="th-sm">
                                STATUS
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                             user.claims && user.claims.map((claim,index)=>(
                                <tr key={index}>
                                    <td>
                                        <p>{claim.Coupon ? claim.Coupon.Merchant.merchantName : ""}</p>
                                    </td>
                                    <td>
                                        <p>{claim.Coupon.CouponType.couponType}</p>
                                    </td>
                                    <td>
                                        <p>{claim.Coupon.Category.category}</p>
                                    </td>
                                    <td>
                                        <p>{claim.Coupon.percentageAmount}</p>
                                    </td>
                                    <td>
                                        <p>{moment(claim.Coupon.claimedDate).format('DD/MM/YYYY')}</p>
                                    </td>
                                    <td>
                                        <p>{moment(claim.Coupon.endDate).format('DD/MM/YYYY')}</p>
                                    </td>
                                    <td>
                                        <button className={claim.Coupon.status ? `restore-action-button mr-3` : `disable-action-button mr-3`}>{claim.Coupon.status ? `Active` : `Inactive`}</button> 
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                    </table>

                  </div>

                  <div className="marchant-recents-data">
                    <h1>Recent Activities</h1>
                    {
                        activities.map((activity,index)=>(
                            <div className="marchant-recents-data-wrap" key={index}>
                                <div className="mes-merchant">
                                    <h3>{activity.title}</h3>
                                    <h2>“{activity.activity}”</h2>
                                </div>
                                <div className="mes-merchant-time">
                                    {moment(activity.createdAt).fromNow()}
                                </div>
                            </div>
                        ))
                    }

                  </div>
            </Layout>
            <ComfirmBlock deletData={DeleteData} />
        </div>
    )
}


