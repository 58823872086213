import React,{useState} from 'react'
import {Layout} from '../../Components/Layout/layout'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import calendar from '../../Components/Assets/calendar.png'
import Remove from '../../Components/Assets/close.png'
import { Link } from "react-router-dom";
import DownloadPNG from '../../Components/Assets/d_load.png';
import SendPNG from '../../Components/Assets/send.png';
import Devicebg from '../../Components/Assets/device_bg.png';
import { useEffect } from 'react';
import { httpGet, httpPost } from '../../Components/helpers/httpMethods';
import { showLoader, hideLoader } from '../../Components/helpers/loader';
import {NotificationManager} from 'react-notifications';



export default function PushNotifications(props) {
    const [activePage, setActivePage] = useState("Coupon_Properties")
    const [startDate, setstartDate] = useState(new Date());
    const [coupons,setCoupons] = useState([]);
    const [channels,setChannels] = useState([]);
    const [data,setData] = useState({
        title : "",
        mesaage : "",
        channel : "",
        deal_id : ""
    });
    
    const sendNotification = async (event) => {
        try{
            event.preventDefault();
            if(
                data.channel.trim() === ""
                || data.title.trim() === "" 
                || data.message.trim() === ""
            ){
                return NotificationManager.error("Channel, message and title must be set");
            }
            showLoader();
            let fd = {
                ...data,
                "notificationData": {
                    "id": data.deal_id
                }
            };
            let url = 'notifications/channel/send';
            // if(data.channel === 'general'){
            //     let fd = {
            //         ...data,
            //         "notificationData": {
            //             "id": data.deal_id
            //         }
            //     };
            //    //url = 'notifications/devices/send';
            // }
            await httpPost(url,fd);
            hideLoader();
            setData({
                title : '',
                message : '',
                channel : '',
                deal_id  : ""
            });
            NotificationManager.success("Notification has been sent");
        }catch(error){
            hideLoader();
            NotificationManager.error(error.message);
        }
    }
    const getChannels = async () => {
        try{
            showLoader();
            let coupons = await httpGet(`admin/coupons/?page=${1}&limit=1000`);
            setCoupons(coupons.data)
            let res = await httpGet('notifications/channels');
            setChannels(res);
            hideLoader();
        }catch(error){
            hideLoader();
            NotificationManager.error(error.message);
        }
    }
    useEffect(()=>{
        getChannels();
    },[])
    return (
        <Layout pageName="Push Notifications" subPageName="">
            <section className="page-section">
                <form>
                    <div className="row">
                        <div className="col-md-6 col-lg-3">
                            <div className="form-group">
                                <label className="bold-550">Coupon  <sup className="text-danger">*</sup></label>
                                <select className="form-control"
                                     onChange={(e)=>setData({...data, deal_id : e.target.value })}
                                     value={data.deal_id}
                                >
                                    <option value="">Select option</option>
                                    {
                                        coupons.map((coupon,index)=>(
                                            <option value={coupon.id}>{coupon.code}</option>
                                        ))
                                    }
                                </select>
                                <span className="fa fa-angle-down select-arrow"></span>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="form-group">
                                <label className="bold-550">Audience <sup className="text-danger">*</sup></label>
                                <select className="form-control"
                                    onChange={(e)=>setData({...data, channel : e.target.value })}
                                    value={data.channel}
                                >
                                    <option value="">Select option</option>
                                    <option value="general">general</option>
                                    {
                                        channels.map((item,index)=>(
                                        <option value={item.name} key={index}>{item.name}</option>
                                        ))
                                    }
                                </select>
                                <span className="fa fa-angle-down select-arrow"></span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-lg-6">
                            <div className="option-container">
                                <p>Top</p>
                                <span className="fa fa-angle-up select-arrow"></span>
                                <div className="postions-display-container mt-5">
                                    {
                                        [...'1234'].map((item,index)=>(
                                            <div className={index === 0 ? `active-postion-display-container postion-display-container` : `postion-display-container`} key={index}>
                                                <div className={index === 0? `active-postion postion`: `postion`}>
                                                    <div className={index === 0? `active-shaded-object shaded-object` : `shaded-object`}></div>
                                                </div>
                                                <p className="text-center mt-2">{index === 0 ? 'Top' : index === 1 ? 'Center' : index === 2 ? 'Bottom' : 'Full'}</p>
                                            </div>
                                        ))
                                    }      
                                </div>
                            </div>
                            <div className="mt-3">
                                <label>Title</label>
                                <input name="title" value={data.title} onChange={(e)=>setData({...data,title : e.target.value})}
                                    className="form-control"
                                />
                            </div>
                            <div className="mt-3">
                                <label>Message</label>
                                <textarea  name="message" className="area-control" onChange={(e)=>setData({...data,message : e.target.value})} value={data.message}/>
                                <h5 className="mt-2"><span className="character-count">500</span> <span className="character-naming">Characters</span></h5>
                            </div>

                            <div className="mt-3 tool-container">
                                <div className="tool">
                                    <label>Color</label>
                                    <div className="color-picker-container">
                                        <input type="text" className="color-code form-control" value="#e560699"/>
                                        <input type="color" className="form-control color-picker"/>
                                    </div>
                                </div>
                                <div className="tool">
                                    <label className="bold-550">Size</label>
                                    <select className="form-control">
                                        <option>Select option</option>
                                        <option>1</option>
                                    </select>
                                </div>
                                <div className="tool">
                                    <label className="bold-550">Alignment</label>
                                    <div className="alignments">
                                        <span><i className="fa fa-align-left"></i></span>
                                        <span><i className="fa fa-align-right"></i></span>
                                        <span><i className="fa fa-align-justify"></i></span>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-5">
                                <button type="button" className="acrodion btn-block text-left" data-toggle="collapse" data-target="#collapseExample" aria-expanded="true" aria-controls="collapseExample">
                                    <i className="fa fa-angle-down select-arrow"></i> Image (Disabled)
                                </button>
                                <div className="collapse p-3" id="collapseExample">
                                   <input type="text" className="form-control mb-4" placeholder="image link"/>
                                   <button className="btn btn-primary text-center">
                                        Upload {' '}
                                        <span className="icon-image-container mr-0"><img src={DownloadPNG} /></span>
                                    </button>
                                </div>
                            </div>

                            <div className="mt-4">
                                <button type="button" className="acrodion btn-block text-left" data-toggle="collapse" data-target="#button-settings" aria-expanded="true" aria-controls="button-settings">
                                    <i className="fa fa-angle-down select-arrow"></i> Button (Disabled)
                                </button>
                                <div className="collapse p-3" id="button-settings">
                                    <div className="tool-container mt-2">
                                        <div className="tool">
                                            <label>Button Text</label>
                                            <input type="text" className="form-control"/>
                                        </div>
                                        <div className="tool">
                                            <label>Background Color</label>
                                            <div className="color-picker-container">
                                                <input type="text" className="color-code form-control" value="#e560699"/>
                                                <input type="color" className="form-control color-picker"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tool-container mt-3">
                                        <div className="tool">
                                            <label>Color</label>
                                            <div className="color-picker-container">
                                                <input type="text" className="color-code form-control" value="#e560699"/>
                                                <input type="color" className="form-control color-picker"/>
                                            </div>
                                        </div>
                                        <div className="tool">
                                            <label className="bold-550">Size</label>
                                            <select className="form-control">
                                                <option>Select option</option>
                                                <option>1</option>
                                            </select>
                                            {/* <span className="fa fa-angle-down select-arrow"></span> */}
                                        </div>
                                        <div className="tool">
                                            <label className="bold-550">Alignment</label>
                                            <div className="alignments">
                                                <span><i className="fa fa-align-left"></i></span>
                                                <span><i className="fa fa-align-right"></i></span>
                                                <span><i className="fa fa-align-justify"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <p className="mt-3">
                                        <input type="checkbox"/> {'  '} Dismiss on click
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-6 notification-image-container">
                            <img src={Devicebg}/>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-md-12">
                            <button className="btn btn-primary btn-block" type="button">
                                Send to test device {'   '}
                                <span className="icon-image-container mr-0">
                                    <img src={SendPNG} />
                                </span>
                            </button>
                        </div>
                        <div className="col-md-12">
                            <div className="mt-5">
                                <button type="button" className="acrodion btn-block text-left" data-toggle="collapse" data-target="#triger-settings" aria-expanded="true" aria-controls="triger-settings">
                                    <i className="fa fa-angle-down select-arrow"></i> Triggers
                                </button>
                                <div className="collapse p-3" id="triger-settings">
                                   <p className="text-muted mb-2">When should users see this message?</p>
                                   <p className="mb-2">
                                       <input type="radio" name="trigger" /> On app open
                                   </p>
                                   <p className="mb-2">
                                        <input type="radio" name="trigger" /> When certain conditions are satisfied during the session
                                   </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="mt-5">
                                <button type="button" className="acrodion btn-block text-left" data-toggle="collapse" data-target="#schedule-settings" aria-expanded="true" aria-controls="schedule-settings">
                                    <i className="fa fa-angle-down select-arrow"></i> Schedule
                                </button>
                                <div className="collapse p-3" id="schedule-settings">
                                    <div className="tool-container mt-3">
                                        <div className="tool">
                                            <label htmlFor="">Start Date <span>*</span></label>
                                            <div className="inputbox-cupon">
                                                <DatePicker
                                                // selected={startDate}
                                                // onChange={date => setStartDate(date)}
                                                minDate={new Date()}
                                                />
                                                <div className="icon"><img src={calendar}></img></div>   
                                            </div>
                                        </div>
                                        <div className="tool">
                                            <label htmlFor="">End Date <span>*</span></label>
                                            <div className="inputbox-cupon">
                                                <DatePicker
                                                // selected={startDate}
                                                // onChange={date => setStartDate(date)}
                                                minDate={new Date()}
                                                />
                                                <div className="icon"><img src={calendar}></img></div>    
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tool-container mt-3">
                                        <div className="tool inputbox-cupon">
                                            <label>Start Time</label>
                                            <input type="time" className="form-control"/>
                                        </div>
                                        <div className="tool inputbox-cupon">
                                            <label>End Time</label>
                                            <input type="time" className="form-control "/>
                                        </div>
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <button className="btn btn-primary mt-4" onClick={sendNotification}>Publish</button>
                        </div>
                    </div>
                </form>
            </section>
                
        </Layout>
    )
}


