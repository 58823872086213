import React,{useState} from 'react'
import {Layout} from '../../Components/Layout/layout'
import './admin-tasks.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Link} from 'react-router-dom';
import Avatar from '../../Components/Assets/user.png';
import AdminsTable from '../../Components/Tables/Admins';
import { useEffect } from 'react';
import { httpGet,httpDelete,httpPatch } from '../../Components/helpers/httpMethods';
import {NotificationManager} from 'react-notifications';
import { hideLoader, showLoader } from '../../Components/helpers/loader';
import ActivateAndDeactivateModal from '../../Components/Modals/ActivateAndDeactivate';
import { getLocalItem } from '../../Components/helpers/authService';
import BlockModal from '../../Components/Modals/blockModal';


const AdminList = () => {

    const [admins,setAdmins] = useState([]);
    const [admin,setAdmin] = useState({});
    const [user,setUser] = useState({});

    const DeleteData = async()=>{
        try {
            if(user.id === admin.id){
                return NotificationManager.error("Sorry. You can not delete yourself from list");
            }
            showLoader();
            const res = await httpDelete(`admin/delete/${admin.id}`)
            hideLoader()
            NotificationManager.success(
                "deleted successfully",
                "Yepp",
                3000
            );
            getAdmins();
        } catch (error) {
            hideLoader();
        }
    }
    
    const DeactivateOrActivate = async () =>{
        try{
            let fd = {
                action : admin.activeAsAdmin ? Number(0) : Number(1),
                adminId : admin.id
            }
            let res = await httpPatch('/admin/activate_or_deactivate',fd);
            if(res.status === 'success'){
                getAdmins();
            }
        }catch(error){
            NotificationManager.error(error.message);
        }
    }

    const getAdmins = async () => {
        try{
            showLoader();
            let res  = await httpGet(`admin/all?page=1&limit=1000`);
            setAdmins(res.data);
            setUser(JSON.parse(
                getLocalItem('userInformation')
            ));
            hideLoader();
        }catch(error){
            hideLoader();
            NotificationManager.error(error.data.message);
        }
    }
    useEffect(()=>{
       getAdmins(); 
    },[]);

    return (
        <div>
            <Layout pageName="Admins">
                <section className="page-section">
                    <div className="row">
                        <div className="col-md-8">
                            <h4 className="bold-550">Admin Information</h4>
                        </div>
                        <div className="col-md-4">
                            <Link to="/create-admin" className="btn-primary pull-right"><i className="fa fa-plus"></i> New Admin</Link>
                        </div>
                    </div>
                </section>
                <section className="page-section">
                    <AdminsTable admins={admins} setAdmin={setAdmin} />
                </section>
            </Layout>
            <ActivateAndDeactivateModal  handleActivate={DeactivateOrActivate} status={admin.activeAsAdmin}/>
            <BlockModal deletData={DeleteData} />
        </div>
    )
}
export default AdminList;


