
import React,{useState,useEffect} from 'react'
import {Layout} from '../../Components/Layout/layout'
import './index.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import calendar from '../../Components/Assets/calendar.png'
import user from '../../Components/Assets/user.png'
import {httpGet, httpPatch, httpPostFormData,httpPost, httpDelete, httpPut} from '../../Components/helpers/httpMethods'
import {hideLoader, showLoader} from '../../Components/helpers/loader'
import {NotificationManager} from 'react-notifications'
import axios from 'axios'
import moment from 'moment'
import { Filter } from '@material-ui/icons';
import ValidateImage from '../../Components/helpers/validateImage'
import ValidateVideo from '../../Components/helpers/validateVideo'
import QRCode from 'qrcode.react';
export default function CreateCupon(props) {
    const [activePage, setActivePage] = useState("Coupon_Properties")
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date())
    const [FormatEndDateQr, setFormatEndDateQr] = useState(new Date())
    const [tags,setTags] = useState([]);

   let qrEndDate =  moment(endDate).format("YYYY-MM-DD")
    
    const downloadQR = () => {
        const canvas = document.getElementById("123456");
        const pngUrl = canvas
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = "123456.png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    useEffect(() => {
        getData()
        if (props.match.params.type === "edit") {
            getCoupons() 
        }
       
    }, [])

    const [coupon, setCoupon] = useState([])

    

    const [createCoupon, setCreateCoupon] = useState({
        limits  : "",
        categoryId:"",
        restriction:"",
        couponType :"",
        couponWeblink:"",
        percentageAmount :"",
        code :"",
        mediaFile:"",
        merchantId:"",
        filesData:[],
        videoFile:[],
        restrictions:"",
        editImageFile:"",
        editVideoFile:"",
        pageType:"create",
        bgColor:"",
        qrCodeColor:"",
        logo:"",
        logoUrl:"",
        qrcodeImg:"",
        redeem : "",
        tag : ""
    })


    const  getCoupons =async()=>{
        let id = props.match.params.id
        showLoader();
        try {
          const res = await httpGet(`admin/coupon/${id}`);
          setCreateCoupon({
                ...createCoupon,
                limits  : res.coupon.limits,
                description: res.coupon.description,
                categoryId:res.coupon.categoryId,
                restriction:res.coupon.restriction,
                couponType :res.coupon.couponTypeId,
                couponWeblink:res.coupon.couponWeblink,
                percentageAmount :res.coupon.percentageAmount,
                code :res.coupon.code,
                merchantId:res.coupon.merchantId,
                redeem:res.coupon.redeem,
                code:res.coupon.code,
                tag:res.coupon.tagId,
                pageType:"edit"
            })
            setStartDate(new Date(res.coupon.startDate));
            setEndDate(new Date(res.coupon.endDate))
            hideLoader()
        } catch (error) {
            hideLoader()
        }
    }



    const [getAllCouponType,setgetAllCouponType] = useState([])
    const [getAllcategory,setgetAllcategory] = useState([])
    const [Merchants, settMerchants] = useState([])
    const  getData =async()=>{

        try {
          showLoader()
            const res1 = await httpGet(`coupon_type/retrieve/`)
            const res2 = await httpGet(`admin/categories/`)
            const res3 = await httpGet(`admin/all_merchants?limit=1000`)
            const res4 = await httpGet(`/tag`);
            const all = await axios.all([res1, res2,res3,res4])
            setgetAllCouponType(all[0].couponType)
            setgetAllcategory(all[1].categories)
            settMerchants(all[2]);
            setTags(all[3].tag);
            hideLoader();
        } catch (error) {
          hideLoader()
        }
    }

    const [showQrcode,setShowQrcode] = useState(false)
    const validateInput=()=>{
        if (createCoupon.merchantId === "" || createCoupon.merchantId === null || createCoupon.merchantId === undefined ) {
            setShowQrcode(false)
            NotificationManager.error(
                "Merchant Id is required before qr code can be generated",
                "Opps",
                4000
            ); 
            return false
        }

         if (createCoupon.categoryId === "" || createCoupon.categoryId === null || createCoupon.categoryId === undefined ) {
            setShowQrcode(false)
            NotificationManager.error(
                "category Id is required before qr code can be generated",
                "Opps",
                4000
            ); 
            return false
        }

        if (!createCoupon.tag || !createCoupon.description || !createCoupon.redeem  || !createCoupon.code ||
            createCoupon.tag === "" || createCoupon.description === "" || createCoupon.redeem === "" || createCoupon.code === ""
            ) {
            setShowQrcode(false)
            NotificationManager.error(
                "Fields marked with * are required",
                "Opps",
                4000
            ); 
            return false
        }

         if (createCoupon.couponType === "" || createCoupon.couponType === null || createCoupon.couponType === undefined ) {
            setShowQrcode(false)
            NotificationManager.error(
                "Coupon Type is required before qr code can be generated",
                "Opps",
                4000
            ); 
            return false
        }

        if (createCoupon.couponWeblink === "" || createCoupon.couponWeblink === null || createCoupon.couponWeblink === undefined ) {
            setShowQrcode(false)
            NotificationManager.error(
                "Coupon Web link is required before qr code can be generated",
                "Opps",
                4000
            ); 
            return false
        }

        if (createCoupon.logo === "" || createCoupon.logo === null || createCoupon.logo === undefined ) {
            setShowQrcode(false)
            NotificationManager.error(
                "Logo is required before qr code can be generated",
                "Opps",
                4000
            ); 
            return false
        }

        if (createCoupon.percentageAmount === "" || createCoupon.percentageAmount === null || createCoupon.percentageAmount === undefined ) {
            setShowQrcode(false)
            NotificationManager.error(
                "Discount  is required before qr code can be generated",
                "Opps",
                4000
            ); 
            return false
        }

        if (createCoupon.limits === "" || createCoupon.limits === null || createCoupon.limits === undefined ) {
            setShowQrcode(false)
            NotificationManager.error(
                "Qr Limit is required before qr code can be generated",
                "Opps",
                4000
            ); 
            return false
        }

        else{
            setShowQrcode(true)
            return true
        }
    }

    
    const handleSubmit=async(e)=>{
        if (createCoupon.pageType==="edit") {
            e.preventDefault();
          
            try {
              showLoader()
              const formData = new FormData();
              
              if (createCoupon.logo !== "" ) {
                formData.append('logo',  createCoupon.logo)
            
            }

            if (createCoupon.qrcodeImg !== "" ) {
                formData.append('barcode',  createCoupon.qrcodeImg)
            
            }
              formData.append('merchantId', createCoupon.merchantId );
              formData.append('categoryId',  createCoupon.categoryId);
              formData.append('restriction',  createCoupon.restriction);
              formData.append('couponTypeId',  createCoupon.couponType);
              formData.append('couponWeblink',  createCoupon.couponWeblink);
              formData.append('percentageAmount',  createCoupon.percentageAmount);
              formData.append('limits',  createCoupon.limits);
              formData.append('restrictions',  createCoupon.restrictions);
              formData.append('code',  createCoupon.code);
              formData.append('description',  createCoupon.description);
              formData.append('tagId',  createCoupon.tag);
              formData.append('redeem',  createCoupon.redeem);


              formData.append('startDate', moment(startDate).format("YYYY-MM-DD"));
              formData.append('endDate',  moment(endDate).format("YYYY-MM-DD"));
 
               let res = await httpPut(`qr_coupon/edit/${props.match.params.id}/`,formData)
               console.log("res status",res) 
               if (res.status === 201 || res.status === 200) {
                       hideLoader()
                      
                       getCoupons() 
                       setActivePage("Coupon_Properties")
          
                NotificationManager.success(
                   "Coupon updated successfully",
                  "Yepp",
                  4000
              );
 
              
             
               }
               
              
            
                hideLoader()
          } catch (error) {
              console.log(error.response)
              NotificationManager.error(
                  error.message,
                 "Opps",
                 3000
             );
              hideLoader()
        
          }
        }
        else{
            e.preventDefault();
            try {
                showLoader()
                const formData = new FormData();
              
                if (createCoupon.logo !== "" ) {
                  formData.append('logo',  createCoupon.logo)
              
              }
  
              if (createCoupon.qrcodeImg !== "" ) {
                  formData.append('barcode',  createCoupon.qrcodeImg)
              
              }
                formData.append('merchantId', createCoupon.merchantId );
                formData.append('categoryId',  createCoupon.categoryId);
                formData.append('restriction',  createCoupon.restriction);
                formData.append('couponTypeId',  createCoupon.couponType);
                formData.append('couponWeblink',  createCoupon.couponWeblink);
                formData.append('percentageAmount',  createCoupon.percentageAmount);
                formData.append('limits',  createCoupon.limits);
                formData.append('restrictions',  createCoupon.restrictions);
                formData.append('code',  createCoupon.code);
                formData.append('description',  createCoupon.description);
                formData.append('tagId',  createCoupon.tag);
                formData.append('redeem',  createCoupon.redeem);
  
  
                formData.append('startDate', moment(startDate).format("YYYY-MM-DD"));
                formData.append('endDate',  moment(endDate).format("YYYY-MM-DD"));
                let res = await httpPostFormData("qr_coupon/submit",formData)
                console.log("res status",res.status) 
                if (res.status === 201 || res.status === 200) {
                        hideLoader()
                        setCreateCoupon({
                            limits  : "",
                            categoryId:"",
                            restriction:"",
                            couponType :"",
                            couponWeblink:"",
                            percentageAmount :"",
                            code :"",
                            mediaFile:"",
                            merchantId:"",
                            filesData:[],
                            videoFile:[],
                            restrictions:"",
                            editImageFile:"",
                            editVideoFile:"",
                            pageType:"create",
                            bgColor:"",
                            qrCodeColor:"",
                            logo:"",
                            logoUrl:"",
                            qrcodeImg:"",
                            redeem : "",
                            tag : ""
                        })
                    NotificationManager.success(
                        "Coupon created successfully",
                        "Yepp",
                        4000
                    ); 
                    
                setActivePage("Coupon_Properties")
                }
                hideLoader()
            } catch (error) {
                console.log(error)
                NotificationManager.error(
                    error.message,
                    "Opps",
                    3000
                );
                hideLoader();
            }
        }
    }
  

    
    const [couponCodeData, setcouponCodeData] = useState({
        marchantNmae:"",
        categoryNmae:""
    })
    
        const  handleChange=(e,type)=>{
            
            
            if (type==="marchant") {
                let getMarchantName = Merchants.filter((value)=>{
                    return value.id === e.target.value
                })
                setCreateCoupon({...createCoupon, merchantId: e.target.value })
                setcouponCodeData({
                   ...couponCodeData,
                    marchantNmae:getMarchantName[0].merchantName
                })
            }
            if (type==="category") {
                let getCategoryName = getAllcategory.filter((value)=>{
                    return value.id === e.target.value
                })

                setCreateCoupon({...createCoupon, categoryId: e.target.value })

               
                setcouponCodeData({
                   ...couponCodeData,
                   categoryNmae:getCategoryName[0].category
                })
            }
            else{
                setCreateCoupon({...createCoupon, [e.target.name]: e.target.value })
            }
       }
    

    const removeFile=(id,type)=>{
        if (type === "image") {
            let filesDatas = createCoupon.filesData
            let newArray = filesDatas.filter((data,index)=>{
                return(index !== id)
            })
            setCreateCoupon({...createCoupon,filesData:[...newArray]})
            console.log("filter", newArray)
        }
         else{

            let filesDatas = createCoupon.videoFile
            let newArray = filesDatas.filter((data,index)=>{
                return(index !== id)
            })
            setCreateCoupon({...createCoupon,videoFile:[...newArray]})
            console.log("filter", newArray)
         }
    }

        const  handleFileChange=(e)=>{
            if(!e.target.files[0]){
                return false;
            }
            if(e.target.files[0].name.split('.')[1] !== 'png'){
                return NotificationManager.error("Image type must be png");
            }
            if(e.target.name === 'logo'){
                setCreateCoupon({...createCoupon,[e.target.name] : e.target.files[0], logoUrl : URL.createObjectURL(e.target.files[0])})
            }else{
                setCreateCoupon({...createCoupon, qrcodeImg: e.target.files[0]})
            }
        }
            


    
    return (
        <div>
            <Layout pageName="QR Coupons" subPageName={`${createCoupon.pageType === "edit" ? "Edit Coupon": "Create Coupons"}`} 
            >
                <div className="page-router-display">

                    <div className="page-roter-list">
                        <div>
                            <div className="hsd">
                                <span
                                onClick={()=>setActivePage("Coupon_Properties")}
                                 className={`page-router-name 
                                ${activePage==="Coupon_Properties"?"page-router-name-active":""}`}>
                                    Coupon Properties</span>
                            </div>

                          <div className="hsd">
                                <span
                                onClick={showQrcode === false ? validateInput : (e)=>{setActivePage("Images_and_videos")}}
                                 className={`page-router-name 
                                ${activePage==="Images_and_videos"?"page-router-name-active":""}`}>
                                    Generate Code</span>
                            </div>
                        </div>
                        {/* <div><button></button></div> */}
                    </div>

                    <div className="pg-dimacation"></div>

                </div>
                {
                    activePage==="Coupon_Properties" ? (
                        <div className="create-cupon-page">

                        <div className="create-conpon-container">

                            <div className="cupon-input-box">
                                    <label htmlFor="">Merchant  <span>*</span></label>
                                    <div className="inputbox-cupon">
                                        <select required  onChange={(e)=>handleChange(e,"marchant")} required name="merchantId" value={createCoupon.merchantId}>
                                         <option value="">Select</option>
                                         {
                                                Merchants?Merchants.map((data)=>{
                                                    return(
                                                <option  value={data.id}>{data.merchantName}</option>)
                                                }):[]
                                            }
                                         </select>
                                         
                                    </div>

                        </div>

                        
                        <div className="cupon-input-box">
                                    <label htmlFor="">Coupon  Type <span>*</span></label>
                                    <div className="inputbox-cupon">
                                    <select required value={createCoupon.couponType} name="couponType" onChange={handleChange}>
                                            <option value="">Select</option>
                                            {
                                                getAllCouponType?getAllCouponType.map((data)=>{
                                                    return(
                                                <option value={data.id}>{data.couponType}</option>)
                                                }):[]
                                            }
                                         
                                    
                                         </select>
                                         
                                    </div>
                                    
                        </div>


                        <div className="cupon-input-box">
                                    <label htmlFor="">Percentage/Amount <span>*</span></label>
                                    <div className="inputbox-cupon">
                                    <input onChange={handleChange} required name="percentageAmount" value={createCoupon.percentageAmount} type="text"/>

                                    </div>
                                    
                        </div>


                        <div className="cupon-input-box">
                            <label htmlFor="">Limits<span>*</span></label>
                            <div className="inputbox-cupon">
                                <input onChange={handleChange} required name="limits" value={createCoupon.limits} type="number"/>
                            </div>     
                        </div>

                        <div className="cupon-input-box">
                                    <label htmlFor="">Category <span>*</span></label>
                                    <div className="inputbox-cupon">
                                        <select onChange={(e)=>handleChange(e,"category")} required name="categoryId" value={createCoupon.categoryId }>
                                        <option value="">Select</option>
                                            {
                                               getAllcategory?
                                               getAllcategory.map((data)=>{
                                                return(
                                            <option value={data.id}>{data.category}</option>)
                                            })
                                               : []
                                            }
                                         </select>
                                         
                                    </div>
                                    
                        </div>

                        
                        <div className="cupon-input-box">
                                    <label htmlFor="">Start Date <span>*</span></label>
                                    <div className="inputbox-cupon">
                                    <DatePicker
                                    selected={startDate}
                                    onChange={date => setStartDate(date)}
                                    minDate={new Date()}
                                    />
                                    <div className="icon"><img src={calendar}></img></div>
                                         
                                    </div>
                                    
                        </div>


                        <div className="cupon-input-box">
                                    <label htmlFor="">End Date <span>*</span></label>
                                    <div className="inputbox-cupon">
                                    <DatePicker
                                        selected={endDate}
                                        onChange={date => setEndDate(date)}
                                        minDate={startDate}
                                    />
                                    <div  className="icon"><img src={calendar}></img></div>
                                         
                                    </div>
                                    
                        </div>


                       

                        <div className="cupon-input-box">
                            <label htmlFor="">Coupon web link<span>*</span></label>
                            <div className="inputbox-cupon">
                                <input onChange={handleChange} required name="couponWeblink" value={createCoupon.couponWeblink} type="text"/>
                                
                            </div>       
                        </div>

                        <div className="cupon-input-box">
                            <label htmlFor="">Coupon Code<span>*</span></label>
                            <div className="inputbox-cupon">
                                <input onChange={handleChange} required name="code" value={createCoupon.code} type="text"/>
                                
                            </div>       
                        </div>

                        <div className="cupon-input-box">
                            <label htmlFor="">Tag<span>*</span></label>
                            <div className="inputbox-cupon">
                                <select required value={createCoupon.tag} name="tag" onChange={handleChange}>
                                    <option value="">Select</option>
                                   {
                                       tags.map(tag=>(
                                            <option value={tag.id}>{tag.type}</option>
                                       ))
                                   }
                                </select>
                            </div>        
                        </div>
                    </div>

                  
                    {/* CUPON DROPDOWNS END HERE */}
                    <div className="cupon-text-area-box">
                    <div className="cupon-input-box textareaCon">
                                    <label htmlFor="">Restrictions<span>*</span></label>
                                    <div className="inputbox-cupon">
                                        <textarea onChange={handleChange} required name="restriction" value={createCoupon.restriction} type="text"/>
                                        <span className="cuponTextCount">500 <span>Characters left.</span></span>
                                    </div>
                                    
                        </div>

                        <div className="cupon-input-box textareaCon">
                            <label htmlFor="">description<span>*</span></label>
                            <div className="inputbox-cupon">
                                <textarea onChange={handleChange} required name="description" value={createCoupon.description} type="text"/>
                                <span className="cuponTextCount">500 <span>Characters left.</span></span>
                            </div>  
                        </div>

                        <div className="cupon-input-box textareaCon">
                            <label htmlFor="">How to Redeem<span>*</span></label>
                            <div className="inputbox-cupon">
                                <textarea onChange={handleChange} required name="redeem" value={createCoupon.redeem} type="text"/>
                                <span className="cuponTextCount">500 <span>Characters left.</span></span>
                            </div>  
                        </div>
                        <div  className="cupon-input-box textareaCon">
                                    <label htmlFor="">Logo (PNG format only)<span>*</span></label>
                            <div className="inputbox-cupon">
                                <div className="upload_form marchant_upload_form">
                                    <div style={{border:"1px solid rgba(45, 78, 245, 0.08)"}} className="dragDropArea upload_col    marchant-drag-and-drop">
                                        <form className="uploadCuponImage">
                                            
                                        {
                                            createCoupon.logo && createCoupon.logo.name.length > 0 && (
                                                <small>{createCoupon.logo.name}</small>
                                            )
                                        }
                                            <span>Drag and drop file here</span>
                                            <div className="upload97">or</div>
                                    
                                            <button className="uploadCupon-file" type="button">Upload</button>
                                            <input onChange={handleFileChange}  type="file" name="logo"/>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
            </div>


            <div className="submit-cupon-button">
                {/* <button className="activaeSubmit-cupon">Preview</button> */}
                <button onClick={showQrcode === false ? validateInput : (e)=>{setActivePage("Images_and_videos")}}  className="activaeSubmit-cupon">Next</button>
            </div>
                    

                  

                       

                            
            </div>
                        
                    ) :
                    
                    
                    ""
                }

              

            {
                activePage==="Images_and_videos"?
                
                <div>
                    <div className="create-cupon-page-two">

<div className="">

    <div className="flex-qr-data">

            <div className="qr-code-wrap">
            <QRCode
                id="123456"
                value={`${createCoupon.couponWeblink}`}
                size={150}
                bgColor={"#ffffff"}
                fgColor={"#000000"}
                level={"L"}
                includeMargin={false}
                renderAs={"canvas"}
                imageSettings={{
                    src: `${createCoupon.logoUrl}`,
                    x: null,
                    y: null,
                    height: 24,
                    width: 24,
                    excavate: true,
                }}
            />

               
            <p onClick={downloadQR}> Download QR </p>
            </div>

            <div  className="cupon-input-box textareaCon">
                                    <label htmlFor="">Upload Downloaded Coupon Code<span>*</span></label>
                                    <div className="inputbox-cupon">
                                    <div className="upload_form marchant_upload_form">
                    <div style={{border:"1px solid rgba(45, 78, 245, 0.08)"}} className="dragDropArea upload_col marchant-drag-and-drop">
                        <form className="uploadCuponImage">
                            {
                                createCoupon.qrcodeImg && createCoupon.qrcodeImg.name.length > 0 && (
                                    <small>{createCoupon.qrcodeImg.name}</small>
                                )
                            }
                            <span>Drag and drop file here</span>
                            <div className="upload97">or</div>
                    
                            <button className="uploadCupon-file" type="button">Upload</button>
                            <input onChange={handleFileChange} name="qrcodeImg"  type="file" />
                        </form>
                  </div>
                  </div>
                  </div>
                  </div>
    </div>



   
</div>
</div>
                <div className="submit-cupon-button">
                    
                    <button onClick={handleSubmit}  className="publish_button">Publish</button>
                </div>
            </div>
            :""
        }
            </Layout>
        </div>
    )
}