import React from 'react'
import Like from '../Assets/like.png'
import NoLike from '../Assets/noLike.png'
import  './index.css';
import moment from 'moment';
import { capitalize } from '@material-ui/core';

export default function Review(props) {
	return (
		<div className="table-responsive">
			<table className="tbTable">
				<thead className="tbHead">
					<tr>
						<th>COUPON</th>
					<th>REVIEWER</th>
					<th>REVIEW</th>
					<th>DATE</th>
					<th>STATUS</th>
					</tr>
					
				</thead>
				<tbody>
					{
						props.reviews.map(item=>(
							<tr  onClick={()=>props.viewReview(`/review/${item.id}`)}>
								<td>
								<div className="review-header">
									{item.Coupon.Merchant && item.Coupon.Merchant.merchantName ? 
									capitalize(item.Coupon.Merchant.merchantName) : ""}
								</div>
									{item.Coupon.percentageAmount}% {item.Coupon.CouponType.couponType}
								</td>
						<td> <div className="review-header">
								{item.User && item.User.firstName ? capitalize(item.User.firstName) : ""} {` `}
								{item.User && item.User.lastName ? capitalize(item.User.lastName) : ""}
									</div> {item.User.location}</td>
								<td> 
									<div className="review-header-star">
										{
											[...Array(item.star).keys()].map(item=>(
												<img src={Like} alt=""/>
											))
										}
										{
											[...Array(5 - item.star).keys()].map(item=>(
												<img src={NoLike} alt=""/>
											))
										}
										
									</div> 
									<div className="review-header">
										{props.ratings[item.star - 1]}
									</div>
									{item.review}
								</td>
									<td>{moment(item.reviewDate).format('DD/MM/YYYY h:mm:ss a')}</td>
								<td> 
									{
										item.replies.length === 0 ? (
											<button className="inactive-status-button"> Pending</button>
										) : (
											<button className="active-status-button">Published</button>
										)
									} 
								</td>
							</tr>
						))
					}
				</tbody>
			</table>
		</div>
	)
}
