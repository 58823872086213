import React from 'react';
import {Link} from 'react-router-dom'
export const Footer = () => {
    return(
        <section className="footer-section p-5">
            <div className="container">
                <div className="flex">
                    <div className="flex-3">
                        <Link to="/"><span>&copy; Tokenfy</span> {(new Date().getFullYear())}</Link>
                    </div>
                    <div className="flex-1 mr-3">
                        <Link to="/policy">Privacy policy</Link>
                    </div>
                    <div className="flex-1 mr-3">
                        <Link to="/terms">Terms of use</Link>
                    </div>
                    <div className="flex-1">
                        <Link to="/faq">FAQ</Link>
                    </div>
                </div>
            </div>
        </section>
    )
}