import React,{useState,useEffect} from 'react'
import {Layout} from '../../Components/Layout/layout';
import './dashboard.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SearchImg from '../../Components/Assets/search.png'
import {Link} from 'react-router-dom'
import {httpGet, httpPatch, httpPostFormData,httpPost, httpDelete} from '../../Components/helpers/httpMethods'
import {hideLoader, showLoader} from '../../Components/helpers/loader'
import {NotificationManager} from 'react-notifications'
import UsersTable from '../../Components/Tables/UsersTable'

export default function Users(props) {
    useEffect(() => {
        GetUsers()
    }, [])

    const [users, setUsers] = useState([])

    const [activePage, setActivePage] = useState("Coupon_Properties")
    const [startDate, setstartDate] = useState(new Date())

    const  GetUsers =async()=>{
        try {
          showLoader()
            const res = await httpGet(`admin/all_users?limit=1000`);
            setUsers(res.data)
            hideLoader()
        } catch (error) {
          hideLoader()
        }
    }
    
    
    return (
        <div>
            <Layout pageName="Overview" subPageName="Users">
                <div className="Table-sec">
                    <UsersTable users={users} />
                </div>
            </Layout>
        </div>
    )
}


