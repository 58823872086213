import React,{useState,useEffect} from 'react'
import {Layout} from '../../Components/Layout/layout';
import EditPNG from '../../Components/Assets/icons/edit_square.png';
import PasswordPNG from '../../Components/Assets/icons/password.png';
import Avatar from '../../Components/Assets/user.png';
import {httpPatch, httpPatchFormdata,httpNewPatch} from '../../Components/helpers/httpMethods'
import {hideLoader, showLoader} from '../../Components/helpers/loader'
import {NotificationManager} from 'react-notifications'
import {getLocalItem, setLocalItem} from '../../Components/helpers/authService';


const AccountSettings = () => {
    let [accountInfo,SetaccountInfo] = useState({
        firstName:"",
        lastName:"",
        email:"",
        phone:"",
        image:"",
        updateImage:"",
        location:"",
        password:"",
    })

    const getUserInformation =  async () => {
        let res = await JSON.parse(getLocalItem('userInformation'));
        let data = await res
        console.log("user>>>",res)
        getUserAccountInfo(data)
       
      }
      useEffect(()=>{
        getUserInformation();
      },[])

     
      const getUserAccountInfo=async(data)=>{
          console.log('data>>',data);
        SetaccountInfo({
            ...accountInfo,
             firstName:data.firstName,
             lastName:data.lastName,
             email:data.email,
             phone:data.phone === null ? "" : data.phone,
             image:data.image ? data.image : data.avatar ? data.avatar : Avatar,
             location:data.location === null ? "" : data.location,
             password:data.password,
        })
        
      }

      
    const handleSubmitAccountData=async(e)=>{
           e.preventDefault();
           try {
                showLoader()
                const formData = new FormData();
                formData.append('firstName', accountInfo.firstName);
                formData.append('lastName',  accountInfo.lastName);
                // formData.append('phoneNumber',  accountInfo.phoneNumber);
                formData.append('email',  accountInfo.email);
                // formData.append('location',  accountInfo.location);
                formData.append('phone',  accountInfo.phone);
                if(typeof accountInfo.updateImage === 'object'){
                    formData.append('avatar',  accountInfo.updateImage);
                }
                let res = await httpPatchFormdata(`admin/edit`,formData);
                console.log('res>>',res);
                let data = await JSON.parse(getLocalItem('userInformation'));
                let fd = {
                    ...data,
                    firstName: res.update_fields.firstName || data.firstName,
                    lastName: res.update_fields.lastName || data.lastName,
                    phone:res.update_fields.phone || data.phone,
                    image:res.update_fields.avatar || data.avatar,
                    location:data.location,
                    password:data.password,
                }
                setLocalItem('userInformation',fd);
                await getUserInformation();
                hideLoader()
                
                NotificationManager.success(
                    "Data updated successfully",
                    "Yepp",
                    4000
                );
            } catch (error) {
                hideLoader();
                console.log('Error>>',error);
                error.data ? NotificationManager.error(error.data.message) : 
                NotificationManager.error(
                    "Network Error! Please check your internet",
                    "Opps",
                    3000
                );
            }
        }
        
        const  handleAccountChange=(e)=>{
            SetaccountInfo({...accountInfo, [e.target.name]: e.target.value });
        }

    const  handleAccountFileChange=(e)=>{
        SetaccountInfo({...accountInfo, [e.target.name]:URL.createObjectURL( e.target.files[0]),updateImage:e.target.files[0] })
    }

    const [changePassword,setChangePwd] = useState(false);
    const [pwd,setPwd] = useState(null);
    const [changePasswordConfirm,setChangePwdConfirm] = useState(false)
    const [oldPasword,SetoldPassword] = useState(false)
    
    const validateNewPassword=(e)=>{
        
        if (pwd === "" || pwd === null) {
            NotificationManager.error(
                "New password is required",
               "Opps",
               3000
           );
           return false
        }

        if (oldPasword === "" || oldPasword === null) {
            NotificationManager.error(
                "Old password is required",
               "Opps",
               3000
           );
           return false
        }

        if (pwd  !== changePasswordConfirm ) {
            NotificationManager.error(
                "New passord must match confirm password",
               "Opps",
               3000
           );
           return false
        }

        else{
            return true
        }

    }


    const handlePasswordChange = async(e)=>{
        e.preventDefault();
        let check = validateNewPassword()
        if (check === false) {
            return
        }
        else{
            try {
                showLoader()
                let data = {
                    oldPassword:oldPasword,
                    newPassword:pwd
                }
                const res = await httpNewPatch(`admin/change`,data)
                NotificationManager.success(
                    "Data updated successfully",
                    "Yepp",
                    3000
                );
                hideLoader()
            } catch (error) {
                console.log('Error>>',error);
                hideLoader();
                error.data ? NotificationManager.error(error.data.message) : NotificationManager.error(
                    error,
                   "Yepp",
                   3000
               );
            }
        }
      
    }

    const  handleChange=(e)=>{
        if (e.target.name === "oldPassword") {
                SetoldPassword( e.target.value )
        }

        if (e.target.name === "newPassword") {
            setPwd(e.target.value)
        }

        if (e.target.name === "newConfirmPassword") {
            setChangePwdConfirm( e.target.value)
        }
    }

  
    return (
        <div>
            <Layout pageName="Settings">
                <section className="pr-3 mt-5rem">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="mb-3">
                                <div className="icon-image-container">
                                    <img src={EditPNG} />
                                </div> 
                                <div className="icon-image-desc" onClick={()=>setChangePwd(false)}>
                                    <h6 className="bold-550">Edit Profile</h6>
                                </div>
                            </div>
                            <div className="mb-7">
                                <div className="icon-image-container">
                                    <img src={PasswordPNG} />
                                   
                                </div> 
                                <div className="icon-image-desc" onClick={()=>setChangePwd(true)}>
                                    <h6 className="bold-550 text-muted">Change Password</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7 setting-content-container">
                            {
                                changePassword ? (
                                    <div className="change-password-container">
                                        <h3 className="bold-550">Change Password</h3>
                                        <div className="profile-image-container">
                                        <img 
                                               className="profile-image-settings"  src={accountInfo.image === "" ? Avatar : accountInfo.image}
                                            />
                                        </div>
                                        <form   >
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="bold-550">Old Password <sup>*</sup></label>
                                                    <input  name="oldPassword" onChange={(e)=>handleChange(e)} type="password" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="bold-550">New Password <sup>*</sup></label>
                                                    <input name="newPassword" onChange={(e)=>handleChange(e)} type="password" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="bold-550">Confirm Password <sup>*</sup></label>
                                                    <input name="newConfirmPassword" onChange={(e)=>handleChange(e)} type="password" className="form-control" />
                                                </div>
                                                <button onClick={handlePasswordChange} className="btn btn-success mb-5">Update password</button>
                                            </div>
                                        </form>
                                    </div>
                                ) : (
                                    <div className="edit-profile-container">
                                        <h3 className="bold-550">Edit Profile</h3>
                                        <div className="profile-image-container">
                                        {console.log('>>',accountInfo.image)}
                                            <img 
                                                className="profile-image-settings"
                                                src={accountInfo.image === "" ? Avatar : accountInfo.image}
                                            />
                                             <div className="center-input-on-image">
                                                 <button>Change Image</button>
                                           <input onChange={handleAccountFileChange}  name="image"  type="file"/>
                                    </div>
                                        </div>
                                        <form style={{position:"relative",top:"26px"}}>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="bold-550">First Name <sup>*</sup></label>
                                                        <input required onChange={handleAccountChange} value={accountInfo.firstName} name="firstName" type="text" className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="bold-550">Last Name <sup>*</sup></label>
                                                        <input required onChange={handleAccountChange} value={accountInfo.lastName} name="lastName" type="text" className="form-control" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="bold-550">Email <sup>*</sup></label>
                                                        <input readOnly onChange={handleAccountChange}  value={accountInfo.email} name="email" type="email" className="form-control" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="bold-550">Phone Number <sup>*</sup></label>
                                                        <input required onChange={handleAccountChange} value={accountInfo.phone} name="phone" type="tel" className="form-control" />
                                                    </div>
                                                    <button onClick={handleSubmitAccountData}  className="btn btn-success mb-5">Save</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                )
                            }



                            
                            
                        </div>
                    </div>
                </section>
            </Layout>
        </div>
    )
}
export default AccountSettings;


