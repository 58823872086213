import {atom} from 'recoil'

export const  UserCurrentState = atom({
  key: "UserCurrentState",
  default: ""
})


export const  UserToken = atom({
  key: "UserToken",
  default: ""
})


export const BrowserController = atom({
  key: "BrowserController",
  default: ""
})
