import axios from "axios";
import { getToken } from "./authService";
import { hideLoader } from "../helpers/loader";
import { NotificationManager } from "react-notifications";

const token = getToken();
const AUTHORIZATION = "authorization";
//export let baseUrl = "http://ec2-3-139-34-118.us-east-2.compute.amazonaws.com/api/v1/";
//export let loginUrl = "http://ec2-3-139-34-118.us-east-2.compute.amazonaws.com/api/v1/";
export let baseUrl = "https://api.tokenfy.io/api/v1/";
export let loginUrl = "https://api.tokenfy.io/api/v1/";

if (process.env.REACT_APP_NODE_ENV === "development") {
	baseUrl = "http://127.0.0.1:8080";
}


export const LoginhttpPost = async (url, postBody) => {
	try {
		const res = await axios.post(`${loginUrl}${url}`, postBody, {
			headers: { Authorization: localStorage.api_token },
		});
		console.log(res)
		return res;
	} catch (error) {
		hideLoader();
		return error;
	}
};

export const httpPost = async (url, postBody) => {
	return new Promise((resolve,reject)=>{
		axios.post(`${baseUrl}${url}`, postBody, {
			headers: { Authorization: `${localStorage.api_token}`},
		}).then(res=>{
			resolve(res.data.data) ;
		}).catch(error=>{	
			if(!error.response){
				hideLoader();
				return NotificationManager.error('Please check your internet');
			}
			reject(error.response ? error.response.data ? error.response.data : error.response : error.response);
		});
	})
};

export const httpGet = async (url) => {
	console.log("<<||>>>",localStorage.api_token)
	return new Promise((resolve,reject)=>{
		axios.get(`${baseUrl}${url}`, {
			headers: { Authorization: `${localStorage.api_token}`},
		}).then(res=>{
			resolve(res.data.data);
		}).catch(error=>{
			hideLoader();
			if(!error.response){
				return NotificationManager.error('Opps','Please check your internet');
			}
			if ((error.response.data.data && error.response.data.data.status === 401) || error.response.data.data.status === 403 ) {
				NotificationManager.error(
					error.response.data.message || "Something went wrong. Please retry.",
					"Oops!",
					3000
				);
				localStorage.removeItem("api_token");
				return window.location.href = '/login';
			}
			console.log('>>>',error.response.data.data.status);
			reject(error.response.data);
		});
	})
};



export const httpPatchFormdata = async (url,postBody) => {
	return new Promise((resolve,reject)=>{
		axios.patch(`${baseUrl}${url}`, postBody, {
			headers: { 
			Authorization: `${localStorage.api_token}`,
			"Content-Type": "multipart/form-data",
		},
		}).then(res=>{
			resolve(res.data.data);
		}).catch(error=>{
			hideLoader();
			if(!error.response){
				return NotificationManager.error('Opps','Please check your internet');
			}
			if (error.response.data.status === 401 || error.response.data.status === 403 ) {
				NotificationManager.error(
					error.response.data.message || "Something went wrong. Please retry.",
					"Oops!",
					3000
				);
				localStorage.removeItem("api_token");
				return window.location.href = '/login';
			}
			reject(error.response.data);
		});
	})
};


export const httpPut = async (url, postBody) => {
	try {
		const res = await axios.put(`${baseUrl}${url}`, postBody, {
			headers: { Authorization: `${localStorage.api_token}` },
		});
		console.log(res)
		return res;
	} catch (error) {
		hideLoader();
		return error;
	}
};



export const httpPatch = async (url, postBody) => {
	try {
		const res = await axios.patch(`${baseUrl}${url}`, postBody, {
			headers: { Authorization: `${localStorage.api_token}` },
		});
		return res.data;
	} catch (error) {
		return error;
	}
};

export const httpNewPatch = async (url, postBody) => {


	return new Promise((resolve,reject)=>{
		axios.patch(`${baseUrl}${url}`, postBody, {
			headers: { 
			Authorization: `${localStorage.api_token}`
		}
		}).then(res=>{
			resolve(res.data.data);
		}).catch(error=>{
			hideLoader();
			if(!error.response){
				return NotificationManager.error('Opps','Please check your internet');
			}
			if (error.response.data.status === 401 || error.response.data.status === 403 ) {
				NotificationManager.error(
					error.response.data.message || "Something went wrong. Please retry.",
					"Oops!",
					3000
				);
				localStorage.removeItem("api_token");
				return window.location.href = '/login';
			}
			reject(error.response.data);
		});
	})
}



export const httpDelete = async (url) => {
	try {
		const res = await axios.delete(`${baseUrl}${url}`, {
			headers: { Authorization: localStorage.api_token },
		});
		return res;
	} catch (error) {
		if (error.response.data.status === 401 || error.response.data.status === 403 ) {
			NotificationManager.error(
				error.response.data.message || "Something went wrong. Please retry.",
				"Oops!",
				3000
			);
			window.location.href = '/logout';
			hideLoader();
		}
		NotificationManager.error(
			error.response.data.message || "Something went wrong. Please retry.",
			"Oops!",
			3000
		);
		hideLoader();
		return error;
	}
};

export const httpPutFormData = async (url, postBody) => {
	try {
		const res = await axios.put(`${baseUrl}${url}`, postBody, {
			headers: {
				Authorization: `${localStorage.api_token}`,
				"Content-Type": "multipart/form-data",
			},
		});
		//console.log(res)
		return res;
	} catch (error) {
		if (error.response.data.status === 400 || error.response.data.status === 409) {
			NotificationManager.error(
				error.response.data.message || "Something went wrong. Please retry.",
				"Oops!",
				3000
			);
			hideLoader();
		}
		if (error.response.data.status === 401 || error.response.data.status === 403 ) {
			NotificationManager.error(
				error.response.data.message || "Something went wrong. Please retry.",
				"Oops!",
				3000
			);
			window.location.href = '/logout';
			hideLoader();
		}
		NotificationManager.error(
			error.response.data.message || "Something went wrong. Please retry.",
			"Oops!",
			3000
		);
		hideLoader();
		return error;
	}
};

export const httpPostFormData = async (url, postBody) => {
	try {
		const res = await axios.post(`${baseUrl}${url}`, postBody, {
			headers: {
				Authorization: `${localStorage.api_token}`,
				"Content-Type": "multipart/form-data"
			},
		});
		return res;
	} catch (error) {
		console.log('error>>',error.response.d);
		if (error.response.data && error.response.data.data) {
			NotificationManager.error(
				error.response.data.data.message || "Something went wrong. Please retry.",
				"Oops!",
				3000
			);
			return hideLoader();
		}
		if (error.response.data.status === 400 || error.response.data.status === 409) {
			NotificationManager.error(
				error.response.data.message || "Something went wrong. Please retry.",
				"Oops!",
				3000
			);
			hideLoader();
		}
		if (error.response.data.status === 401 || error.response.data.status === 403 ) {
			NotificationManager.error(
				error.response.data.message || "Something went wrong. Please retry.",
				"Oops!",
				3000
			);
			window.location.href = '/logout';
			hideLoader();
		}
		NotificationManager.error(
			error.response.data.message || "Something went wrong. Please retry.",
			"Oops!",
			3000
		);
		hideLoader();
		return error;
	}
};

export const httpPostFormData1 = async (url, postBody,is_new_merchant=false) => {
	return new Promise((resolve,reject)=>{
		axios.post(`${baseUrl}${url}`, postBody, {
			headers: {
				Authorization: `${localStorage.api_token}`,
				"Content-Type": "multipart/form-data",
			},
		}).then(res=>{
			return resolve(res)
		}).catch(error=>{
			if (error.response.data.status === 400 || error.response.data.status === 409) {
				NotificationManager.error(
					error.response.data.message || "Something went wrong. Please retry.",
					"Oops!",
					3000
				);
				hideLoader();
			}
			if (error.response.data.status === 401 || error.response.data.status === 403 ) {
				NotificationManager.error(
					error.response.data.message || "Something went wrong. Please retry.",
					"Oops!",
					3000
				);
				window.location.href = '/logout';
				hideLoader();
			}
			NotificationManager.error(
				"Something went wrong. Please check the email address entered.",
				"Oops!",
				3000
			);
			hideLoader();
		})
	});
}