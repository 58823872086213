import React,{useState,useEffect} from 'react'
import {Layout} from '../../Components/Layout/layout';
import "react-datepicker/dist/react-datepicker.css";
import {Link} from 'react-router-dom';
import User from '../../Components/Assets/user.png'
import CardGiftPNG from '../../Components/Assets/icons/card_gift.png';
import { httpGet,httpDelete, httpPost } from '../../Components/helpers/httpMethods';
import { hideLoader,showLoader } from '../../Components/helpers/loader';
import { NotificationManager } from 'react-notifications';
import {useParams} from 'react-router-dom';
import ConfirmBlock from '../../Components/Modals/blockModal';
import axios from 'axios';
import moment from 'moment';
import { currencyFormat } from '../../Components/helpers/helpers';

export default function Wallet(props) {
    const {user_id} = useParams();
    const getWalletInfo = async () =>{
       try{
           showLoader();
            let res =  httpGet(`/admin/user/${user_id}`);
            let res1 = httpGet(`/admin/user/${user_id}/rewards?limit=1000`);
            let all = await axios.all([res,res1]);
            setUser(all[0]);
            setAmount(all[0].wallet ? all[0].wallet.balance : 0) ;
            setHolder(all[0].wallet ? all[0].wallet.balance : 0);
            console.log('Res>>',all[0]);
            setRewards(all[1]);
            hideLoader();
       }catch(error){
            hideLoader();
            NotificationManager.error(error.data.message);
       }
    }

    const walletTopUp = async () => {
        try{
            showLoader();
            let fd = {
                balance : Number(amount) - Number(holder)
            }
            let res = await httpPost(`/wallet/topup/${user_id}`,fd)
            NotificationManager.success("Wallet has been topped up")
            console.log('res>>',res);
            setAmount(res.balance);
            setHolder(res.balance);
            setShow(false);
            hideLoader();
        }catch(error){
            hideLoader();
            return error.data ? NotificationManager.error(error.data.message) : NotificationManager.error("Network Error. Please retry");
        }
    }

    const deleteData = async(id)=>{
        showLoader()
        try {
            const res = await httpDelete(`admin/user/reward/${id}`);
            hideLoader();
            NotificationManager.success(
                "deleted successfully",
                "Yepp",
                3000
            );
            getWalletInfo()
        } catch (error) {
            hideLoader()
            console.log(error)
        }
    }

    const setWallet = (event) => {
        if(event.target.value.trim() === ""){
            return setAmount(holder);
        }
        setAmount(Number(event.target.value) + Number(holder))
    } 

    useEffect(()=>{
        getWalletInfo();
    },[]);
    const [rewards,setRewards] = useState([]);
    const [user,setUser] = useState({});
    const [amount,setAmount] = useState(0);
    const [holder,setHolder] = useState(0);
    const [show,setShow] = useState(false);
    return (
        <div>
            <Layout pageName="Overview" subPageName="User">

                <div className="cupon_statics full-width">
                    <div className="cuponImg">
                        <img src={user.avatar}/>
                        <div className="about-viewCupon">
                        <p>{user.firstName} {user.lastName}</p>
                        {
                            user.activeAsUser ? (
                                <button>Active</button>
                            ) : (
                                <button id="inactive-status-indicator">Inactive</button>
                            )
                        }
                    </div>
                    </div>

                    

                    <div className="cupon-detailsbe7">
                        <p> <span>Phone Number:</span>   {user.phone}</p>
                        <p style={{color:"#2D4EF5"}}> <span >Email:</span>   {user.email}</p>
                    </div>

                    <div className="cupon-detailsbe7">
                        <p><span>Location:</span>  {user.location}</p>
                        <p><span>Tokenfy ID:</span>   {user.tokenfyId}</p>
                    </div>

                   
                </div>

                <section className="page-section">
                    <div className="row">
                        <div className="col-md-12 p-0">
                            <div className="card bg-dark">
                                <h3 className="text-white">Wallet Balance</h3>
                                <h1 className="bold-550 text-white mt-5 mb-5 text-center">
                                    <span className="bold-550">&#x20A6;</span>
                                    {new Intl.NumberFormat('en-US').format(Number(amount) ? amount : 0)}
                                </h1>
                            </div>
                        </div>
                        
                        <div className="col-md-6 mt-4">
                            <div className="icon-image-container-no-margin mr-3">
                                <img 
                                    src={CardGiftPNG}
                                />
                            </div>
                            <h4 className="bold-550 icon-image-desc">Rewards</h4>
                        </div>
                        <div className="col-md-6 mt-4 text-right p-0">
                            <Link to={`/add-reward/${user.id}`} onClick={(e)=>{
                                e.preventDefault();
                                setShow(true);
                            }} className="btn btn-primary btn-primary-outline mr-4" id="add-reward"><span className="fa fa-pencil"></span> Edit</Link>
                            <Link to={`/add-reward/${user.id}`} className="btn btn-primary"><span className="fa fa-plus"></span> Add Reward</Link>
                        </div>
                       {
                           show && (
                            <div className="offset-md-9 col-md-3 add-wallet-field mt-3 text-right p-0">
                                <input type="text" className="form-control mb-2" onChange={(e)=>setWallet(e)}/>
                                <button className="mr-3" onClick={(e)=>{
                                    setAmount(holder)
                                    setShow(false);
                                }}><i className="fa fa-close text-danger"></i></button> 
                                <button onClick={walletTopUp}><i className="fa fa-check text-primary"></i></button>
                            </div>
                           )
                       }
                    </div>
                </section>

                <div className="page-section mb-4">
                    <div className="row">
                       {
                           rewards.length > 0 && rewards.map((reward,index)=>(
                            <div className="col-md-3 mb-3" key={index}>
                                <div className="card">
                                    <div className="header">
                                        <h6>
                                            {reward.Merchant.merchantName}
                                            <button className={reward.status === 'active' ? `restore-action-button pull-right` : `inactive-status-button pull-right`}>{reward.status === 'active' ? 'Active' : 'Inactive'}</button> 
                                        </h6>
                                    </div>
                                    <div className="mt-4">
                                        <h3 className="bold-550">{reward.Category.category}</h3>
                                        <p className="text-muted">{moment(reward.startDate).format('DD/MM/YYYY')} - {moment(reward.endDate).format('DD/MM/YYYY')}</p>
                                    </div>
                                    <div className="border-top-1 mt-2">
                                        <span><small className="text-warning">3/5</small> <small>left</small></span>
                                        <Link data-toggle="modal" data-target="#BlockModal" className="pull-right text-warning"><small>Delete <span className="fa fa-trash"></span></small></Link>
                                        <Link to={`/add-reward/${user.id}/${reward.id}`} className="pull-right text-primary mr-2"><small>Edit <span className="fa fa-pencil"></span></small></Link>
                                    </div>
                                </div>
                                <ConfirmBlock deletData={deleteData} item_id={reward.id}/>
                            </div>
                           ))
                       }
                       {
                           rewards.length === 0  && (
                                <div className="col-md-12">
                                    <h4 className="text-center">No record found</h4>
                                </div>
                           )
                       }
                    </div>
                </div>
            </Layout>
        </div>
    )
}


