import React, { useState } from 'react'
import {Link} from 'react-router-dom';
import './home.css';
import logo_png from '../../Components/Assets/logo.png';
import {Footer} from '../../Components/Layout/footer';


export const Policy = () =>{
    return(
        <>
            <section className="p-5 header-section">
                <div className="container header-container">
                    <Link to="/" className="flex">
                        <div className="logo-container mr-3">
                            <img src={logo_png}/>
                        </div>
                        <h4 className="flex-5 text-bold">TOKENFY</h4>
                    </Link>
                </div>
            </section>
            <section className="p-5">
                <div className="container">
                    <h1 className="text-center mb-5">Privacy Policies</h1>
                    <div className="flex">
                        <div className="flex-1">
                            {
                                [
                                    `
                                    Privacy Policy Effective Date: January 20, 2021
                                    `,

`This Privacy Policy ("Privacy Policy") explains how Tokenfy Technologies Limited ("Tokenfy", "us", "our", and "we"), collects and uses information when you interact with us. This Privacy Policy applies to all users of Tokenfy's website published at www.Tokenfy.io (and any other websites with "Tokenfy" branding that link to this Privacy Policy, including, without limitation, m.Tokenfy.io ) and any mobile-device applications we offer, such as our iOS and Android applications, that are branded "Tokenfy" and link or reference this Privacy Policy (collectively, the "Services").`,

`Please read this Privacy Policy carefully. This Privacy Policy replaces and supersedes the last version of this Privacy Policy for all users (including for those users that registered prior to the Effective Date). If you do not agree to our practices, please do not register, subscribe, create an account, or otherwise interact with the Services. Your use of the Services is also subject to our Terms of Use. If you have enrolled in our card-linked cash back rewards program, your use is also subject to our Card-Linked Cash Back Rewards Promotion Terms and the specific rewards-related privacy provisions in Section 3 below.`,
`Please Contact Us if you have any questions or comments about our privacy practices or this Privacy Policy. You can reach us via postal mail at the following address:`,
`Tokenfy Technologies Limited
Attention: Legal Department
171 Obadina Street, Omole Phase 1,
Ikeja, Lagos.
`,
`Our Privacy Policy is organized as follows:`,
`Types of Personal Information We Collect
How Tokenfy Collects Personal Information
How Tokenfy Uses Personal Information
When and Why Tokenfy Discloses Personal Information
Cookies and Related Technologies
Your Choices
Accessing and Correcting Personal Information
Security of Personal Information
Notice to Residents of Countries outside Nigeria
Privacy Practices of Third Parties
Communities
Changes to this
Children's Privacy
`,
`Types of Personal Information We Collect
How Tokenfy Collects Personal Information
How Tokenfy Uses Personal Information
When and Why Tokenfy Discloses Personal Information
Cookies and Related Technologies
Your Choices
Accessing and Correcting Personal Information
Security of Personal Information
Notice to Residents of Countries outside Nigeria
Privacy Practices of Third Parties
Communities
Changes to this
Children's Privacy
`,
`Contact Information that allows us to communicate with you, such as your name, postal addresses, email addresses, social media website user account names, telephone numbers, or other addresses at which you receive communications from or on behalf of Tokenfy.
Relationship Information that helps us to understand who you are and what types of stores, products, and advertisements you might like. This includes lifestyle, preference, and interest information; the types of coupons and coupon websites that interest you; information collected from social media interactions (such as via Facebook Connect); and demographic information (e.g., birth date, age, gender, zip code).
`,
`Transaction Information about how you interact with the Services, such as the coupons you view and redeem, the gift cards you purchase or sell via our Services, the cash back offers that you claim, including QR Codes that you scanned; financial information about you as needed to administer your participation in our rewards programs, including payment card number, expiration date, and CVN; other information about how you use our Services, email, other communications, and applications; and how you interact with Tokenfy's merchants, business partners, and service providers, including, in some cases, the items you purchase from those merchants.`,
`Location Information that allows us to periodically determine your geolocation, such as your location relative to nearby merchants or, your presence within a mall or store (e.g., near a checkout, aisle, or entrance) or your state/province, city, or neighborhood. Please see the Your Choices section under the heading "mobile" for information on how to limit the Location Information that Tokenfy may collect from the Services operating on a mobile device.
Analytics Information about your use of our Services, such as your IP address, access time, device ID or other unique identifier (e.g., Android advertising ID or Apple IDFA), domain name, screen views, language information, device name and model, operating system type, and your activities on our Services. For example, information collected by our Services via a mobile device may include the following: (i) the names of the other applications on your mobile device and, if you use an Android-based mobile device, how you use them (e.g., frequency and duration of use); (ii) if you use an Android-based mobile device, the URL addresses of the webpages you access; (iii) information about your mobile device (e.g., make, model, operating system, and other similar information, but not phone number); (iv) information about your use of features, functions, or clicks on notifications or content; and (v) the strength of signal, temperature, battery level, and unique identifier of a nearby Bluetooth® proximity beacon.`,

`2. How Tokenfy Collects Personal Information
We may ask you for Personal Information when you register, subscribe, create an account, or otherwise interact with the Services or contact Tokenfy. For example, you may be required to provide us with Contact Information or Relationship Information in order to register for certain services.
`,
`We collect Personal Information when you use our Services on one or more of your devices that interact with the Services. For example, we may track the coupons you view, submit or redeem via a desktop computer, the gift cards that you purchase from us via your mobile device, and the comments you make to the coupons, bulletin boards, discussion groups, chat rooms or other methods of communication that may be offered on or through the Services from time to time (collectively, "Communities"), or to those related to our social media pages or accounts. We use cookies and related technologies to collect information about your computer and your interaction with the Services and our business partner websites. You can read more about cookies and other technologies in the Cookies and Related Technologies section.`,
`We also collect Personal Information if you contact us via telephone, email, text, postal mail, social media interaction, or other methods of communication, such as for customer support purposes.`,
`If you use a social media platform, a mobile device or other third-party method of communication to interact with Tokenfy or the Services, that platform may have a specific privacy statement that governs the use of Personal Information related to that platform. If you have questions about the security and privacy settings of your mobile device, please refer to instructions from your mobile service provider or the manufacturer of your device to learn how to adjust your settings.`,
`If you use the Services on a mobile device and your mobile device's settings allow it, we may collect Personal Information from your mobile device. For example, if you have permitted your mobile device to transmit location data, we may collect your geolocation and time of day. In addition, if you have also enabled Bluetooth® functionality on your mobile device, our Services may determine your presence and duration within a location, such as a mall or store, based on the detection of signals from nearby Bluetooth® proximity beacons. Our application may be designed to collect information even if you are not logged into one of the Services. In some cases, for example, we may use your Internet Protocol (“IP”) address to estimate your general location even if you have not logged in or enabled location tracking on your mobile device. Please see the Your Choices section under the heading "mobile" for more information on how to limit the Location Information that Tokenfy may collect from the Services operating on a mobile device.`,
`From time to time, we may also supplement Personal Information with information collected from third parties, including demographic information, information about your interests, and similar information that the third parties have collected from other sources (which may include analytics data from third party websites). For example, we may collect information from third parties to enhance other information we have about you, to help us better understand you, to provide you with targeted advertising, and to otherwise enhance the Services.`,
`3. How Tokenfy Uses Personal Information
Tokenfy uses Personal Information to:

Operate and improve our Services;
Provide users with content and advertisements across their devices for products and services from merchants, including coupons based on variables such as stated and anticipated user interests, personal characteristics, and location;
Evaluate eligibility of users for certain coupons, products or services;
Evaluate the types of coupons, products or services that may be of interest to users;
Track redemption of coupons and perform other analytics;
Provide user support, including to merchants or business partners;
Fulfill requests for Tokenfy services;
Communicate and provide additional information that may be of interest to you about Tokenfy and featured merchants and business partners, such as Tokenfy news, special offers and coupons, announcements, and marketing materials;
Send you reminders, technical notices, updates, security alerts and support and administrative messages service bulletins, or marketing;
Provide commercial notifications when you arrive at, linger near or leave places;
Infer the location of the places you visit most often, determine your frequency at these locations, and send notifications when you arrive at or leave those places;
Provide advertisements to you through the Services, third-party sites, email messages, text messages, mobile-push notifications, or other methods of communication;
Administer surveys, contests, or other promotional activities or events sponsored by us or our partners;
Create user profiles that may contain inferences about users such as age range, income range, gender, and interests;
To help verify accounts and activity, and to promote safety and security on and off of our Services, such as by investigating suspicious activity or violations of our terms or policies and assessing the relative risk that a particular activity (e.g., the purchase or sale of a gift card) may be unauthorized or fraudulent;
Manage our everyday business needs such as website administration, forum management, analytics, fraud prevention, Terms of Use or to comply with the law;
Allow you to apply for a job, post a video or sign up for special offers from third parties through the Services;
As described in our rewards program, if you agreed to participate in those programs; and
Carry out other purposes that are disclosed to you and to which you consent.
In addition, certain Tokenfy services may include a rewards program allowing you to enroll your eligible credit card, debit card and/or other payment method and related information in order to earn cash back from purchases made with participating merchants (the “Program”).
`,
`4. When and Why Tokenfy Discloses Personal Information
We may share your Personal Information as required or permitted by law as described below:

With affiliated companies (e.g., subsidiaries and other companies owned or controlled by, or under common ownership or control with, Tokenfy) who may only use the Personal Information for the purposes described in Section 3 above;
With our service providers that provide services for us to assist us in carrying out the purposes described in Section 3 above such as sending emails, push notifications, or SMS messages on our behalf, assessing potential fraud (such as in connection with the purchase or sale of gift cards) and conducting surveys on our behalf. Our service providers are bound by law or contract to protect Personal Information and are only allowed to use the Personal Information in accordance with the terms of our service agreements with them. In some limited cases, such as for purposes of investigating suspicious activity and assessing the likelihood for fraud, some of our service providers may use the Personal Information that we provide them for the purpose of improving their own services;
Merchants, but only to the extent you have redeemed a coupon offered by the merchant, or participated in an offer, contest or other activity sponsored or offered through Tokenfy on behalf of the merchant;
Tokenfy business partners or advertisers, as part of an offer, contest, or other activity that is provided by or in conjunction with Tokenfy and its business partners and in which you have elected to participate. For example, if you are referred to Tokenfy from a business partner's website or through an invitation facilitated by or in conjunction with a Tokenfy business partner, Tokenfy may provide that business partner with Contact Information and Transactional Information to validate the referral and to support the terms of the relationship between Tokenfy and the business partner, which may allow such business partner to contact you directly and to advertise to you;
To effect a merger, acquisition, or otherwise; to support the sale or transfer of business assets; to enforce our rights or protect our property; to protect the rights, property or safety of others, investigate fraud, respond to a government request; or as needed to support auditing, compliance, and corporate governance functions. We may also disclose Personal Information to defend ourselves in litigation or a regulatory action, and when required or advised to do so by law, such as in response to a subpoena, a reporting requirement or similar legal process, including to law enforcement agencies, regulators, and courts in Nigeria and other countries where we operate. If Tokenfy is involved in a merger, acquisition, or sale of all or a portion of its assets, you will be notified via email and/or prominent notice on one or more of our Services of any change in ownership or uses of your Personal Information, as well as any choices you may have regarding your Personal Information; or
Otherwise with your consent.
We encourage Tokenfy merchants and business partners to adopt and post privacy policies. However, the use of your Personal Information by such parties is governed by the privacy policies of such parties and is not subject to our control. When you choose to interact with a Tokenfy merchant or business partner, we encourage you to read any privacy policy they may have posted on their website.

We may also disclose information about you that is not personally identifiable. For example, we may provide merchants, business partners, or other third parties with reports that contain aggregated and statistical data about our users.
`,
`5. Cookies and Related Technologies
When you use our Services, we collect certain information by automated or electronic means, using technologies such as cookies, pixel tags, web beacons, browser analysis tools, and web server logs. As you use our Services, your browser and devices communicate with servers operated by us, our business partners and services providers to coordinate and record the interactivity and fill your requests for services and information. Additional information on how to opt-out of certain tracking features is set forth in the Your Choices section below.

The information from cookies and related technology is stored in web server logs and also in web cookies kept on your computers or mobile devices, which are then transmitted back to our Services by your computers or mobile devices. These servers are operated and the cookies managed by us, our business partners or our service providers.

For example, when you access our Services, Tokenfy and our service providers may place cookies on your computers or mobile devices. These cookies may include means for tracking your Transaction Information with a Merchant and may include tracking technology from third-party affiliate-network operators like Link Share and Commission Junction. Cookies allow us to recognize you when you return, and track and target your interests in order to provide a customized experience. They also help us detect certain kinds of fraud.

A "cookie" is a small amount of information that a web server sends to your browser that stores information about your account, your preferences, and your use of the Services. Some cookies contain serial numbers that allow us to connect your activity with the Services with other information we store about you in your profile.

Some cookies are temporary, whereas others may be configured to last longer. "Session" cookies are temporary cookies used for various reasons, such as to manage page views. Your browser usually erases Session cookies once you exit your browser. "Persistent" cookies are more permanent cookies that are stored on your computers or mobile devices even beyond when you exit your browser. We use Persistent cookies for a number of purposes, such as retrieving certain information you have previously provided (such as your user id if you asked to be remembered), and storing your preferences.

Pixel tags and web beacons are tiny graphic images placed on website pages or in our emails that allow us to determine whether you have performed specific actions. When you access these pages or open email messages, the pixel tags and web beacons generate a notice of that action to us, or our service providers. These tools allow us to measure response to our communications and improve our web pages and promotions.

We collect many different types of information from cookies and other technologies. For example, we may collect information from the devices you use to access our Services, your operating system type or mobile device model, browser type, domain, and other system settings, as well as the language your system uses and the country and time zone of your device. Browsers routinely send these types of information to web servers. Our server logs also record the IP addresses of the devices you use to interact with the Services. We may also collect information about the website you were visiting before you came to our Services and the website you visit after you leave our Services, if this information is supplied to us by your browser.

In many cases, the information we collect using cookies and other tools is used in non-identifiable ways, without any reference to Personal Information. For example, we use information we collect about users to optimize our Services and understand its traffic and usage patterns. In other cases, we associate the information we collect using cookies and related technologies with Personal Information. In that case, this Privacy Policy governs how we use that information.

Additionally, if you have visited one of our mobile websites or activated one of our mobile-device applications, and if the settings on your location-aware device allow us to receive Location Information, we will collect that automatically. If we associate Location Information with other Personal Information, this Privacy Policy governs how we would use that information too.

We may allow third parties to use tracking devices through the Services to collect the same type of information for the same purposes as Tokenfy does for itself. For example, Tokenfy has relationships with third party advertising technology and analytics companies, such as Google Analytics to perform tracking and reporting functions for our Services and, in some cases, to help us place targeted advertisements via the Services or third-party websites. Additionally, we may share non-personally identifiable information from or about you with third parties, such as location data, advertising identifiers, or a cryptographic hash of a common account identifier (such as an email address), to facilitate the display of targeted advertising. Third parties may be able to associate the information they collect with other information they have about you from other sources. We do not necessarily have access to or control over the tracking devices they use, but you may be able to opt out of some of practices by visiting the links provide in the Opting Out of Tracking paragraph of the “Your Choices” section below. Please note that opting out does not prevent the display of all advertisements to you.
`,
`6. Your Choices
We think that you benefit from a more personalized experience when we know more about you and the kinds of coupons you like. However, you can limit the information you provide to Tokenfy, and you can limit the communications that Tokenfy sends to you.

Email
You can manage your email preferences by logging into the Services via a desktop computer and navigating to the "Settings" page. You can also manage your subscriptions by following subscription management instructions contained in the commercial messages that we send you. You may choose to subscribe to some types of messages, and may choose to unsubscribe from others. You may also update your subscription preferences from time to time. If you wish to opt out of receiving offers directly from Tokenfy merchants or business partners, you can follow the opt-out instructions in the emails that they send you.

Opting Out of Tracking
Currently, our Services do not recognize browser do-not-track signals. However, you may manage how your browser handles cookies and related technologies by adjusting its privacy and security settings. Browsers are different, so refer to instructions related to your browser to learn about cookie-related and other privacy and security settings that may be available. The Help portion of the toolbar on most browsers will tell you how to prevent your computer from accepting new cookies, how to have the browser notify you when you receive a new cookie, or how to disable cookies altogether. You can opt-out of being targeted by certain third party advertising companies.7u

Mobile App
If you access any of our Services through one of our mobile applications, you may manage how your Personal Information is shared with Tokenfy. You may adjust the settings on your mobile device to allow or restrict the sharing of Location Information. For example, the "Location" permissions (or "Privacy" >> "Location Services" on iOS-based devices) on your mobile device will allow you to elect whether to never share Location Information with Tokenfy, to share Location Information only while you are using the application (e.g., to help you find nearby offers), or always share Location Information even while you are not using the application (e.g., alert you of nearby offers when you approach a shopping mall). You may also elect to only disable "Bluetooth" to prevent just the sharing of information collected via nearby Bluetooth ® proximity beacons. Please note that if you elect to not share your Location Information, you may be unable to access some features of our Services that are designed for mobile devices. Also, in the event that you prevent the sharing of Location Information, we may still estimate your general location based on the IP address you use to access our Services.

You may also manage how your mobile device handles ad-related tracking technologies by adjusting your mobile device privacy settings. For example, the "Privacy" settings of an iOS device will allow you to "Limit Ad Tracking" and reset your mobile device’s "Advertising Identifier." Please refer to instructions provided by the manufacturer of your device to learn how to adjust your mobile device settings.

You may also opt-out of receiving different types of push notifications or alerts by toggling off the "Notifications" options within the "Settings" menu of our mobile applications. This feature is available in the most recent versions of our iOS-based and Android-based applications.


Connecting through Social Networks
You may manage the sharing of certain Personal Information when you register with us through a social media platform or application, such as through Facebook Connect. Please refer to the privacy settings of the social media platform or application to determine how you may adjust our permissions and manage the interactivity between Tokenfy and your social media account. By registering with Tokenfy through a social media platform, you grant Tokenfy permission to access all of the elements of your social network profile information that you have made available to be shared and to use it in accordance with the social network's terms of use and this Privacy Policy.
`,
`
7. Accessing and Correcting Personal Information
Keeping Personal Information about you current helps ensure that we offer you the best coupons available for merchants and business partners. You can do several things to help keep Personal Information about you current. You can access, update and delete your Contact Information and the other information you provided us to use in your profile by logging into the Services via a desktop computer and clicking on the "Profile" link.

If you want to close your account or have other questions or requests, or if you would like to access or request a correction to any other Personal Information we hold regarding you, please Contact Us.

We will retain your information for as long as your account is active or as needed to provide you services. If you wish to cancel your account or request that we no longer use your information to provide you services, Contact Us. In response, we will cancel or remove your information but may retain and use copies of your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.
`,
`
8. Security of Personal Information
Tokenfy follows generally accepted industry standards to protect the Personal Information that you provide. For example, we regularly monitor our system for possible vulnerabilities and attacks, and we use a secured-access data center. No method of transmission over the Internet, or method of electronic storage, is one hundred percent secure, however. For this reason, there is no guarantee that information may not be accessed, disclosed, altered, or destroyed by breach of any of our physical, technical, or managerial safeguards. If you have any questions about our security practices, please Contact Us.
`,
`
9. Notice to Residents of Countries outside Nigeria

Tokenfy is headquartered in Nigeria. Personal Information may be accessed by us or transferred to us in Nigeria or to our affiliates, business partners, merchants, or service providers elsewhere in the world. By providing us with Personal Information, you consent to such access and transfers. You also acknowledge and agree that Personal Information may be subject to access requests from governments, courts, law enforcement officials, and national security authorities in Nigeria in accordance with Nigeria laws. We will protect the privacy and security of Personal Information according to our Privacy Policy, regardless of where it is processed or stored.

To ensure adequate protection of personal data of the European Economic Area (the “EEA”), any transfers of such data outside of the EEA will be pursuant to the Model Clauses approved by the European Commission. Tokenfy commits to resolve complaints about your privacy and our collection or use of your personal information. We encourage anyone with inquiries or complaints regarding this privacy policy should first Contact Us.
`,
`10. Privacy Practices of Third Parties

This Privacy Policy only addresses the use and disclosure of information by Tokenfy through your interaction with the Services. Other websites that may be accessible through links from our Services may have their own privacy statements and personal information collection, use, and disclosure practices. Merchants and business partners may have their own privacy statements, too. We encourage you to familiarize yourself with the privacy statements provided by these other parties prior to providing them with information or taking advantage of an offer or promotion.
`,
`
11. Communities

Our Services contain Communities and other areas where you may be able to publicly post information, communicate with others (such as via discussion boards or blogs), review coupons, products and merchants, and submit media content. Prior to posting in these areas, please read our Terms of User carefully. All the information you post will be accessible to anyone with Internet access, and any Personal Information you include in your posting may be read, collected, and used by others in a manner we cannot control. For example, if you post your email address along with a comment explaining how to redeem a particular coupon, you may receive unsolicited messages from other parties. We are not able to control the use by third parties of Personal Information you post to public areas of the Services. Please avoid publicly posting Personal Information. If you have questions about Personal Information that you have publicly posted in our Communities, please Contact Us.
`,
`
12. Changes to this Privacy Policy

From time to time, we may update this Privacy Policy to clarify our practices or to reflect new or different privacy practices, such as when we add new features to our Services. If we make any material changes we will notify you by email (sent to the email address specified in your account) or by means of notice on the Services prior to the change becoming effective. Your continued access or use of the Services after any such changes become effective constitutes your acceptance of the Privacy Policy as revised. We encourage you to periodically review this page for the latest information about our privacy practices.
`,
`
13. Children's Privacy

Tokenfy does not intend that any portion of the Services will be accessed or used by children under the age of thirteen, and such use is prohibited. By using the Services, you represent that you are at least thirteen years old and understand that you must be at least thirteen years old in order to create an account and purchase the goods or services advertised through the Services. We will promptly delete information associated with any account for which we obtain actual knowledge that the registered user associated with the account is not at least thirteen years old.
`,
`CATEGORIES OF INFORMATION WE COLLECT`,
`We may have collected the following categories of personal information during the past 12 months for business or commercial purposes:`,
`Identifiers such as your name, postal address, unique personal identifier, online identifier, Internet Protocol (“IP”) address, device ID, email address, account name, social media website user account names and information collected from social media interactions, demographic information, telephone number, or other addresses at which you receive communications from or on behalf of Tokenfy.`,
`Commercial information such as products you’ve purchased, obtained or considered; the coupons you view and redeem; the cash back offers that you claim, including QR codes that you scanned; other information about how you use our Services, email, other communications, and applications; your purchasing tendencies including how you interact with Tokenfy's merchants, business partners, and service providers, including, in some cases, the items you purchase from those merchants.`,
`Internet or other similar network activity such as your browsing history, search history, interaction with the Tokenfy website and app.`,
`Geolocation data including your physical location and movements that allows us to periodically determine your geolocation, such as your location relative to nearby merchants or, your presence within a mall or store (e.g., near a checkout, aisle, or entrance) or your state/province, city, or neighborhood.`,
`Inferences drawn from other personal information such as your preferences and shopping characteristics that helps us to understand who you are and what types of stores, products, and advertisements you might like. This includes lifestyle, preference, and interest information and the types of coupons and coupon websites that interest you.`,
`Personal information does not include publicly available information, de-identified consumer information or aggregated consumer information.`,
`We do not knowingly collect personal information from consumers who are less than 16 years of age.`,
`SOURCES OF INFORMATION`,
`We obtain the above categories of personal information from three sources: directly, when you choose to provide it; indirectly; and from third parties.`,
`Personal Information We Collect Directly From You

We collect personal information from you when you register for or subscribe to our Services, download our app or browser extension, scan a QR code or other information relating to a purchase, use a coupon or in-store offer, or otherwise interact with the Services or with Tokenfy.

We also collect personal information if you contact us via telephone, email, text, postal mail, social media interaction, or other methods of communication, such as for customer support purposes.

Personal Information We Collect Indirectly From You

When you use our Services, we collect information using technologies such as cookies, pixels, tags, web beacons, data analytics tools, browser analysis tools, and web server logs. For example, when you access our Services, Tokenfy and our service providers may place cookies on your computer or mobile devices. These cookies may include means for tracking your personal information and include tracking technology from third-party affiliate-network operators. Cookies allow us to recognize you when you return, and track and target your interests in order to provide a customized experience. They also help detect certain kinds of fraud. The information from cookies and related technology is stored in web server logs and also in web cookies kept on your computers or mobile devices, which are then transmitted back to our Services by your computers or mobile devices. These servers are operated and the cookies managed by us, our business partners or our service providers.

If you use the Services on a mobile device and your mobile device's settings allow it, we may collect personal information even if you are not logged into one of the Services. In some cases, for example, we may use your IP address to estimate your location, even if you have not logged in or enabled location tracking on your mobile device. Additionally, we may associate your geolocation information with other personal information.

Personal Information We Obtain From Third Parties

We may obtain personal information about you from other sources, including service providers, third parties, partners or publicly available sources. We use this information to supplement and enhance the personal information we already have about you to help us better understand you, provide you with targeted advertising, and to otherwise enhance the Services.
`,
`USE OF INFORMATION`,
`We may use your personal information for one or more of the following business or commercial purposes:

Operate and improve our Services;
Provide you with content and advertisements across devices, including those based upon your interests, preferences, shopping habits and location;
Evaluate your eligibility for certain coupons, products or services;
Confirm a purchase;
Evaluate the types of coupons, products or services that may be of interest to you;
Track redemption of coupons;
Perform analytics;
Provide user, merchant and business partner support;
Fulfill requests for or relating to the Services;
Communicate and provide additional information that may be of interest to you about Tokenfy and our merchants and business partners;
Send you reminders, technical notices, updates, security alerts and support and administrative messages service bulletins;
Provide push notifications when you arrive at, stay near or leave places;
Infer the location of the places you visit most often, determine your frequency at these locations, and send notifications when you arrive at or leave those places;
Provide advertisements to you through the Services, third-party sites, email messages, text messages, mobile-push notifications, or other methods of communication;
Administer surveys, contests, or other promotional activities or events sponsored by us or our partners;
Create user profiles that may contain inferences about your interests;
Provide targeted offers that may be of interest to you and other marketing;
To help verify accounts and activity, and to promote safety and security on and off of our Services, such as by investigating suspicious activity or violations of our terms or policies and assessing the relative risk that a particular activity may be unauthorized or fraudulent;
Manage our everyday business needs such as website administration and fraud prevention;
Conducting analysis for the improvement and optimization of the Program;
Allow you to apply for a job;
Communicate with you concerning transactions and provide general customer service and respond to your inquiries;
Provide information in order to respond to legal, regulatory and audit requests, including, without limitation, those from a government authority or a payment organization involved in a transaction with you or a merchant, and to resolve disputes and defend claims against or by Tokenfy concerning the Program; and
Carry out other purposes that are disclosed to you and to which you consent.
`,
`DISCLOSING, SHARING AND SELLING PERSONAL INFORMATION`,
`We may disclose your personal information to a third party for a business purpose or share your personal information for a commercial purpose.`,
`DISCLOSING PERSONAL INFORMATION FOR A BUSINESS PURPOSE`,
`When we disclose personal information for a business purpose, we enter into a contract or other legally binding transaction that describes the purpose and requires the recipient to both keep personal information confidential and not use it for any purpose except performing the responsibilities outlined in the contract.

In the preceding twelve (12) months, we may have disclosed or shared the following categories of personal information with our service providers:

Identifiers
Records statute
Commercial information
Internet or other similar network activity
Geolocation data
Inferences drawn from other personal information
We share information with our service providers in order for them to perform the following services for us or on our behalf:

Support, analyze and improve the Services;
Conduct marketing and promotion;
Verify accounts, activity and purchases;
Process or issue payments;
Promote safety and security on and off of our Services, such as by investigating suspicious activity;
Manage our everyday business needs including website, email, mobile and other communication administration;
Operate other promotions;
Track and prevent fraud;
Measure the effectiveness of our ads, managing how many times you may see an ad and reporting on the performance of our ads;
Ensure our services are working correctly and securely;
Provide aggregate statistics and analytics, improving when and where you may see ads and/or reducing ad fraud;
Conduct analysis for optimization of the Services; and
Assist us in operating our business and Services.
We may also disclose your personal information to affiliates under a common control; if we have a chance of corporate control such as an acquisition, merger, sale of our assets, reorganization; or if we are involved in a bankruptcy.
`,
`SHARING AND SELLING PERSONAL INFORMATION TO THIRD PARTIES`,
`In the preceding twelve (12) months, we may have sold or shared the following categories of personal information for a commercial purpose:

Identifiers
Records statute
Commercial information
Internet or other similar network activity
Geolocation data
Inferences drawn from other personal information
We may have sold or shared your personal information with the following categories of third parties:

Advertisers
Affiliate networks
Merchant partners
Data aggregators
Analytics Companies
Publishers
Business Partners
We may allow third parties to use tracking devices, pixels and tags through or on the Services to collect the same type of information for the same purposes as Tokenfy does for itself. For example, Tokenfy has relationships with third party advertising technology and analytics companies to perform tracking and reporting functions for our Services and, in some cases, to place targeted advertisements on third-party websites. Additionally, we may share personal information from or about you with third parties to facilitate the display of targeted advertising. Third parties may be able to associate the information they collect with other information they have about you from other sources.
`,
`YOUR RIGHTS AND CHOICES`,
`The CCPA provides California consumers with specific rights regarding their personal information. This section describes your CCPA rights and explains how to exercise those rights.`,
`RIGHT TO KNOW`,
`You have the right to request that Tokenfy disclose the personal information we have collected about you over the 12 months preceding your request. After we validate your request, we will provide a list of the following:

The categories of personal information we collected about you;
The specific pieces of personal information we collected about you, if reasonably available or possible for us to do so;
The categories of sources;
Our business or commercial purpose for collecting or selling that personal information;
The categories of third parties with whom we shared or sold that personal information; and
The personal information categories that we sold or shared.
You may make a right to know request by clicking here
`,
`RIGHT TO DELETE`,
`You also have the right to request that we delete the personal information that we have collected from or about you, subject to certain exceptions. After we validate your request and within the stated time period, we will delete, de-identify or aggregate your personal information and will direct our service providers to delete your personal information, unless an exception applies.

You may request deletion by clicking here

You have the right to make a request to know or delete two times in any 12-month period. You may also delete your Tokenfy account. You can find instructions on how to delete your account here.
`,
`VERIFIABLE REQUESTS TO KNOW AND/OR TO DELETE`,
`Only you, or someone legally authorized to act on your behalf, may make a request to know and/or delete your personal information.

In order for us to process your request(s) you must provide us with sufficient information for us to reasonably verify that you are the person about whom we collected the personal information, or an authorized representative of that person, including verifying that you are a Nigerian resident. If you use an authorized agent to submit a request for disclosure or deletion we may require that you provide the authorized agent written permission to submit your request, and for you to verify your identity directly with us.
We may deny your request to know and/or delete if we cannot verify your identity to a reasonable or reasonably high degree of certainty, depending upon the nature of the information you are requesting to know or requesting be deleted. If there is no reasonable method by which we can identify you, we will explain this to you in our response.

Any disclosures we provide will only cover the 12-month period preceding the verifiable request.

If we cannot respond to a request we will explain the reasons in writing.

We do not charge a fee to process or respond to your verifiable consumer request unless it is excessive, repetitive, or manifestly unfounded. If we determine that the request warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before completing your request.

Note that Tokenfy will honor requests to know and/or requests to delete only from verifiable Nigerian residents.
`,
`OPTING OUT OF THE SALE OF YOUR PERSONAL INFORMATION`,
`You may opt out of us selling or disclosing your personal information for commercial purposes and if you do so we will instruct the third parties with whom we share or sell personal information to opt you out of future sale. To exercise the right to opt-out of the sale of your information, you may submit a request by visiting the Contact Us link. You do not need to create an account with us to exercise your opt-out rights. We will only use personal information provided in an opt-out request to review and comply with the request.

Note that although we will not sell your personal information after you click the button, we will continue to share some personal information with our partners (who will function as our service providers in such instances) to help us perform business functions such as, but not limited to, measuring the effectiveness of our ads, managing how many times you see an ad, reporting on the performance of our ads, processing or fulfilling transactions, ensuring our services are working correctly and securely, providing aggregate statistics and analytics, reducing ad fraud and providing services similar to these.

If you access this site or app from other devices or browsers, you should visit the Contact Us link from those devices or browsers as well to ensure your choice applies to the data collected when you use those devices or browsers.

Although contacting us will opt you out of the sale of your personal information, it will not opt you out of the use of previously collected and sold personal information or all interest-based advertising. If you would like more information about how to opt out of interest-based advertising and tracking in desktop and mobile browsers on a particular device. You can also use the platform controls on your mobile device to opt out of certain activities.

Opting out will not prevent the display of all advertisements to you. If you opt out of sale of your personal information by us, but you do not opt out of interest-based advertising more generally, you may still receive ads tailored to your interests based upon personal information exchanged before your opt-out request, or otherwise sold by parties other than Tokenfy from which you have not opted out.

You may adjust how your browser handles cookies and related technologies by adjusting its privacy and security settings. Browsers are different, so refer to instructions related to your browser to learn about cookie-related and other privacy and security settings that may be available.

If you access any of our Services through one of our mobile applications, you may manage how your personal information is shared with us. You may adjust the settings on your mobile device to allow or restrict the sharing of geolocation data. For example, the "Location" permissions (or "Privacy" >> "Location Services" on iOS-based devices) on your mobile device will allow you to elect whether to never share Location Information with Tokenfy, to share geolocation information only while you are using the application (e.g., to help you find nearby offers), or always share geolocation information even while you are not using the application (e.g., alert you of nearby offers when you approach a shopping mall). You may also elect to only disable "Bluetooth" to prevent just the sharing of information collected via nearby Bluetooth proximity beacons. Please note that if you elect to not share your geolocation information, you may be unable to access some features of our Services that are designed for mobile devices. Also, in the event that you prevent the sharing of geolocation information, we may still estimate your general location based on the IP address you use to access our Services.

You may manage how your mobile device handles ad-related tracking technologies by adjusting your mobile device privacy settings. For example, the "Privacy" settings of an iOS device will allow you to "Limit Ad Tracking" and reset your mobile device’s "Advertising Identifier."

You can also opt-out of receiving different types of push notifications or alerts from us by toggling off the "Notifications" options within the "Settings" menu of our mobile applications.
`,
`NON-DISCRIMINATION`,
`We will not discriminate against you for exercising your CCPA rights.`,
`CHANGES TO OUR PRIVACY NOTICE`,
`We reserve the right to modify this notice at any time. We encourage you to periodically review this page for the latest information about our privacy practices. If we make changes to this notice, we will post an updated page in a timely manner.`,
`CONTACT INFORMATION`,
`If you have any questions or comments about this notice; the waays in which we collect, use, share or sell your information; your choices; or how to exercise your rights, please do not hesitate to contact us:

By reaching us on our site

contact@tokenfy.io
`
                                ].map((item,index)=>(
                                    <p className="terms" key={index}>{item}</p>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </section>
           <Footer />
        </>
    )
}