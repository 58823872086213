import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Login from './Pages/Login/Login'
import CreateAccount from './Pages/Login/CreateAccount'
import NotFound from './NotFound'
import CreateCupons from './Pages/Compons/create-cupon'
import Cupons from './Pages/Compons/cupons'
import Review from './Pages/Reviews/review'
import ViewCupon from './Pages/Compons/viewCupon'
import EditCupon from './Pages/Compons/editCupon'
import DashbordUsers from './Pages/Dashboard/Users'
import CreateMerchant from './Pages/Dashboard/create_merchant'
import MerchantOverview from './Pages/Dashboard/merchantOverview'
import Merchants from './Pages/Dashboard/merchants'
import AdminTasks from './Pages/Admins/admin-tasks';
import CreateTask from './Pages/Admins/create-task';
import AdminList from './Pages/Admins/admin-list';
import CreateAdmin from './Pages/Admins/create-admin';
import AccountSettings from './Pages/Settings/settings';
import DashboardOverview from './Pages/Dashboard/overview';
import QrCoupons from './Pages/QRCoupon/QrCoupons';
import CreateQrCoupons from './Pages/QRCoupon/createQrCoupon';
import CreateQrCouponsTwo from './Pages/QRCoupon/createCoupon_two';
import "./App.css";
import TrashList from "./Pages/Trash/trash";
import ReviewTable from './Pages/Reviews/ReviewTable'
import Wallet from './Pages/Dashboard/wallet';
import UserDetails from "./Pages/Dashboard/user_details";
import AddReward from "./Pages/Dashboard/add-reward";
import CouponType from "./Pages/Dashboard/coupon_type";
import CreateCategory from "./Pages/Dashboard/create_category";
import 'react-notifications/lib/notifications.css';
import {NotificationManager,NotificationContainer} from 'react-notifications'
import PushNotifications from "./Pages/Dashboard/push_notifications";
import CreateTag from './Pages/Dashboard/create_tag';
import { Home } from "./Pages/Home/home";
import {Policy} from './Pages/Home/policy';
import {Terms} from './Pages/Home/terms';
import { FAQ } from "./Pages/Home/faq";
import DefaultLayout from "./Components/Layout/Default";
import Subscribers from "./Pages/Dashboard/subscribers";
class App extends Component {
	render() {
		return (
	<React.Fragment>
			<NotificationContainer/>
			<Router>
				{/* <NotificationContainer /> */}
				<Switch>
					<Route exact="/" path="/" component={Home}/>

					<Route exact="/policy" path="/policy" component={Policy} />
					<Route exact="/terms" path="/terms" component={Terms} />
					<Route exact="/faq" path="/faq" component={FAQ}/>

					{/* <Route exact="/login" path="/login" component={Login}/> */}
					
					{/* <Route exact="/create_account" path="/create_account" component={CreateAccount}/> */}
					<DefaultLayout>
						<Route exact="/create_coupons/:id/:type" path="/create_coupons/:id/:type" component={CreateCupons}/>
						<Route exact="/submitted_coupon/:id/:type" path="/submitted_coupon/:id/:type" component={CreateCupons}/>
						<Route exact="/create_qr_coupons/:id/:type" path="/create_qr_coupons/:id/:type" component={CreateQrCoupons}/>
						<Route exact="/coupons" path="/coupons" component={Cupons}/>
						<Route exact="/review/:review_id" path="/review/:review_id" component={Review}/>
						<Route exact="/review/:review_id" path="/dashboard/subscribers" component={Subscribers}/>
						<Route exact="/all_reviews" path="/all_reviews" component={ReviewTable} />
						<Route exact="/view_coupon/:id" path="/view_coupon/:id" component={ViewCupon}/>
						<Route exact="/view_qr_coupon/:id" path="/view_qr_coupon/:id" component={ViewCupon}/>
						<Route exact="/view_submitted_coupon/:id" path="/view_submitted_coupon/:id" component={ViewCupon}/>
						<Route exact="/edit_coupon" path="/edit_coupon" component={EditCupon}/>
						<Route exact="/dashboard/users" path="/dashboard/users" component={DashbordUsers}/>
						<Route exact="/dashboard/add_merchants" path="/dashboard/add_merchants" component={CreateMerchant}/>
						<Route exact="/dashboard/add_merchants/:merchant_id" path="/dashboard/add_merchants/:merchant_id" component={CreateMerchant}/>
						<Route exact="/merchants_overview/:merchant_id" path="/merchants_overview/:merchant_id" component={MerchantOverview}/>
						<Route exact="/merchants/users_overview" path="/merchants/users_overview" component={Merchants}/>
						<Route exact="/admin-tasks" path="/admin-tasks" component={AdminTasks} />
						<Route exact="/admin-task" path="/create-task" component={CreateTask} />
						<Route exact="/admin-list" path="/admin-list" component={AdminList} />
						<Route exact="/create-admin" path="/create-admin" component={CreateAdmin} />
						<Route exact="/create-admin/:admin_id" path="/create-admin/:admin_id" component={CreateAdmin} />
						<Route exact="/account-settings" path="/account-settings" component={AccountSettings} />
						<Route exact="/trash-list" path="/trash-list" component={TrashList} />
						<Route exact="/dashbord-overview" path="/dashboard-overview" component={DashboardOverview} />
						<Route exact="/qr_coupons" path="/qr_coupons" component={QrCoupons} />
						<Route exact="/create_qr_coupon" path="/create_qr_coupon" component={CreateQrCoupons} />
						<Route exact="/create_qr_coupon_two" path="/create_qr_coupon_two" component={CreateQrCouponsTwo} />
						<Route exact="/wallet/:user_id" path="/wallet/:user_id" component={Wallet} />
						<Route exact="/user-details/:user_id" path="/user-details/:user_id" component={UserDetails} />
						<Route exact="/add-reward/:user_id" path="/add-reward/:user_id" component={AddReward} />
						<Route exact="/add-reward/:user_id/:reward_id" path="/add-reward/:user_id/:reward_id" component={AddReward} />
						<Route exact="/create-coupon-type" path="/create-coupon-type" component={CouponType} />
						<Route exact="/create-coupon-type/:coupon_type_id" path="/create-coupon-type/:coupon_type_id" component={CouponType} />
						<Route exact="/create-category" path="/create-category" component={CreateCategory} />
						<Route exact="/create-category/:category_id" path="/create-category/:category_id" component={CreateCategory} />
						<Route exact="/push_notifications" path="/push_notifications" component={PushNotifications} />
						<Route exact="/create-tag" path="/create-tag" component={CreateTag} />
						

						<Route exact="/create-tag/:tag_id" path="/create-tag/:tag_id" component={CreateTag} />
					
					</DefaultLayout>

					<Route path="*" component={NotFound} />
				</Switch>
			</Router>	
</React.Fragment>
		);
	}
}

export default App;