import React,{useState,useEffect} from 'react'
import {Layout} from '../../Components/Layout/layout'
import "react-datepicker/dist/react-datepicker.css";
import {httpGet,httpPost, httpPut} from '../../Components/helpers/httpMethods'
import {hideLoader, showLoader} from '../../Components/helpers/loader'
import {NotificationManager} from 'react-notifications';
import { useParams } from 'react-router-dom';

export default function CreateTag(props) {
    const [tag, setTag] = useState({
        type: "",
        description: ""
    });

    const {tag_id} = useParams();
    
    const handleSubmit=async(e)=>{
           e.preventDefault();
           try {
                showLoader()
                if(tag_id){
                    let res = await httpPut(`tag/${tag_id}`,tag);
                    hideLoader();
                    return NotificationManager.success("Record has been updated")
                }
                await httpPost("/tag",tag)
                hideLoader()
                setTag({
                    type: "",
                    description: ""
                });
                NotificationManager.success(
                    "Tag created successfully",
                    "Yepp",
                    4000
                );
                hideLoader()
        } catch (error) {
            error.data ? NotificationManager.error(
                error.data.message,
               "Opps",
               3000
           ) : NotificationManager.error(
            "Network Error! Please retry",
           "Opps",
           3000
       );;
            hideLoader()
        }
    }
       
    const getTag = async () => {
        try{
            showLoader();
            let res = await httpGet(`tag/${tag_id}`);
            setTag(res.tagExist);
            hideLoader();
        }catch(error){
            hideLoader();
            error.data ? NotificationManager.error(error.data.message) : NotificationManager.error("Opps! Please check your internet");
        }
    }
    const  handleChange=(e)=>{
            setTag({...tag, [e.target.name]: e.target.value })
    }
    
    useEffect(()=>{
        if(tag_id){
            getTag();
        }
    },[

    ]);
    return (
        <form onSubmit={handleSubmit}>
            <Layout pageName={tag_id ? `Edit` : 'Create'} subPageName="Tag"> 
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="">Tag title<span>*</span></label>
                            <div className="inputbox-cupon">
                                <input value={tag.type} name="type" onChange={handleChange} required type="text" className="form-control"/>
                            </div>
                        </div>
                    </div>
                   <div className="col-md-6">
                       <div className="form-group">
                            <label htmlFor="">Descriptions<span>*</span></label>
                            <div className="inputbox-cupon">
                                <textarea value={tag.description}  name="description" onChange={handleChange} required type="text" className="form-control" rows='5' cols='6' />
                                <span className="text-primary">500 <span>Characters left.</span></span>
                            </div>
                       </div>
                   </div>
                   <div className="col-md-12 pr-5">
                        <button className="btn btn-primary mr-3 pull-right">Submit</button>
                    </div>

                </div>
        
            </Layout>
        </form>
        
    )
}


