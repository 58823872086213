import React,{useState,useEffect} from 'react'
import {Layout} from '../../Components/Layout/layout'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Link} from 'react-router-dom';
import ArrowRightPNG from '../../Components/Assets/icons/arrow_right.png';
import LightRightPNG from '../../Components/Assets/icons/light_right_arrow.png';
import {httpGet, httpPatch, httpPostFormData,httpPost, httpDelete} from '../../Components/helpers/httpMethods'
import {hideLoader, showLoader} from '../../Components/helpers/loader'
import {NotificationManager} from 'react-notifications';
import moment from 'moment';

import {
    ComposedChart,Bar, XAxis, YAxis, Tooltip,
    Legend,
  } from 'recharts';
import axios from 'axios'

const DashboardOverview = (props) => {
    useEffect(() => {
        Overview()
    }, [])

    const [isEmpty,setEmpty] = useState(false);
    
    const [overviewData,setoverviewData] = useState([])
    const [getAllCouponType,setgetAllCouponType] = useState([])
    const [getAllcategory,setgetAllcategory] = useState([]);
    const [topClaims,setTopClaims] = useState([]);
    const [topMarchants, setTopMarchant] = useState([])
    const [startDate,setStartDate] = useState(null);
    const [endDate,setEndDate] = useState(null);
    const [tags,setTags] = useState([]);
    const  Overview =async()=>{
        try {
            showLoader()
            setStartDate(null);
            setEndDate(null);
            const res1 = await httpGet(`admin/overview/`)
            const res2 = await httpGet(`coupon_type/retrieve/`)
            const res3 = await httpGet(`admin/categories/`)
            const res4 = await httpGet(`/tag`)
            const all = await axios.all([res1, res2,res3,res4])
            setoverviewData(all[0].overviewResult)
            setgetAllCouponType(all[1].couponType) 
            setTopClaims(all[0].overviewResult.top_categories.splice(0,4).map((value)=>{
                return {
                    name : value.category,
                    claims : value.totalClaimed
                }
            }))
            setgetAllcategory(all[2].categories)
            setTags(all[3].tag)
            hideLoader()
        } catch (error) {
          hideLoader()
        }
    }
    
    const filterByDate = async () =>{
        try{
            if(!startDate || !endDate){
                return false;
            }
            if(new Date(startDate).getTime() > new Date(endDate).getTime()){
                return NotificationManager.error("Start date can not be greater than end date")
            }
            showLoader()
            let res = await httpGet(`admin/overview?startDate=${moment(startDate)
                .format('YYYY-MM-DD')}&&endDate=${moment(endDate).format('YYYY-MM-DD')}`);
            setoverviewData(res.overviewResult)
            hideLoader()
        }catch(error){
            console.log(error);
            hideLoader();
            NotificationManager.error("Network Error. Please try again")
        }
    }
    
    return (
        <div>
            <Layout pageName="Overview">
                <section className="page-section">
                    <div className="row">
                        <div className="col-lg-3 col-sm-4">
                            <DatePicker
                                selected={startDate}
                                onChange={date => {
                                    setStartDate(date)
                                }}
                            />
                            <div className="custom-input-group-prepend">
                                <i className="fa fa-calendar"></i>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-4">
                            <DatePicker 
                                selected={endDate}
                                onChange={date => {
                                    setEndDate(date)
                                }}
                            />
                            <div className="custom-input-group-prepend">
                                <i className="fa fa-calendar"></i>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-4">
                            <span className="fa fa-search mt-3 text-primary"
                                onClick={filterByDate}
                            ></span>
                        </div>
                    </div>
                </section>

                <section className="page-section">
                    <div className="row">
                        <div className="col-xl-7 col-md-12 mb-3">
                            <div className="card overview-card">
                                <h5 className="bold-550">
                                    Top Merchants
                                    <Link to="/merchants/users_overview" className="view-more-link text-primary pull-right"> 
                                        <span className="mr-1">View</span> 
                                        <i className="fa fa-angle-right"></i>
                                        <i className="fa fa-angle-right"></i>
                                        <i className="fa fa-angle-right"></i>
                                    </Link>
                                </h5>
                                <div className="">
                                    <table id="dtBasicExample" class="table table-responsive table-striped table-no-bordered" cellspacing="0" width="100%">
                                        <thead className="mb-20">
                                            <tr>
                                                <th className="th-sm" style={{width:'10%'}}>NO
                                                </th>
                                                <th className="th-sm" style={{width:'25%'}}>MERCHANTS
                                                </th>
                                                <th className="th-sm" style={{width:'35%'}}>
                                                    CLAIMED COUPONS
                                                </th>
                                                <th className="th-sm" style={{width:'25%'}}>
                                                    TOTAL
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                overviewData.top_merchants && overviewData.top_merchants.map((res,index)=>(
                                                    <tr key={index}>
                                                        <td>
                                                            {index+1}
                                                        </td>
                                                        <td>
                                                           {res.merchantName}
                                                        </td>
                                                        <td>
                                                            {res.totalClaimed === null ? "0 ": `${res.totalClaimed} `}
                                                        </td>
                                                        <td className="text-center">
                                                            {res.totalCoupon === null ? "0 ": `${res.totalCoupon} `}
                                                             <span className="fa fa-arrow-up text-success"></span>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-5 col-md-12">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="card overview-card">
                                        <h6 className="text-muted bold-550">Total Users</h6>
                                        <h1 className="bold-550">{overviewData.totalUsers}</h1>
                                        <Link to="/dashboard/users" className="view-more-link text-primary text-right"> 
                                            <span className="mr-1">View</span> 
                                            <i className="fa fa-angle-right"></i>
                                           <i className="fa fa-angle-right"></i>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="card overview-card">
                                        <h6 className="text-muted bold-550">Total Merchants</h6>
                                        <h1 className="bold-550">{overviewData.totalMerchants}</h1>
                                        <Link to="/merchants/users_overview" className="view-more-link text-primary text-right"> 
                                            <span className="mr-1">View</span> 
                                            <i className="fa fa-angle-right"></i>
                                            <i className="fa fa-angle-right"></i>
                                            <i className="fa fa-angle-right"></i>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="card mt-3 overview-card">
                                        <h6 className="text-muted bold-550">Total Coupons</h6>
                                        <h1 className="bold-550">{overviewData.totalCoupons}</h1>
                                        <Link to="/coupons" className="view-more-link text-primary text-right"> 
                                            <span className="mr-1">View</span> 
                                            <i className="fa fa-angle-right"></i>
                                            <i className="fa fa-angle-right"></i>
                                            <i className="fa fa-angle-right"></i>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="card overview-card top-claims mt-3">
                                        <h5 className="text-muted bold-550">Top Claimed Categories</h5>
                                        <div>
                                            <ComposedChart
                                                layout="vertical"
                                                width={350}
                                                height={400}
                                                data={topClaims}
                                                margin={{
                                                top: 20, right: 20, bottom: 20, left: 20,
                                                }}
                                            >
                                                <XAxis type="number" />
                                                <YAxis dataKey="name" type="category" />
                                                <Tooltip />
                                                <Legend />
                                                <Bar dataKey="claims" barSize={20} fill="#2D4EF5" />
                                            </ComposedChart>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="page-section">
                    <h3 className="bold-550 mb-3">Categories</h3>
                    <div className="row">
                        {
                            [...getAllcategory].map((value,index)=>(
                                <div class="col-md-6 col-lg-4 col-xl-3 mb-3" key={index}>
                                    <div className="card item-card overview-card">
                                        <h5 className="bold-550">{value.category}</h5>
                                        <div className="card-description-container">
                                            <p>{value.description}</p>
                                        </div>
                                        <Link to={`/create-category/${value.id}`} className="view-more-link text-primary text-right"> 
                                            <div className="icon-image-container-no-margin text-right">
                                                <img src={ArrowRightPNG}/>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            ))
                        }
                        <div class="col-md-6 col-lg-4 col-xl-3 mb-3">
                            <div className="card item-card bg-primary">
                                <h5 className="bold-550">Add new Category</h5>
                                <p>Create a new category of coupons</p>
                                <Link to="/create-category" className="view-more-link text-primary text-right mt-3"> 
                                    <div className="icon-image-container-no-margin text-right">
                                        <img src={LightRightPNG}/>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>

                </section>

                <section className="page-section">
                    <h3 className="bold-550 mb-3">Coupon Type</h3>
                    <div className="row">
                        {
                            [...getAllCouponType].map((value,index)=>(
                                <div class="col-md-6 col-lg-4 col-xl-3 mb-3" key={index}>
                                    <div className="card item-card overview-card">
                                      <h5 className="bold-550">{value.couponType}</h5>
                                        <div className="card-description-container">
                                            <p>{value.description}</p>
                                        </div>
                                        <Link to={`/create-coupon-type/${value.id}`} className="view-more-link text-primary text-right"> 
                                            <div className="icon-image-container-no-margin text-right">
                                                <img src={ArrowRightPNG}/>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            ))
                        }
                        <div class="col-md-6 col-lg-4 col-xl-3 mb-3">
                            <div className="card item-card bg-primary overview-card">
                                <h5 className="bold-550">Add new coupon type</h5>
                                <p>Create a new coupon type</p>
                                <Link to="/create-coupon-type" className="view-more-link text-primary text-right mt-3"> 
                                    <div className="icon-image-container-no-margin text-right">
                                        <img src={LightRightPNG}/>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
               
                <section className="page-section">
                    <h3 className="bold-550 mb-3">Tags</h3>
                    <div className="row">
                        {
                            [...tags].map((value,index)=>(
                                <div class="col-md-6 col-lg-4 col-xl-3 mb-3" key={index}>
                                    <div className="card item-card overview-card">
                                      <h5 className="bold-550">{value.type}</h5>
                                        <div className="card-description-container">
                                            <p>{value.description}</p>
                                        </div>
                                        <Link to={`/create-tag/${value.id}`} className="view-more-link text-primary text-right"> 
                                            <div className="icon-image-container-no-margin text-right">
                                                <img src={ArrowRightPNG}/>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            ))
                        }
                        <div class="col-md-6 col-lg-4 col-xl-3 mb-3">
                            <div className="card item-card bg-primary">
                                <h5 className="bold-550">Add new tag</h5>
                                <p>Create a new tag</p>
                                <Link to="/create-tag" className="view-more-link text-primary text-right mt-3"> 
                                    <div className="icon-image-container-no-margin text-right">
                                        <img src={LightRightPNG}/>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>

           
        </Layout>
    </div>
    )
}
export default DashboardOverview;


