import React,{useState,useEffect} from 'react'
import {Layout} from '../../Components/Layout/layout'
import './cupon.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Link} from 'react-router-dom';
import User from '../../Components/Assets/user.png'
import EditPNG from '../../Components/Assets/icons/white_edit.png';
import {httpGet, httpPatch, httpPostFormData,httpPost, httpDelete} from '../../Components/helpers/httpMethods'
import {hideLoader, showLoader} from '../../Components/helpers/loader'
import {NotificationManager} from 'react-notifications'
import DateFormat from '../../Components/helpers/dateFormater'
import ActivateAndDeactiveModal from '../../Components/Modals/ActivateAndDeactivate'
import ComfirmBlock from '../../Components/Modals/blockModal'
import Goback from '../../Components/helpers/GoBack';
import moment from 'moment';
export default function ViewCupon(props) {

    useEffect(() => {
        getCoupons()
        getClaims();
    }, [])

    const [coupon, setCoupon] = useState([])

    const getMerchant = async (merchant_id) => {
        try{
            const res = await httpGet(`admin/merchant/${merchant_id}`);
            setMerchant(res);
        }catch(error){
            NotificationManager.error(error.data.message)
        }
    }

    const  getCoupons =async()=>{
        let id = props.match.params.id
        showLoader()
        try {
            let query = window.location.pathname.includes("view_qr_coupon") ? `/qr_coupon/one_Coupon/${id}` : 
            window.location.pathname.includes('view_submitted_coupon') ? `/admin/submitted/coupons/${id}` : `admin/coupon/${id}/`;
            
            let res = await httpGet(query);
            console.log(res);
            let merchant_id = window.location.pathname.includes("view_submitted_coupon") ? res.merchantId : res.coupon.merchantId
            let coupon = window.location.pathname.includes("view_submitted_coupon") ? res : res.coupon
            getMerchant(merchant_id)
            setCoupon(coupon);
            hideLoader()
        } catch (error) {
          hideLoader()
          console.log(error);
          //NotificationManager.error(error);
        }
    }

    const [claims, setClaims] = useState([])
    const [merchant,setMerchant] = useState({});

    const  getClaims =async()=>{
        let id = props.match.params.id
        showLoader()
        try {
            const res = await httpGet(`admin/coupon/${id}/claims/`);
            setClaims(res);
            hideLoader();
        } catch (error) {
          hideLoader()
        }
    }

    const handleActivate=async()=>{
        showLoader()
        let data ={
            couponId:props.match.params.id,
            action:coupon.status === "active" ? 0 : 1
        }
        try {
            let query = window.location.pathname.includes('view_qr_coupon') ? `/qr_coupon/activate_or_deactivate/` :
            `admin/coupon/activate_or_deactivate/`;
            let res = await httpPatch(query,data)
            getCoupons();
            NotificationManager.success(
                "Updated successfully",
                "Yepp",
                3000
            );
            hideLoader();
        } catch (error) {
            hideLoader()
            NotificationManager.error(
                error,
                "Opps",
                3000
            );
        }
    }
  

      const DeleteData = async()=>{
      showLoader()
      try {
        let query = window.location.pathname.includes('view_qr_coupon') ? `/qr_coupon/delete/${props.match.params.id}` :
        window.location.pathname.includes('view_submitted_coupon') ? `/admin/submitted/coupons/${props.match.params.id}` :
         `admin/coupon/${props.match.params.id}`;
          const res = await httpDelete(query)
          hideLoader()
          NotificationManager.success(
                        "deleted successfully",
                       "Yepp",
                       3000
                   );
                   props.history.goBack()
      } catch (error) {
          hideLoader()
          console.log(error)
      }
  } 

   

    return (
        <div>
            <Layout  pageName={` ${merchant.merchantName} ${coupon.percentageAmount}% ${coupon.CouponType ? coupon.CouponType.couponType : ""}`}>

                <div className="viewCupon-actions">
                    <div className="edit-btn-cupon">
                        <Link to={window.location.pathname.includes('view_qr_coupon') ? `/create_qr_coupons/${props.match.params.id}/edit` 
                            : window.location.pathname.includes('view_submitted') ? `/submitted_coupon/${props.match.params.id}/edit` : `/create_coupons/${props.match.params.id}/edit`}>
                            <button>Edit  <div class="icon-image-container"><img src={EditPNG}/></div></button>
                        </Link>
                    </div>
                    <div className="Deactivate-btn-cupon">
                        {
                            !window.location.pathname.includes("view_submitted_coupon") && (
                                <button  data-toggle="modal" data-target="#activateAndDeactivateModal" >
                                    {coupon.status === "active" ? "Deactivate" : "Activate"} 
                                    <span className="fa fa-times-circle-o"></span>
                                </button>
                            )
                        }
                            <button data-toggle="modal" data-target="#BlockModal">Delete <span className="fa fa-trash"></span></button>
                        </div>
                </div>

                <div className="cupon_statics">
                    <div className="cuponImg">
                        <img src={coupon.image === null ? User : coupon.image}/>
                        <div className="about-viewCupon">
                            <p>{merchant.merchantName}</p>
                            <button id={coupon.status === 'inactive' && 'inactive-status-indicator'}>
                                {coupon.status === 'inactive' ? 'Inactive' : 'Active'}
                            </button>
                        </div>
                    </div>
                    <div className="cupon-detailsbe7">
                        <p> <span>Limit:</span>  {coupon.limits}</p>
                        <p> <span>Category:</span>  {coupon.Category ? coupon.Category.category : ""}</p>
                    </div>

                    <div className="cupon-detailsbe7">
                        <p><span>Coupon Type:</span> {coupon.CouponType ? coupon.CouponType.couponType : ""}</p>
                        <p><span>Tokenfy ID:</span>   {merchant.tokenfyId}</p>
                    </div>

                    <div className="cupon-detailsbe7">
                        <p> <span>Start Date:</span> {DateFormat(coupon.startDate)}</p>
                        <p> <span>End Date: </span> {DateFormat(coupon.endDate)} </p>
                    </div>
                </div>

                <div className="des-section76g">
                    <h1>Description</h1>
                    <p>{coupon.description}</p>
                </div>
                  <div className="rest76">
                      <h1>Restrictions</h1>
                       <div className="restriction-cupons">
                      <p>{coupon.restriction}</p>
                   
                </div>
                  </div>

                  <div className="cupon-claimers">
                    <span>Claimers</span>
                    <button className="pl-2 pr-2 pt-1 pb-1">{claims.claimers}</button>
                    {/* <span>coupons remaining(updated 15minutes ago)</span> */}
                  </div>
                  <div className="Table-sec">
                  <table id="dtBasicExample" class="table table-striped table-bordered" cellspacing="0" width="100%">
                        <thead className="mb-20">
                            <tr>
                                <th className="th-sm">TAKE BY
                                </th>
                                <th className="th-sm">TAKE DATE
                                </th>
                                <th className="th-sm">
                                    EXPIRING
                                </th>
                                <th className="th-sm">
                                    STATUS
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                claims.claimsToCoupon && claims.claimsToCoupon.data.map((claim,index)=>(
                                    <tr key={index}>
                                        <td>
                                            <p>{claim.User.firstName} {claim.User.lastName}</p>
                                        </td>
                                        <td>
                                            <p>{moment(claim.claimedDate).format('DD/MM/YYYY')}</p>
                                        </td>
                                        <td>
                                            <p>{moment(claim.Coupon.endDate).format('DD/MM/YYYY')}</p>
                                        </td>
                                        <td>
                                            <button className={claim.status === 'active' ? `restore-action-button mr-3` : `disable-action-button mr-3`}>{claim.status ? `Active` : `Inactive`}</button> 
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                  </div>
            </Layout>
            <ComfirmBlock deletData={DeleteData} />
            <ActivateAndDeactiveModal handleActivate={handleActivate} CouponStatus={coupon.status} />
        </div>
    )
}


