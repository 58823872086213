
import React,{useState,useEffect} from 'react'
import {Layout} from '../../Components/Layout/layout'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Link} from 'react-router-dom'
import SearchImg from '../../Components/Assets/search.png'
import {httpGet, httpPatch, httpPostFormData,httpPost, httpDelete} from '../../Components/helpers/httpMethods'
import {hideLoader, showLoader} from '../../Components/helpers/loader'
import {NotificationManager} from 'react-notifications'
import CouponsTable from '../../Components/Tables/qrCoupons'
import ComfirmBlock from '../../Components/Modals/blockModal'
import ActivateAndDeactiveModal from '../../Components/Modals/ActivateAndDeactivate'

export default function CreateCupon(props) {
    const [activePage, setActivePage] = useState("Coupon_Properties")
    const [startDate, setstartDate] = useState(new Date())
    const [actionID, setactionID] = useState()
    const [CouponStatus, setCouponStatus] = useState()
    const [actionType, setactionType] = useState();
    const [is_submitted,setSubmitted] = useState(false);
    const [couponsHolder, setHolder] = useState([])
    useEffect(() => {
        getCoupons()
        getCouponsType()
    }, [])

    const [coupons, setCoupons] = useState([])

    const  getCoupons =async(is_submitted = false)=>{
        try {
            showLoader();
            setSubmitted(is_submitted);
            let query = is_submitted ? `/admin/submitted/coupons?limit=1000` : `/qr_coupon/all_coupons?limit=1000`
            const res = await httpGet(query);
            let data = is_submitted ? res.data : res;
            setCoupons(data);
            setHolder(data);
            hideLoader()
        } catch (error) {
          hideLoader();
          NotificationManager.error(error.data.message)
        }
    }

    const [couponsType, setCouponsType] = useState([])

    const  getCouponsType =async()=>{

        try {
          showLoader()
            const res = await httpGet(`admin/coupon/types/count`);
            console.log('Res>>',res);
            setCouponsType(res);
            console.log("resss",res)
            hideLoader()
        } catch (error) {
          hideLoader();
          NotificationManager.error(error.data.message)
        }
    }

    const getId =(id,type)=>{
        setactionID(id)
        setactionType(type)
    }


    
  const DeleteData = async()=>{
      showLoader()
      try {
          let query = is_submitted ? `/admin/submitted/coupons/${actionID}` : `/qr_coupon/delete/${actionID}`;
          const res = await httpDelete(query);
          hideLoader()
        //   NotificationManager.success(
        //                 "deleted successfully",
        //                "Yepp",
        //                3000
        //            );
                   getCoupons(is_submitted);
      } catch (error) {
          hideLoader()
          console.log(error)
      }
  }  



    const handleActivate=async()=>{
        showLoader()
        let data ={
            couponId:actionID,
            action:CouponStatus === "active" ? 0 : 1
        }
        try {
            let res = await httpPatch(`admin/coupon/activate_or_deactivate`,data)
          
                NotificationManager.success(
                    "Updated successfully",
                    "Yepp",
                    3000
                );
                getCoupons()
                hideLoader()
                
            
        } catch (error) {
            hideLoader()
            NotificationManager.error(
                error,
                "Opps",
                3000
            );
        }
    }

    const  getStatus=(status,couponId)=>{
        setCouponStatus(status)
        setactionID(couponId)
    }


    return (
        <div>
            <Layout pageName="QRCoupons">
                <div className="qr-coupon-btn-head">
                    <button className={is_submitted ? `inactive-button` : `active-button`} onClick={()=>{getCoupons(false);
                    }}>QR Coupons</button>
                    <button className={!is_submitted ? `inactive-button` : `active-button`} onClick={()=>{getCoupons(true);
                    }}>Submitted Coupons</button>
                </div>
                <div className="page-router-display">
                {/* <CuponsTable/> */}
                <div className="create_cupon-btn"> <Link to="/create_qr_coupon"><button><span className="fa fa-plus">
                    </span> Create Qr Coupons</button></Link> </div>
                    <div  style={{marginBottom:"20px"}} className="page-roter-list">
                        
                        <div>
                        {
                                couponsType.map((data,index)=>{
                                  return(
                                    <div className="hsd" key={index}>
                                        {console.log('>>>',data)}
                                        <span
                                        style={{width:"fit-content"}}
                                        onClick={(e)=>{
                                            setActivePage(index === 0 ? 'all' : data);
                                            setCoupons(couponsHolder.filter((coupon)=>coupon.couponTypeId === data.id));
                                        }}
                                        className={`page-router-name 
                                        ${activePage === data || (index === 0 && activePage === 'all') ? "page-router-name-active ":""}`}>
                                            {data.type}</span><div className="c-count-copon">{data.count}</div>
                                    </div>
                                  )
                                })
                            }
                            

                            

                        </div>
                     
                    </div>

                  

                    <div className="pg-dimacation"></div>

                </div>

               
                <div className="Table-sec">

                    <CouponsTable data={activePage !== 'all' ? coupons : couponsHolder} getStatus={getStatus}  getId={getId} is_qr_coupon={!is_submitted}/>

                </div>
                           
            </Layout>
            <ComfirmBlock deletData={DeleteData} />
            <ActivateAndDeactiveModal handleActivate={handleActivate} CouponStatus={CouponStatus} />
        </div>
    )
}


