import React,{useState,useEffect} from 'react'
import {Layout} from '../../Components/Layout/layout'
import './reviews.css'
import "react-datepicker/dist/react-datepicker.css";
import avatar from '../../Components/Assets/user.png';
import moment from 'moment';
import { NotificationManager } from 'react-notifications';
import { showLoader, hideLoader } from '../../Components/helpers/loader';
import { httpGet, httpPost } from '../../Components/helpers/httpMethods';
import { useParams } from 'react-router-dom';
import Like from '../../Components/Assets/like.png';
import NoLike from '../../Components/Assets/noLike.png';

export default function Reviews(props) {
    const {review_id} = useParams();
    const ratings  = ['Not Recommended','Fairly Recommended','Recommended','Recommended','Highly Recommended']
    const getReview = async () => {
        try{
            showLoader();
            let res = await httpGet(`/review/${review_id}`);
            console.log('Review>>',res);
            setReview(res.review);
            hideLoader();
        }catch(error){
            error.data ? NotificationManager.error(error.data.message) : NotificationManager.error('Opps! Please check your internet');
            hideLoader();
        }
    }

    const submitHandler = async (event) => {
        event.preventDefault();
        try{
            showLoader();
            let fd = {
                reviewId : review_id,
                message
            }
            await httpPost('/admin/review/reply',fd);
            let res =  await httpGet(`/review/${review_id}`);
            setReview(res.review);
            setMessage('');
            NotificationManager.success('Reply has been sent');
            hideLoader();
        }catch(error){
            hideLoader();
            error.data ? NotificationManager.error(error.data.message) : NotificationManager('Opps! Please check your internet');
        }
    }

    const [review,setReview] = useState({});
    const [message,setMessage] = useState('');
    useEffect(()=>{
        getReview();
    },[]);
    return (
        <div>
            <Layout pageName="Reviews">
                <div>
                    <div className="header-comments-sec">
                        <h2>{review.Coupon ? review.Coupon.Merchant.merchantName : ""} {` `} 
                            {review.Coupon ? review.Coupon.percentageAmount : ""}% {` `} 
                            {review.Coupon ? review.Coupon.CouponType.couponType : ""}
                        </h2>
                    </div>
                    <div className="">

                        <div className="review">
                            <div className="client_review">
                                <img src={review.User ? review.User.avatar : ""} className="userAvatar" />
                                <div class="comment">
                                    <p className="">
                                        {review.review}
                                    </p>
                                </div>
                                <div>
                                    {
                                        [...Array(review.star || 0).keys()].map((item,index)=>(
                                            <div className="rating-image-container" key={index}>
                                                <img src={Like} alt=""/>
                                            </div>
                                        ))
                                    }
                                    {
                                        [...Array(5 - review.star || 0).keys()].map((item,index)=>(
                                            <div className="rating-image-container" key={index}>
                                                <img src={NoLike} alt=""/>
                                            </div>
                                        ))
                                    }
                                    <h6 className="mt-2">{ratings[review.star-1]}</h6>
                                </div>
                            </div>
                        </div>



                        {
                            review.replies && review.replies.map((review,index)=>(
                                <div className="review" key={index}>
                                    {console.log('review',review)}
                                    <div className="user_comment">
                                    <img src={avatar} className="userAvatar" />
                                        <div class="comment">
                                            <p className="">
                                                {review.reply}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div class="form comhd">
                    <form id="commentForm" onSubmit={submitHandler}>
                        <div className="input-comment-text">
                            <input  name="message" type="text" value={message} placeholder="Enter your message here" 
                                onChange={(e)=>setMessage(e.target.value)} required
                            />
                            <button className="comment_form_button">Send</button>
                        </div>
                    </form>
                </div>
            </Layout>
        </div>
    )
}


