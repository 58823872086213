import React,{useState,useEffect} from 'react'
import {Layout} from '../../Components/Layout/layout';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Link} from 'react-router-dom';
import User from '../../Components/Assets/user.png'
import EditPNG from '../../Components/Assets/icons/white_edit.png';
import {httpGet,httpPatch, httpDelete} from '../../Components/helpers/httpMethods'
import {hideLoader, showLoader} from '../../Components/helpers/loader'
import {NotificationManager} from 'react-notifications';
import { useParams} from "react-router";
import axios from 'axios';
import moment from 'moment';
import ComfirmBlock from '../../Components/Modals/blockModal'

export default function MerchantOverview(props) {
    const [merchant, setMerchant] = useState({})
    const [coupons,setCoupons] = useState([]);
    const {merchant_id} = useParams();
    const [activities,setActivities] = useState([]);
    useEffect(() => {
        getMerchant();
        getActivities();
    }, [])

    const DeleteData = async()=>{
        showLoader()
        try {
            const res = await httpDelete(`admin/merchant/${merchant.id}`)
            hideLoader()
            NotificationManager.success(
                "deleted successfully",
                "Yepp",
                3000
            );
            props.history.goBack()
        } catch (error) {
            hideLoader()
            console.log(error)
        }
    }

    const DeactivateOrActivate = async () =>{
        try{
            let fd = {
                action : merchant.isActive ? Number(0) : Number(1),
                merchantId : merchant.id
            }
            let res = await httpPatch('admin/merchant/activate_or_deactivate',fd);
           if(res.status === 'success'){
            console.log('in here>>',res);
            setMerchant({...merchant,isActive : res.data.isActive})
           }
        }catch(error){
            NotificationManager.error(error.message);
        }
    }
    const  getMerchant =async()=>{

        try {
            showLoader()
            const res = await httpGet(`admin/merchant/${merchant_id}`)
            const res1 = await httpGet(`admin/merchant/${merchant_id}/coupons`);
            setMerchant(res)
            setCoupons(res1)
            hideLoader()
        } catch (error) {
            hideLoader();
            NotificationManager.error(error.message);
        }
    }

    const getActivities = async () =>{
        try{
            showLoader();
            let res = await httpGet(`/admin/merchant/${merchant_id}/recent_activities`);
            console.log('res>>>',res);
            setActivities(res);
            hideLoader();
        }catch(error){
            hideLoader();
            NotificationManager.error("Network Error. Please try again")
        }
    }

    return (
        <div>
            <Layout pageName="Overview" subPageName="Merchant">
            <div className="viewCupon-actions">
                    <div className="edit-btn-cupon">
                        <Link to={`/dashboard/add_merchants/${merchant_id}`}>
                            <button>Edit  <div class="icon-image-container"><img src={EditPNG}/></div></button>
                        </Link>
                    </div>

                    <div className="Deactivate-btn-cupon">
                        <button 
                            className={!merchant.isActive && 'activate-button'}
                            onClick={DeactivateOrActivate}
                        >{merchant.isActive ? 'Deactivate' : 'Activate'} <span className={`fa ${merchant.isActive ? 'fa-times-circle-o' : 'fa-check'}`}></span></button>
                        <button data-toggle="modal" data-target="#BlockModal">Delete <span className='fa fa-trash'></span></button>
                    </div>

                </div>

                <div className="cupon_statics">
                    <div className="cuponImg">
                        
                        <img src={merchant.logo}/>
                        <div className="about-viewCupon">
                        <p>{merchant.merchantName}</p>
                        {
                            merchant.isActive ? (
                                <button>Active</button>
                            ): (
                                <button id="inactive-status-indicator">Inactive</button>
                            )
                        }
                    </div>
                    </div>

                    

                    <div className="cupon-detailsbe7">
                        <p> <span>Phone Number:</span>   {merchant.phoneNumber}</p>
                    <p style={{color:"#2D4EF5"}}> <span >Email:</span> {merchant.email}</p>
                    </div>

                    <div className="cupon-detailsbe7">
                    <p><span>Location:</span>  {merchant.location}</p>
                        <p><span>Tokenfy ID:</span>   {merchant.tokenfyId}</p>
                    </div>

                   
                </div>

                <div className="des-section76g">
                    <h1>Description</h1>
                    <p>
                    {merchant.description}
                    </p>
                </div>
                 

                  <div className="cupon-claimers-overview mt-5">
                      <span>Coupons</span>
                  </div>

                <div className="Table-sec">
                    {
                        coupons.length > 0 ? (
                            <table id="dtBasicExample" class="table table-striped table-bordered" cellspacing="0" width="100%">
                                <thead className="mb-20">
                                    <tr>
                                        <th className="th-sm">TYPE
                                        </th>
                                        <th className="th-sm">CATEGORY
                                        </th>
                                        <th className="th-sm">
                                            OFFER
                                        </th>
                                        <th className="th-sm">
                                            START DATE
                                        </th>
                                        <th className="th-sm">
                                            EXPIRING
                                        </th>
                                        <th className="th-sm">
                                            LIMITS
                                        </th>
                                        <th className="th-sm">
                                            STATUS
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        coupons.map((coupon,index)=>(
                                            <tr key={index}>
                                                <td>
                                                    {coupon.CouponType.couponType}
                                                </td>
                                                <td>
                                                    {coupon.Category.category}
                                                </td>
                                                <td>
                                                    {coupon.percentageAmount}%
                                                </td>
                                                <td>
                                                    {moment(coupon.startDate).format('DD/MM/YYYY')}
                                                </td>
                                                <td>
                                                    {moment(coupon.endDate).format('DD/MM/YYYY')}
                                                </td>
                                                <td>
                                                    {coupon.limits}
                                                </td>
                                                <td>
                                                    <button className={`${coupon.status === 'inactive' ? 'inactive-status-button' : 'restore-action-button'} mr-3`}>
                                                        {coupon.status === 'inactive' ? 'Inactive' : 'Active'}
                                                    </button> 
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        ) : (
                            <h4 className="text-center mt-5 mb-5">
                                No Coupon Found
                            </h4>
                        )
                    }
                </div>

                  <div className="marchant-recents-data">
                      <h1>Recent Activities</h1>

                        {
                            activities.map((activity,index)=>(
                                <div className="marchant-recents-data-wrap" key={index}>
                                        <div className="mes-merchant">
                                            <h3>{activity.title}</h3>
                                            <h2>“{activity.activity}”</h2>
                                        </div>
            
                                        <div className="mes-merchant-time">
                                           {moment(activity.createdAt).fromNow()}
                                        </div>
                                </div>
                            ))
                        }
                  </div>
            </Layout>
            <ComfirmBlock deletData={DeleteData} />
        </div>
    )
}


