import React, { Component } from 'react'
import './modal.css'
export default class BlockModal extends Component {
    render() {
        return (
            <div>
     


<div class="modal fade" id="BlockModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document" style={{width:"375px"}}>
    <div class="modal-content">
     
      <div class="modal-body">
        <div className="confirmWrapper">
            <p>Are you sure you want to delete this?</p>
            <button onClick={()=>this.props.item_id ? this.props.deletData(this.props.item_id) : this.props.deletData(this.props.item)} type="button" class="btn btn-danger" data-dismiss="modal">Delete</button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
      </div>
      <div class="modal-footer">
      </div>
    </div>
  </div>
</div>
            </div>
        )
    }
}
