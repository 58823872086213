import React from 'react'
import icons from './icons'
import MaterialTable, { Column } from 'material-table';
import {Link} from 'react-router-dom'
import { Paper } from '@material-ui/core';
import ForwardPNG from '../Assets/fwd.png';
export default function AdminsTable(props) {
    return (
        <div>
             <MaterialTable
              components={{
                Container: props => <Paper {...props} elevation={0}/>
           }}
                 icons={icons}
                  options={{
                    exportButton: true
                  }}
                  columns={[
                    { title: 'Name', field: 'name' },
                    { title: 'Email', field: 'email' },
                    { title: 'Roles', field: 'roles' },
                    { title: 'Status', field: 'status' },
                    { title: 'Action', field: 'action' },
                  
                  ]}

            data={props.admins?props.admins.map((data)=>{
                return(
                {
                    name: <Link to={`/create-admin/${data.id}`} className="table-link">{`${data.firstName} ${data.lastName}`}</Link>,
                    email:<Link to={`/create-admin/${data.id}`} className="table-link">{`${data.email}`}</Link>,
                    roles:<Link to={`/create-admin/${data.id}`} className="table-link">{`${data.role ? data.role.permissions.join(", ") : ''}`}</Link>,
                    status:<>
                        <button className={data.activeAsAdmin === true ? `restore-action-button` : `inactive-status-button`} 
                          data-target="#activateAndDeactivateModal" data-toggle="modal" onClick={()=>props.setAdmin(data)} type="button"
                        >{data.activeAsAdmin === true ? 'Active' : 'Inactive'}</button> 
                        

                        <button className={`inactive-status-button ml-2`} type="button" data-toggle="modal" onClick={()=>props.setAdmin(data)} data-target="#BlockModal">{'Delete'}</button> 
                    </>,
                    action:<Link to={`/create-admin/${data.id}`} className="table-link">
                        <div>
                            <img src={`${ForwardPNG}`} className="icon-image-container" />
                        </div>
                    </Link>
                })
            }):[]} 
        
          title="All Admins"
          

        />
        </div>
    )
}
