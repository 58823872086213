import React,{useState,useEffect} from 'react'
import {Layout} from '../../Components/Layout/layout'
import "react-datepicker/dist/react-datepicker.css";
import {httpGet,httpPost, httpPut} from '../../Components/helpers/httpMethods'
import {hideLoader, showLoader} from '../../Components/helpers/loader'
import {NotificationManager} from 'react-notifications';
import { useParams } from 'react-router-dom';
export default function CouponType(props) {
    const [couponType, setCouponType] = useState({
        couponType: "",
        description: ""
    });

    const {coupon_type_id} = useParams();
    
    const handleSubmit=async(e)=>{
           e.preventDefault();
           try {
                showLoader()
                if(coupon_type_id){
                    let res = await httpPut(`/coupon_type/${coupon_type_id}`,couponType);
                    hideLoader();
                    return NotificationManager.success("Coupon type has been updated")
                }
                await httpPost("coupon_type/create",couponType)
                hideLoader()
                setCouponType({
                    couponType: "",
                    description: ""
                });
                NotificationManager.success(
                    "CouponType created successfully",
                    "Yepp",
                    4000
                );
                hideLoader()
        } catch (error) {
            console.log('>>>',error);
            error.data ? NotificationManager.error(
                error.data.message,
               "Opps",
               3000
           ) : NotificationManager.error(
            "Network Error! Please retry",
           "Opps",
           3000
       );;
            hideLoader()
        }
    }
       
    const getCouponType = async () => {
        try{
            showLoader();
            let res = await httpGet(`coupon_type/${coupon_type_id}`);
            console.log('Res>>',res);
            setCouponType(res.couponTypeExist);
            hideLoader();
        }catch(error){
            hideLoader();
            error.data ? NotificationManager.error(error.data.message) : NotificationManager.error("Opps! Please check your internet");
        }
    }
    const  handleChange=(e)=>{
            setCouponType({...couponType, [e.target.name]: e.target.value })
            console.log(couponType)
    }
    
    useEffect(()=>{
        if(coupon_type_id){
            getCouponType();
        }
    },[

    ]);
    return (
        <form onSubmit={handleSubmit}>
            <Layout pageName={coupon_type_id ? `Edit` : 'Create'} subPageName="Coupon type"> 
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="">Coupon type title<span>*</span></label>
                            <div className="inputbox-cupon">
                                <input value={couponType.couponType} name="couponType" onChange={handleChange} required type="text" className="form-control"/>
                            </div>
                        </div>
                    </div>
                   <div className="col-md-6">
                       <div className="form-group">
                            <label htmlFor="">Descriptions<span>*</span></label>
                            <div className="inputbox-cupon">
                                <textarea value={couponType.description}  name="description" onChange={handleChange} required type="text" className="form-control" rows='5' cols='6' />
                                <span className="text-primary">500 <span>Characters left.</span></span>
                            </div>
                       </div>
                   </div>
                   <div className="col-md-12 pr-5">
                        <button className="btn btn-primary mr-3 pull-right">Submit</button>
                    </div>

                </div>
        
            </Layout>
        </form>
        
    )
}


