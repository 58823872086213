import React, { useState } from 'react'
import {Link} from 'react-router-dom';
import './home.css';
import apple_store from '../../Components/Assets/apple_store.png';
import google_store from '../../Components/Assets/google_store.png';
import about_png from '../../Components/Assets/about.jpeg'
import connect_png from '../../Components/Assets/connect.png';
import save_png from '../../Components/Assets/save.png';
import shop_png from '../../Components/Assets/shop.png';
import cashback_png from '../../Components/Assets/cashback.png';
import dining_png from '../../Components/Assets/foods.png';
import travels_png from '../../Components/Assets/plane.png';
import clothing_png from '../../Components/Assets/clothing.png';
import electronics_png from '../../Components/Assets/electronics.png';
import health_png from '../../Components/Assets/health.png';
import makeup_png from '../../Components/Assets/makeup.png';
import device_png from '../../Components/Assets/device.png';
import scanner_png from '../../Components/Assets/scanner.png';
import Merchant1_img from '../../Components/Assets/1.png';
import Merchant2_img from '../../Components/Assets/2.png';
import Merchant3_img from '../../Components/Assets/3.jpeg';
import Merchant4_img from '../../Components/Assets/4.jpeg';
import Merchant5_img from '../../Components/Assets/5.png';
import Merchant6_img from '../../Components/Assets/6.jpeg';
import Merchant7_img from '../../Components/Assets/7.jpeg';
import Merchant8_img from '../../Components/Assets/8.jpeg';
import Merchant9_img from '../../Components/Assets/9.png';
import Merchant10_img from '../../Components/Assets/10.jpeg';
import Merchant11_img from '../../Components/Assets/11.png';
import Merchant13_img from '../../Components/Assets/13.jpeg';
import Merchant14_img from '../../Components/Assets/14.jpeg';
import Merchant16_img from '../../Components/Assets/16.jpeg';
//import OwlCarousel from 'react-owl-carousel2';
//import 'react-owl-carousel/style.css'; //Allows for server-side rendering.
//import 'react-owl-carousel/dist/assets/owl.carousel.css';  
//import 'react-owl-carousel/dist/assets/owl.theme.default.css'; 
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css'; 
import logo_png from '../../Components/Assets/logo.png';
import {Footer} from '../../Components/Layout/footer';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import { showLoader } from '../../Components/helpers/loader';
//import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
//import { Carousel } from 'react-responsive-carousel';
import axios from 'axios';

export const Home = () =>{
    const [email,setEmail] = useState('');
    const [loading,setLoading] = useState(false);
    const options = {
        items: 1,
        nav: true,
        rewind: true,
        autoplay: true
    };
    
    const events = {
        onDragged: function(event) {},
        onChanged: function(event) {}
    };

    const handleSubmit = async () =>{
        try{
            if(loading) return;
            let failed = true;
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)){
                failed = false;
            }
            if(email.trim() === "" || failed){
                return NotificationManager.error(
                    "Please provided a valid email address",
                    "Opps!",
                    3000
                );
            }
            let url = 'https://api.purplebux.com/api/subscribe';
            setLoading(true);
            let res = await axios.post(url,{
                email : email
            });
            setLoading(false);
            setEmail("");
            return NotificationManager.success(
                "Thank you for subscribing.",
                "Yippe!",
                3000
            );
        }catch(err){
            console.log("err",err.response);
            let msg = err && err.response && err.response.data &&
            err.response.data.response && err.response.data.response.error ? 
            err.response.data.response.error : "Please be sure to provide a valid email and retry.";
            setLoading(false);
            return NotificationManager.error(
                msg,
                "Opps!",
                3000
            );
        }
    }
    return(
        <>
            <section className="p-5 header-section">
                <div className="container header-container">
                    <Link to="/" className="flex">
                        <div className="logo-container mr-3">
                            <img src={logo_png}/>
                        </div>
                        <h4 className="flex-5 text-bold">TOKENFY</h4>
                    </Link>
                    {/* <Link className="btn btn-dark-round flex-1">Get early access</Link> */}
                </div>
                <div className="container header-container mt-5">
                    <div className="flex-3 catchy-phrase pt-5">
                        <h1>Save Up. Save More.</h1>
                        <div className="about-tokenfy-container">
                            <p>
                            Tokenfy gets you coupons at thousands of places when you shop, dine, 
                            or book hotels. You can also scan coupons at stores. Simply download the app and begin to get amazing deals and save more. 
                            </p>
                            <div className="mt-5 flex mb-5">
                                <a href="https://play.google.com/store/apps/details?id=com.ttl.tokenfy&hl=en_US&gl=US" className="flex-1 mr-4"
                                 target="_blank"
                                >
                                    <img src={google_store}/>
                                </a>
                                <a href={"https://apps.apple.com/us/app/tokenfy/id1592283280"} className="flex-1" target="_blank">
                                    <img src={apple_store}/>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="flex-2">
                        <div className="about-tokenfy-image-container">
                            <img src={about_png}/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="grey-section p-5">
                <div className="container">
                    <div className="card mt-5 mb-5">
                        <h6 className="text-center text-muted small-title">HOW IT WORKS</h6>
                        <h3 className="text-primary text-center mt-3 mb-5">Tokenfy is everything coupon.</h3>
                        <div className="flex">
                            {
                                [
                                    {
                                        body : 'Download the tokenfy app and explore coupons which  you will definitely love.',
                                        title : 'Connect',
                                        img : connect_png
                                    },
                                    {
                                        body : 'Shop on your favorite sites,and use your saved coupon codes from the tokenfy app.',
                                        title : 'Shop',
                                        img : shop_png
                                    },
                                    {
                                        body : 'Save a bit more by using coupons from tokenfy. You don’t always have to pay the full price.',
                                        title : 'Save',
                                        img : save_png
                                    }
                                ].map((item,index)=>(
                                    <div className="flex-1 text-center" key={index}>
                                        <div className="image-container text-center">
                                            <img src={item.img}/>
                                        </div>
                                        <h6 className="text-bold mt-3 mb-3">{item.title}</h6>
                                        <div className="how-body">
                                            <h6 className="text-muted">{item.body}</h6>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="mb-5">
                        <h6 className="text-center text-muted small-title">WHY IT IS GREAT</h6>
                        <h5 className="text-center">Coupons in every category</h5>
                        <h1 className="text-center">We’ve got something for everyone</h1>
                        <div className="flex p-5">
                            {
                                [
                                    {
                                        name : "Cashback Offers",
                                        icon : cashback_png
                                    },
                                    {
                                        name : "Dinning & Takeout",
                                        icon : dining_png
                                    },
                                    {
                                        name : "Travels",
                                        icon : travels_png
                                    },
                                    {
                                        name : "Clothing",
                                        icon : clothing_png
                                    },
                                    {
                                        name : "Electronics",
                                        icon : electronics_png
                                    },
                                    {
                                        name : `Health ${'\n'}
                                        & Finance`,
                                        icon : health_png
                                    },
                                    {
                                        name : "Makeup & Skin Care",
                                        icon : makeup_png
                                    },
                                ].map((item,index)=>(
                                    <div className="flex-1 mr-3">
                                        <div className="img-container">
                                            <img src={item.icon} />
                                        </div>
                                        <p className="mt-3 text-center">{item.name}</p>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </section>
            <section className="light-blue-section p-5">
                <div className="container">
                    <div className="flex">
                        <div className="flex-1 about-tokenfy-container">
                            <h1 className="mt-5">Exclusive Deals</h1>
                            <p className="mt-3">
                                Still searching for promo codes? We've got the best codes you will find and you can just claim it. It's that easy.
                            </p>
                            <a className="btn btn-orange" href="#">Get notified</a>
                        </div>
                        <div className="flex-1 mb-5">
                            <div className="device-img-container">
                                <img src={device_png}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="dark-blue-section p-5">
                <div className="container">
                    <div className="flex">
                        <div className="flex-1 about-tokenfy-container">
                            <div className="icon-image-container">
                                <img src={logo_png}/>
                            </div>
                            <h1 className="mt-5">30% discount coupon code for you only on Tokenfy.</h1>
                            <p className="mt-3">
                                Get a 30% coupon code to use on your favorite site when you download now. Limited time Offer. 
                            </p>
                            <div className="mt-5 flex mb-5">
                                <a href="https://play.google.com/store/apps/details?id=com.ttl.tokenfy&hl=en_US&gl=US" className="flex-1 mr-4"
                                    target="_blank"
                                >
                                    <img src={google_store}/>
                                </a>
                                <a href={"https://apps.apple.com/us/app/tokenfy/id1592283280"} className="flex-1" target="_blank">
                                    <img src={apple_store}/>
                                </a>
                            </div>
                        </div>
                        <div className="flex-1 mb-5">
                            <div className="device-img-container">
                                <img src={device_png}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="p-5">
                <div className="container">
                    <div className="flex">
                        <div className="flex-1">
                            <div className="device-img-container">
                                <img src={scanner_png}/>
                            </div>
                        </div>
                        <div className="flex-1 about-tokenfy-container">
                            <h2 className="mt-5">Scan in-store</h2>
                            <p className="mt-3">
                                Scan coupon codes at stores to get deals which are also exclusive to us.
                            </p>
                            <a className="btn btn-orange" href="#">Get the app</a>
                        </div>
                    </div>
                    <div className="mt-5">
                        <h6 className="text-center text-muted small-title">PARTNERS</h6>
                        <h2 className="text-center">Brands show you love when you shop</h2>
                    </div>
                    <div className="merchange-logo-container mb-5">
                        <div className="flex">
                        <OwlCarousel className='owl-theme' 
                            loop={true} margin={10} 
                            autoplay={true}>
                                {
                                    [
                                        Merchant1_img,
                                        Merchant2_img,
                                        Merchant3_img,
                                        Merchant4_img,
                                        Merchant5_img,
                                        Merchant6_img,
                                        Merchant7_img,
                                        Merchant8_img,
                                        Merchant9_img,
                                        Merchant10_img,
                                        Merchant11_img,
                                        Merchant13_img,
                                        Merchant14_img,
                                        Merchant16_img,
                                    ].map(url=>(
                                        <div>
                                        <a className="flex-1 mr-5">
                                            <img src={url}/>
                                        </a>   
                                        </div>    
                                    ))
                                }
                           </OwlCarousel>
                        </div>
                    </div>
                </div>
            </section>
            <section className="grey-section p-5">
                <div className="container form-container">
                    <h1 className="text-center text-bold mb-5">Ready to start having coupons?</h1>
                    <p className="text-center mb-5">Your favorite brands choose to give you coupon offers via Tokenfy, instead of spending it on 
                        disruptive advertising. How cool is that? With Tokenfy, it all adds up. 
                    </p>
                    <div className="flex">
                        <div className="flex-3 mr-2">
                            <input className="form-control" placeholder="Enter your email and get notified"
                                onChange={(e)=>setEmail(e.target.value)}
                                value={email}
                            />
                        </div>
                        <div className="flex-1">
                            <button className="btn btn-primary"
                                onClick={()=>handleSubmit()}
                            >{
                                loading ? "Please Wait.." : "Get notified"
                            }</button>
                        </div>
                    </div>
                    
                    <div className="store-logo-container">
                        <div className="flex">
                        <a href="https://play.google.com/store/apps/details?id=com.ttl.tokenfy&hl=en_US&gl=US" className="flex-1 m-3"
                                    target="_blank"
                                >
                                <img src={google_store}/>
                            </a>
                            <a href={"https://apps.apple.com/us/app/tokenfy/id1592283280"}  className="flex-1 m-3" target="_blank">
                                <img src={apple_store}/>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
           <Footer />
        </>
    )
}