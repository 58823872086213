import React,{useState,useEffect} from 'react'
import {Layout} from '../../Components/Layout/layout'
import './reviews.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReviewData from '../../Components/Tables/Review'

import {httpGet} from '../../Components/helpers/httpMethods';
import { NotificationManager } from 'react-notifications';
import { showLoader, hideLoader } from '../../Components/helpers/loader';

export default function ReviewTable(props) {

    const[activePage,setActivePage] = useState("all");
    const[startDate,setStartDate] = useState(new Date());
    const getReviews = async () =>{
		try{
			showLoader();
            let res = await httpGet('/review?limit=500');
            console.log('res>>',res);
            setReviews(res.data);
            setHolder(res.data);
			hideLoader();
		}catch(error){
			hideLoader();
			NotificationManager.error(error.data.message);
		}
    }
    const  getCouponsType = async()=>{
        try {
            showLoader()
            const res = await httpGet(`admin/coupon/types/count`);
            setCouponsType(res);
            hideLoader()
        } catch (error) {
          hideLoader();
          NotificationManager.error(error.data.message)
        }
    }
    const ratings  = ['Not Recommended','Fairly Recommended','Recommended','Recommended','Highly Recommended'];
    const [holder, setHolder] = useState([]);
    const [coupons, setCoupons] = useState([])
    const [couponsType, setCouponsType] = useState([]);
    const [reviews,setReviews] = useState([]);
    
	useEffect(()=>{
        getReviews();
        getCouponsType();
	},[]);
    return (
        <div>
            <Layout pageName="Reviews">
                <div style={{marginBottom:"20px"}}  style={{marginBottom:"20px"}} className="page-roter-list">
                        
                        <div>
                        {
                                couponsType.map((data,index)=>{
                                  return(
                                    <div className="hsd" key={index}>
                                        <span
                                        style={{width:"fit-content"}}
                                        onClick={(e)=>{
                                            setActivePage(index === 0 ? 'all' : data);
                                            setReviews(holder.filter((review)=>review.Coupon.couponTypeId === data.id));
                                        }}
                                        className={`page-router-name 
                                        ${activePage === data || (index === 0 && activePage === 'all') ? "page-router-name-active ":""}`}>
                                            {data.type}</span>
                                            <div className="c-count-copon">{data.count}</div>
                                    </div>
                                  )
                                })
                            }
                        </div>
                     
                    </div>
                <ReviewData viewReview={props.history.push} ratings={ratings} reviews={activePage !== 'all' ? reviews : holder}/>
            </Layout>
        </div>
    )
}


