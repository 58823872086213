import React,{useState,useEffect} from 'react'
import {Layout} from '../../Components/Layout/layout';
import './dashboard.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SearchImg from '../../Components/Assets/search.png'
import {Link} from 'react-router-dom'
import {httpGet, httpPatch, httpPostFormData,httpPost, httpDelete} from '../../Components/helpers/httpMethods'
import {hideLoader, showLoader} from '../../Components/helpers/loader'
import {NotificationManager} from 'react-notifications'
import UsersTable from '../../Components/Tables/UsersTable'
import axios from 'axios';
import SubscribersTable from '../../Components/Tables/Subscribers';

export default function Subscribers(props) {
    useEffect(() => {
        GetSubscribers();
    }, [])

    const [subscribers, setSubscribers] = useState([])

    const [activePage, setActivePage] = useState("Coupon_Properties")
    const [startDate, setstartDate] = useState(new Date())

    const  GetSubscribers =async()=>{
        try {
          showLoader()
            const res = await axios.get('https://api.purplebux.com/api/get_subscribers');
            let subs = res.data && res.data.response && res.data.response.data ? 
            res.data.response.data : [];
            console.log("GetUsers",res)
            setSubscribers(res.data.response.data);
            hideLoader()
        } catch (error) {
          hideLoader()
        }
    }
    
    
    return (
        <div>
            <Layout pageName="Overview" subPageName="Subscribers">
                <div className="Table-sec">
                    {console.log("<<>>",subscribers)}
                    <SubscribersTable subscribers={subscribers} />
                </div>
            </Layout>
        </div>
    )
}


