import React,{useState,useEffect,useRef} from 'react'
import {Layout} from '../../Components/Layout/layout'
import './admin-tasks.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Link, useParams} from 'react-router-dom';
import { httpGet, httpPost, httpPostFormData, httpPatchFormdata } from '../../Components/helpers/httpMethods';
import { NotificationManager } from 'react-notifications';
import { hideLoader, showLoader } from '../../Components/helpers/loader';
import GetImageUrl from '../../Components/helpers/getImageUrl';
import Axios from 'axios';

const CreateAdmin = () => {
    const {admin_id} = useParams();
    const hiddenFileInput = useRef(null);
    const [roles,setRoles] = useState([]);
    const [selectedRoles,setSelectedRoles] = useState([]);
    const [placeholder,setPlaceholder] = useState("");
    const [picker,setPicker] = useState(false);
    const [admin,setAdmin] = useState({
        defaultPassword : "",
        firstName : "",
        lastName : "",
        email : "",
        phone : "",
        avatar : []
    });
    const getData = async () => {
        try{
            showLoader();
            setPlaceholder("");
            if(admin_id){
                let admin = await httpGet(`admin/retrieve/admin/${admin_id}`);
                setAdmin({...admin,defaultPassword:""});
                setSelectedRoles(admin.role ? admin.role.permissions : []);
                setPlaceholder(admin.avatar);
                console.log('Admin',admin);
            }
            let res = await httpGet('/admin/all_roles');
            setRoles(res);
            hideLoader();
        }catch(error){
            hideLoader();
            NotificationManager.error(error.data.message);
        };
    }
    const onChangeHandler = (event) => {
        if(event.target.name === "roles"){
            let roles = [...selectedRoles,event.target.value.toString()]
            setSelectedRoles([...new Set(roles)]);
        }
        if(event.target.name === "avatar"){
            if(event.target.files[0].length === 0){
                return;
            }
            console.log('file>>',event.target.files[0]);
            setPlaceholder(GetImageUrl(event.target.files[0]));
            setAdmin({...admin,avatar : event.target.files[0]});
            setPicker(true);
        }
        setAdmin({...admin,[event.target.name] : event.target.value})
    }

    const removeRole = (role) => {
        setSelectedRoles([...new Set(selectedRoles.filter(item=>item !== role))]);
    }

    const submitHandler = async (event) => {
        event.preventDefault();
        try{
            showLoader();
            let fd = new FormData();
            fd.append('firstName',admin.firstName);
            fd.append('lastName',admin.lastName);
            fd.append('email',admin.email);
            fd.append('phone',admin.phone);
            if(admin.defaultPassword !== '' && admin.defaultPassword.trim() !== ''){
                fd.append('defaultPassword',admin.defaultPassword);
            }
            if(picker){
                fd.append('avatar',admin.avatar);
            }
            fd.append('roles',JSON.stringify(selectedRoles));
            if(admin_id){
                let res = await httpPatchFormdata('/admin/edit/',fd);
                hideLoader();
                return NotificationManager.success("Admin record has been updated");
            }
            console.log('fd>>>',fd);
            let res = await httpPostFormData('admin/sub/create',fd);
            if(res.status === 200 || res.status === 201){    
                hideLoader();
                setAdmin({
                    defaultPassword : "",
                    firstName : "",
                    lastName : "",
                    email : "",
                    phone : "",
                    avatar : {}
                });
                //setRoles([]);
                setSelectedRoles([]);
                setPlaceholder("");
                NotificationManager.success('Admin created successfully');
            }
        }catch(error){
            hideLoader();
            //error.data ? NotificationManager.error(error.data.message) : NotificationManager.error("Opps! Please check your internet");
        }
    }
    
    useEffect(()=>{
        getData();
    },[]);
    return (
        <div>
            <Layout pageName={admin_id ? `Edit Admin` : `Create Admin`}>
                {
                    !admin_id && (
                        <h3 className="bold-550">Add new admin</h3>
                    )
                }
                <form method="POST" onSubmit={submitHandler}>
                    <section className="pr-3 mt-5rem">
                        <div className="row">
                            <div class="col-md-3">
                                <div className="form-group">
                                    <label className="bold-550">First Name</label>
                                    <input name="firstName" value={admin.firstName} type="text" 
                                        className="form-control"
                                        onChange={onChangeHandler}
                                        required
                                    />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div className="form-group">
                                    <label className="bold-550">Last Name</label>
                                    <input name="lastName" value={admin.lastName} type="text" 
                                        className="form-control" 
                                        onChange={onChangeHandler}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label className="bold-550">Phone number <sup className="text-danger">*</sup></label>
                                    <input name="phone" value={admin.phone} type="tel" className="form-control" 
                                        required onChange={onChangeHandler}
                                    />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label className="bold-550">Email <sup className="text-danger">*</sup></label>
                                <input type="email" name="email" value={admin.email} className="form-control" 
                                        onChange={onChangeHandler} required
                                    />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label className="bold-550">
                                        Default Password {
                                            !admin_id && (
                                                <sup className="text-danger">*</sup>
                                            )
                                        }
                                    </label>
                                <input type="password" name="defaultPassword" value={admin.defaultPassword} 
                                        className="form-control" {...admin_id ? `required` : ""}
                                        onChange={onChangeHandler}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="pr-3">
                        <div className="row">
                            <div class="col-md-4">
                                <label className="bold-550">Attachment</label>
                                <div className="card">
                                    {
                                        placeholder.length > 0 && (
                                            <div>
                                                <span className="fa fa-remove pull-right mb-3 text-danger remove-item" onClick={()=>{
                                                    setPlaceholder("");
                                                    setPicker(false);
                                                }}></span>
                                                <img src={placeholder} />
                                            </div>
                                        )
                                    }
                                    <div className="upload-file-container">
                                        <p className="bold-550 text-center">Drag and drop a file here</p>
                                        <p>OR</p>
                                        <input type="file" ref={hiddenFileInput} name="avatar" style={{visibility:'hidden'}} onChange={onChangeHandler}/>
                                        <button className="btn btn-primary" type="button" onClick={()=>hiddenFileInput.current.click()}>
                                            Upload
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group mt-3">
                                    <label className="bold-550">Roles <i className="fa fa-exclamation-triangle text-warning"></i></label>
                                    <select name="roles" onChange={onChangeHandler} className="form-control">
                                        <option value="">Select roles</option>
                                        {
                                            roles.map((role,index)=>(
                                                <option index={index} value={role}>{role}</option>
                                            ))
                                        }
                                    </select>
                                    <span className="fa fa-angle-down select-arrow roles-arrow"></span>
                                </div>
                                <div className="roles-list mt-20">
                                    {
                                        selectedRoles.map((role,index)=>(
                                            <p className="mb-2" key={index}>
                                                <i className="fa fa-times text-danger" onClick={()=>removeRole(role)}></i>  
                                                <span className="text-primary ml-2">{role}</span>
                                            </p>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                    <button className="btn btn-success mb-5">{admin_id ? 'Edit' : 'Create'}</button>
                </form>
            </Layout>
        </div>
    )
}
export default CreateAdmin;


