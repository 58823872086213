import React, { useState } from 'react'
import {Link} from 'react-router-dom';
import './home.css';
import logo_png from '../../Components/Assets/logo.png';
import {Footer} from '../../Components/Layout/footer';


export const Terms = () =>{
    return(
        <>
            <section className="p-5 header-section">
                <div className="container header-container">
                    <Link to="/" className="flex">
                        <div className="logo-container mr-3">
                            <img src={logo_png}/>
                        </div>
                        <h4 className="flex-5 text-bold">TOKENFY</h4>
                    </Link>
                </div>
            </section>
            <section className="p-5">
                <div className="container">
                    <h1 className="text-center mb-5">Terms and Conditions</h1>
                    <div className="flex">
                        <div className="flex-1" id="terms-and-conditions">
                            {
                                [
                                    `
                                    Terms of Use
                                    The following conditions apply to the use of the Tokenfy service.
                                    Effective January 20, 2021
                                    `,

"Note: The Tokenfy Terms of Use have been updated and are now governed by the terms of its parent company, Tokenfy Technologies Limited. Please review the updated Terms of Use carefully before using our Services. By using any of the Services, you consent to the terms of the updated Terms of Use.",

"PLEASE READ THESE TERMS OF USE (“TERMS”) CAREFULLY BECAUSE THEY DESCRIBE YOUR RIGHTS AND RESPONSIBILITIES AND, TOGETHER WITH ALL THE OTHER TERMS AND CONDITIONS REFERRED TO BELOW, FORM A LEGALLY BINDING AGREEMENT BETWEEN YOU AND US REGARDING YOUR USE OF OUR WEBSITE(S) AND OTHER SERVICES.",

"THESE TERMS CONTAIN A BINDING ARBITRATION CLAUSE AND A CLASS ACTION WAIVER.",

"IF FOR ANY REASON, YOU ARE UNABLE OR UNWILLING TO AGREE TO ALL OUR TERMS OF USE, PLEASE IMMEDIATELY STOP USING OR ATTEMPTING TO USE OUR WEBSITE AND OTHER SERVICES, BECAUSE IF YOU CONTINUE, YOU WILL BE AGREEING TO EVERYTHING IN OUR TERMS OF USE.",

`•	General
These Terms govern your use of the interactive websites, mobile and connected applications, software, and other online interactive features and services, including, but not limited to, emails, newsletters, forums, communities, and contests (collectively, the “Services”) operated by Tokenfy Technologies Limited, and its subsidiaries (collectively “Tokenfy Technologies Limited” “we” “us” or “our”). Additional terms and conditions of use applicable to specific areas of the Services may also be posted in such areas and, together with these Terms, govern your use of those areas and are referred to collectively as the “Agreement.”
These Terms cover the following Tokenfy websites and mobile applications:`,

`We reserve the right, in our discretion, to change or modify all or any part of the Agreement at any time, effective immediately upon notice published on this page. Your use of the Services constitutes your binding acceptance of these terms and conditions, including any changes or modifications made by Tokenfy Technologies Limited as permitted above. Please be sure to review the Agreement periodically to ensure familiarity with the most current version. If at any time the terms and conditions of the Agreement are no longer acceptable to you, you should immediately cease all use of the Services. It is your responsibility to refer to the Agreement upon accessing the Services.
COPPA: The Services are directed to teenagers and adults and are not directed to children under the age of 13. You must be 13 years of age or older to use the Services outside of the European Union and European Economic Area, and 16 years of age or older in the European Union and European Economic Area. Tokenfy Technologies Limited complies with the Children’s Online Privacy Protection Act and does not permit registration by, and will not knowingly collect personally identifiable information from, anyone under 13. By registering for any Services, you represent and warrant that you are 13 years of age or older or, if you reside in the European Union or European Economic Area, 16 years of age or older. Additionally, you must provide true, accurate and complete registration information to become a member of the Services (“Member”). Creating a Member account if you are under the age of 13 outside of the European Union and European Economic Area or 16 in the European Union and European Economic Area, under automated means or under false or fraudulent pretenses constitutes unauthorized use of the Services, and such accounts will be deleted by Tokenfy Technologies Limited.
As part of the registration process, you may be asked to select a username and password and you will be responsible for all activities occurring under your username and for keeping your password secure. We may refuse to grant you a username that impersonates someone else, violates or infringes on the trademark or proprietary rights of a third party, or is vulgar, offensive, or otherwise inappropriate, as determined by us in our sole discretion. You agree that your username and password is personal to you and should not be used to provide access to the Services to any other person or entity.
By using the Services, you agree to: (a) provide accurate and complete information about yourself as prompted by the registration form for a Service (“Registration Data”), (b) maintain and promptly update the Registration Data to keep it accurate and complete, and (c) maintain the security of your password. In the event you compose a profile upon registering for the Services (a “Member Profile”), such profile must describe you, an individual person. Examples of inappropriate profiles include, but are not limited to, profiles that purport to represent an animal, place, inanimate object, fictional character, or real individual who is not you. If you wish to view or change your profile, you can do so by clicking here to opt out.  
For Shopping Services: Certain of Tokenfy Technologies Limited sites allow you to purchase products (“Products”). Such Products and pricing on the sites may change at any time in the sole discretion of Tokenfy Technologies Limited, without prior notice to you. You agree that you will only purchase Products on the sites for your own use and enjoyment or as a gift for another person, that all information you submit through the sites is complete, accurate, current and true and that you are not barred from receiving Products under applicable law. Some Products you purchase may be used or contain used parts. Certain software or other materials that you purchase through our sites may be subjected to further export controls. You agree to comply with all applicable export and re-export restrictions, laws and regulations and will not encourage, assist, or authorize the transfer of such products to a prohibited country in violation of law, rule or regulation. Please be advised that our order confirmation to you does not signify our acceptance of your order, nor does it constitute confirmation of our offer to sell. At any time after receipt, we may accept, decline, or place quantity restrictions or other limits on your order for any reason in our discretion. If we charge you for an order that has been canceled, we’ll refund you the full amount of the canceled portion of such order. While we strive to make all information on our sites as accurate as possible, we do not warrant that Product descriptions or other content is accurate, complete or error free. Promotions are subject to change and we cannot confirm the availability or price of an item until you place your order. For more information please see our return policy. Please also be advised we do not operate a call center. All inquiries should be addressed to contactshop@tokenfy.io
`,
`For Tokenfy Cash Back Services:  
General. By participating in a Tokenfy Online Cash Back Rewards Offer and/or In-Store Cash Back Rewards Offer (each, a "Promotion"), you agree to be bound by these Cash Back Rewards and Wallet Terms (these "Promotion Terms") in addition to the rest of the Tokenfy Technologies Limited Terms of Use and Privacy Policy. In the event of a conﬂict between these Promotion Terms and the Tokenfy Technologies Limited Terms of Use, these Promotion Terms will control. All features may not be available and Tokenfy reserves the right in its sole discretion to end any Promotion or other program. 
`,
`•	Definitions.
o	"Rewards Oﬀer" means a Rewards Oﬀer for a Promotion that has been added to a user’s account based on offer that has been by completed. In-Store Cash Back QR Code Scanning Rewards Oﬀers may activate when you scan a QR Code after a Qualifying Transaction during the Promotion Period. 
o	"Bonus Reward" means a bonus Reward oﬀered to certain Tokenfy members who complete a qualifying activity or who meet certain criteria, as deﬁned in a Rewards Oﬀer Card or Promotion Landing Page, in addition to or in combination with oﬀers for Online Cash Back Rewards, or In-Store Cash Back Rewards.
o	"In-Store Cash Back Rewards" means Rewards that Tokenfy provides after receiving a Scanned QR Code from Qualiﬁed Transaction and may sometimes be referred to as "In-Store Cash Back" or "In-Store Cash Back Rewards" on the Tokenfy website or app, or on the Promotion Landing Page.
o	"Merchant" means the merchant that is speciﬁed on the Promotion Landing Page.
o	"Minimum Purchase Amount" means the amount speciﬁed on the Promotion Landing Page as the minimum purchase amount from the Merchant that is required to be eligible for the applicable Rewards Oﬀer. The Minimum Purchase Amount excludes discounts/promos, store points/rewards programs, tax, tip, fees, and any amount used to purchase Excluded Items.
o	"Number of Rewards Oﬀers per Person" means (a) for Online Cash Back Rewards, a limit of twenty (20) Rewards Oﬀers in the aggregate under all Tokenfy Promotions in any calendar month and a limit of seven (7) Rewards per Merchant in any calendar month and (b) for In-Store Cash Back Rewards, a limit of ﬁfteen (15) Rewards Oﬀers in the aggregate under all Tokenfy Promotions in any calendar month, unless otherwise speciﬁed in the Promotion Landing Page.
o	"Online Cash Back Rewards" means Rewards that Tokenfy tracks through your Internet browser and may sometimes be referred to as "Online Cash Back" on the Tokenfy website or app, or on the Promotion Landing Page.
o	"Promotion Landing Page" means the website or app page that describes the Promotion and links to these Promotion Terms.
o	"Promotion Link" means the hyperlink provided on the Promotion Landing Page of an Online Cash Back Rewards Oﬀer that directs you to the Merchant’s website.
o	"Promotion Period" means the period beginning on the start date speciﬁed on the Promotion Landing Page and ending at the earlier of (1) the time that all of the Total Rewards Oﬀers available during Promotion have been claimed or (2) the end date speciﬁed on the Promotion Landing Page. The Promotion Period may be shortened or extended at Tokenfy’s discretion. All start and end dates are based on Central Standard Time unless otherwise indicated.
o	"Qualifying Transaction" means a purchase that is (a) in an amount greater than the Minimum Purchase Amount; (b) from a participating Merchant; (c) in connection with a Rewards Oﬀer; (d) made via an Online Cash Back Rewards Offer or submitted through a Receipt; and (e) in Tokenfy’s sole discretion, satisﬁes all of the Promotion Terms.
o	"Receipt" means a proof of purchase from an in-store Qualifying Transaction with a Merchant during the Promotion Period. To be eligible for an In-Store Cash Back Reward, the Receipt must clearly display the date; Merchant name; receipt number or other identiﬁer speciﬁc to that Merchant, that Qualifying Transaction and on that applicate date; the item(s) purchased; the item price; and total amount spent on the item(s). The Receipt must clearly display all required information and meet any other speciﬁed terms to be eligible for an In-Store Cash Back Reward, including terms displayed on the Promotion Landing Page or Rewards Oﬀer Card. All decisions regarding whether to issue a Reward and/or accept a Receipt are in Tokenfy’s sole discretion.
o	"Redemption Process" means the steps you must take, as speciﬁed by Tokenfy in its sole discretion, in order to redeem Approved Rewards. The Redemption Process is further described below.
o	"Reward" means a reward given by Tokenfy to you for fulﬁlling the requirements of the Promotion, as speciﬁed on a Promotion Landing Page and Rewards Oﬀer Card.
o	"Rewards Oﬀer" means an oﬀer for a Reward.
o	"Rewards Oﬀer Card" means the area of a website page or app that describes a Rewards Oﬀer and may include the Link Cash Back Button, a details section, and a link to a Promotion Landing Page.
o	"Rewards Wallet" means the user interface associated with your Tokenfy account that will show Activated Rewards, Approved Rewards, and related information.
o	"Third-party Service Providers" is deﬁned in Section 7 below.
o	"Total Rewards available during Promotion" or "Total Rewards Oﬀer" means the limited quantity of Rewards available under the Promotion, as speciﬁed on the Promotion Landing Page if the quantity is limited.
`,
`•	Eligibility.  Promotions are available to individual users of Tokenfy who are residents of Nigeria and at least eighteen (18) years of age. Participation in any Promotion and the opportunity to earn Rewards from any Promotion are oﬀered at the sole discretion of Tokenfy. Purchases for resale or commercial use are excluded from eligibility. In any calendar month, you are limited to the Number of Rewards Oﬀers per Person and the amount that you may earn, in the aggregate under all Tokenfy Rewards Oﬀers, may be limited (e.g., twenty thousand naira (N20,000 NG) per month).`,
`•	How to Earn Cash Back Rewards.
o	Online Cash Back Rewards. To earn Rewards pursuant to a Rewards Oﬀer for Online Cash Back Rewards, you must: (1) during the Promotion Period, click on the Promotion Link right before making an online purchase via the Merchant’s website; (2) complete the online purchase in an amount equal or greater to the Minimum Purchase Amount; and (3) satisfy any other conditions speciﬁed on the Promotion Landing Page. Upon veriﬁcation that your purchase qualiﬁes and that you have satisﬁed applicable requirements, the Reward speciﬁed for the applicable Rewards Oﬀer will be considered an Approved Reward. YOU MUST CLICK ON THE PROMOTION LINK FOR THE REWARDS OFFER DURING THE SAME SHOPPING SESSION THAT YOU COMPLETE YOUR PURCHASE FROM THE MERCHANT. IF YOU VISIT OTHER SITES BEFORE COMPLETING YOUR PURCHASE, OR IF YOU USE COUPON CODES NOT PROVIDED BY TOKENFY, YOUR PURCHASE MAY BE ASSOCIATED  WITH A SERVICE OTHER THAN TOKENFY AND YOU MAY NOT BE ELIGIBLE FOR THE REWARD(S). YOU MUST NOT ENABLE AN AD BLOCKER OR OTHERWISE DISABLE COOKIES ON YOUR COMPUTER, BECAUSE COOKIES ARE USED TO AUTHENTICATE YOUR PURCHASE.
o	In-Store Cash Back Rewards. 
	To earn Rewards pursuant to a Rewards Oﬀer for In-Store Cash Back Rewards by submitting a Receipt, you must: (1)  Scan the QR Code at the store, if applicable; (2) complete the required purchase via a Qualiﬁed Transaction; (3) submit a valid, readable Receipt through the Promotion Landing Page in accordance with the stated terms; if applicable and (4) satisfy any other conditions speciﬁed on the Promotion Landing Page or Rewards Oﬀer Card.
	If you complete the obligations in Sections 4(b)(i) and 4(b)(ii) and the Reward Offer is still available, the Reward specified for the applicable Reward Offer will be considered an Approved Award.
•	 Receipt Scanning.
	You agree to at all times provide accurate and complete information to Tokenfy. You agree not to: submit a Receipt that is false, inaccurate, fabricated, counterfeited, incomplete, tampered with, adjusted, or otherwise inauthentic for the purpose of seeking to claim a Reward; redeem, or attempt to redeem, a Reward for products that have not actually been purchased, or for products that have been returned; submit or attempt to submit a Receipt that has already been submitted or used by you or another user; or submit Receipts outside of a Promotion Period, or for a purchase that took place prior to or after the launch date of a Rewards Oﬀer. 
	Tokenfy may, in its sole discretion, extend the redemption period for a Rewards Oﬀer.
As between you and Tokenfy, the determination as to what transactions are Qualifying Transactions shall be made by Tokenfy in its sole discretion.
•	Bonus Rewards. From time to time, Tokenfy may oﬀer a Bonus Reward to certain Tokenfy members who complete a qualifying activity or who meet certain criteria, as deﬁned in a Rewards Oﬀer Card or Promotion Landing Page, in addition to or in combination with oﬀers for Online Cash Back Rewards or In-Store Cash Back Rewards. For example, Tokenfy may oﬀer a "My First Time" Bonus Reward to new members who earn an Online Cash Back Reward for the ﬁrst time or earn certain Online Cash Back Rewards for the ﬁrst time. In order to earn a Bonus Reward, you must satisfy all criteria set forth for earning the Bonus Reward as well as the requirements of these Promotion Terms during the promotion period for the Bonus Reward. Tokenfy, in its sole discretion, may adjust the promotion period and/or the bonus amount for Bonus Reward, at any time, without notice. All decisions by Tokenfy are ﬁnal and binding on you. Bonus Rewards are a limited time oﬀers and all criteria must be satisﬁed within the dates of the Promotion.
`,
`•	Returns, Approvals, and No Ownership.
o	For any Rewards Oﬀer, if you return or exchange the items purchased, complete your purchase before or after the Promotion Period, initiate a purchase that results in a chargeback, or fail to satisfy a Minimum Purchase Amount, or any other conditions speciﬁed on the Promotion Landing Page or Rewards Oﬀer Card, Tokenfy reserves the right to invalidate or withdraw all or part of the Rewards Oﬀer. The determination of whether or not a purchase qualiﬁes for the Rewards Oﬀer and whether applicable conditions have been satisﬁed is at the sole discretion of Tokenfy. In the event of a dispute, you may be required to provide your order conﬁrmation as proof of purchase. The timing of when Tokenfy will issue an Approved Reward will vary based on a number of factors. These factors may include the type of Promotion pursuant to which a Reward is oﬀered and your account activity as a member of Tokenfy. Tokenfy reserves the right, in its sole discretion, to determine the order and timing in which to process its conﬁrmation of eligible purchases. Rewards may be limited where participation exceeds the Total Rewards Oﬀers available during Promotion or in other cases, as determined by Tokenfy in its sole discretion. Tokenfy also reserves the right, in its sole discretion, to reevaluate Approved Rewards, at any time, without notice, including, but not limited to, whether purchases qualify for the Promotion and whether applicable conditions have been satisﬁed, and Tokenfy, in its sole discretion, may adjust or withdraw any Rewards Oﬀers or any Approved Rewards, at any time, without notice. All decisions by Tokenfy are ﬁnal and binding on you.
o	Participating in a Rewards Oﬀer does not give you ownership or any other legal claim to any Reward unless and until Tokenfy, in its sole discretion, determines that you have met all eligibility criteria and fulﬁlled all requirements for the Promotion and you have completed the Redemption Process. Rewards Oﬀers are provided solely by Tokenfy and are not underwritten or funded by any other third party. You may not make any claims for Rewards Oﬀers against Merchants or any other third party. Notwithstanding that Rewards Oﬀers may include oﬀers of cash, prior to redemption, Rewards Oﬀers and Approved Rewards have no monetary value and you may not obtain any money or any other thing of value for any Approved Reward or participation in any Rewards Oﬀer unless and until you complete the Redemption Process and a payment has been issued by Tokenfy for Approved Rewards.
`,
`•	Wallet and Redeeming Rewards.
o	Approved Rewards will be shown in the Tokenfy Rewards Wallet. You have no vested rights in Approved Rewards unless and until you complete the Redemption Process. If your Tokenfy account is terminated by Tokenfy, as provided in these Promotion Terms or the Terms of Use, or if you elect to close your Tokenfy account, you will no longer be permitted to access the Rewards Wallet or redeem Approved Rewards shown therein. You may redeem Approved Rewards for cash using your Bank.
	Redeeming for cash to Bank. In order to redeem Approved Rewards for cash via Bank, you must have a functional bank account you can transfer your cash back offers. 
	Forced Redemptions. You are responsible for ensuring compliance with any limitations or requirements speciﬁed by Tokenfy from time to time, including, but not limited to, requirements not to redeem Approved Rewards that exceed amounts speciﬁed by Tokenfy. Tokenfy reserves the right, in its sole discretion, from time to time, to cause the redemption of Approved Rewards, in whole or in part, and issue payments to your bank account linked to the email address or phone number that you provided Tokenfy at the time you created your account, but Tokenfy undertakes no obligation to do so under any circumstances. You hereby authorize Tokenfy to perform any such redemptions and issue any such payments to bank account linked to the email address or phone number that you provided Tokenfy at the time you created your account, automatically and without further action by you.
`,
`
•	Abuse and Cancellations.
•	Tokenfy reserves the right to cancel, suspend and/or modify the Promotion, or any part of it, or the payment of any Rewards amounts at any time without obligation or notice to you, including, without limitation, if any fraud, technical failures, limit of available Rewards, gaming, human error or any other factor impairs the integrity, availability or proper functioning of the Promotion, as determined by Tokenfy in its sole discretion. Without limiting anything  in these Promotion Terms or the Terms of Use, Tokenfy reserves the right, in its sole discretion, to disqualify, terminate or suspend the participation in any Promotion, or otherwise prevent future participation, of any individual it ﬁnds, or reasonably suspects, to be tampering with the operation of a Promotion (including by submitting or attempting to submit fraudulent or ineligible Receipts) or to be acting in violation of the Promotion Terms or any promotion,  or in behaving in an unethical, dishonest, deceptive or disruptive manner. Any attempt by any person to deliberately undermine the legitimate operation of the Promotion may be in violation of criminal and civil law, and, should such an attempt be made, Tokenfy reserves the right to seek damages from any such person to the fullest extent permitted by law. Tokenfy’s failure to enforce any term of these Promotion Terms shall not constitute a waiver of that provision. Tokenfy shall not be liable to you for any suspension, modiﬁcation or termination of a Rewards Oﬀer or the Promotion program.
•	Use of any automated means or more than one account is prohibited and will result in disqualiﬁcation. In the event of any dispute as to the qualiﬁcation of potential recipients of a Reward, the authorized account holder of the email address or phone number provided to Tokenfy at the time the account was created will be deemed the qualiﬁed Reward recipient. The "authorized account holder" is the natural person assigned an email address by an Internet access provider, online service provider or other organization responsible for assigning email addresses for the domain associated with the submitted address. Each potential recipient of Rewards may be required to show proof of being an authorized account holder.`,
`
Your Use of Content
You acknowledge that the Services contain information, software, articles, reviews, content, photographs, audio and video clips, graphics, links, logos, trademarks, the “look and feel” of our websites, applications and software and other material (collectively, the “Content”) that are protected by copyright, trademark and/or other proprietary rights of Tokenfy Technologies Limited or third parties. All Content on the Services is protected by applicable copyright laws. You agree to comply with all copyright laws and any copyright notices, information, or restrictions contained in any Content available on or accessed through the Services. Users of the Services may use the Content only for their personal, noncommercial use. Businesses, organizations or other legal entities may not become Members, and are not permitted to use the Services for any purpose, including but not limited to collecting usernames and/or email addresses of Members by electronic or other means for the purpose of sending unsolicited email and unauthorized framing of, or linking to, the Services or users on the Services. Please refer to our Linking Policy for more information on linking and use of Content.
Except as expressly permitted, you may not (i) modify, publish, transmit, reproduce, create derivative works from, distribute, perform, display, adapt, aggregate, sell, transfer or in any way exploit any of the Content, code or Material (defined below), in whole or in part, or (ii) use any robot, spider, site search and/or retrieval application, or other device to scrape, extract, retrieve or index any portion of the Services. Content consisting of downloadable or web-based software may not be reverse engineered unless specifically authorized by the owner of the software’s patent and/or copyright. You also agree not to circumvent, disable or otherwise interfere with any security related features of the Services or the Content, including features that prevent or restrict use or copying, or that enforce limitations on use.
You may only post on the Services content owned by you (such as your original statements or video clips), content for which you have received express written permission from the owner and content in the public domain (collectively, the “Material”). You assume all risk and responsibility for determining whether any Material is in the public domain. You hereby grant, transfer and assign to Tokenfy Technologies Limited and its affiliates, successors, assigns and licensees (collectively, “Licensee”) a fully-paid, royalty-free, irrevocable, perpetual, worldwide right and license to publish, distribute, reproduce, transmit, use, translate, display, perform, modify, revise, create derivative works of and archive the Material, in any form or media now known or hereafter developed (including without limitation in print, magnetic or electronic form), including, but not limited to, as part of the Services or in support of the Services through advertising or marketing, on any number of occasions in any form, and to sublicense third parties (including other users of the Services) to do any of the foregoing with further right of sublicense (the “License”), without payment or compensation to you and without seeking further permission from you. You represent and warrant that you are authorized to grant all rights set forth in the preceding sentence and that the exercise by Licensee of Licensee’s rights under the License shall not violate any laws, defame or libel any person, invade any person’s right of privacy or publicity or otherwise violate, misappropriate or infringe the rights of any person (including but not limited to any copyright or moral right). You agree that you are solely responsible for the Material that you post on the Services or transmit to others and agree that you will not hold Company responsible or liable for any materials or content you access or receive from other users of the Services.
You may download, copy and make any personal, non-commercial use of the Content and use that is expressly permitted by Nigeria Copyright Act of 2004, as amended (“Copyright Act”) and not prohibited by any section of the Copyright Act or by any other applicable law, rule or regulation; provided, however, that you maintain all copyright and other notices contained in such Content; and provided further that you shall not store electronically any significant portion of any Content.
To the extent that you provide us with any suggestions, feedback or other information relating to our business or the Services (including, but not limited to, suggested new products or services or improvements to existing products and services), such information is provided to us on a non-confidential and unrestricted basis, and you hereby grant to the Licensee (as defined above) a non-exclusive, worldwide, perpetual, royalty-free, fully transferrable and sublicensable right and license to reproduce, display, distribute, use and fully exploit such suggestions, feedback and information.
For questions regarding use of Content please email Brand_Licensing@tokenfy.io
`,
`
•	Rules of Conduct
Tokenfy Technologies Limited has the right, but not the obligation, to investigate any illegal and/or unauthorized use of the Services and appropriate legal action may be taken, including without limitation, civil, and injunctive relief. While utilizing the Services, you may not:
•	Use the Services for any commercial purpose, to distribute any advertising or solicitation of funds or goods and services, or to solicit users to join competitive online services;
•	Post on the Services any links to any external Internet sites that are obscene or pornographic, or display pornographic or sexually explicit material of any kind;
•	Post on the Services or transmit through the Services any Material that violates or infringes another person’s intellectual property rights (including, but not limited to, third party music, videos, photos or other materials where you do not have written authority from the owner to post or transmit such materials);
•	Post or transmit any Material that contains any advertising, promotional materials, “junk mail”, “spam”, “chain letters”, “pyramid schemes” or any other form of solicitation;
•	Reformat, frame or mirror any portion of any web page that is part of the Service;
•	Post Material containing restricted or password only access pages, or hidden pages or images (those not linked to or from another accessible page);
•	Submit any Material that impersonates a person or entity, falsely implies sponsorship or endorsement of that Material by the Services, Tokenfy Technologies Limited and/or any third party, falsify or delete any author attributions in any Material, or promote any information that you know is false or misleading;
•	Transmit any viruses, worms, defects, Trojan horses or other items of a contaminating or destructive nature through the Services or otherwise attempt to interfere with the proper working of the Service or the use and enjoyment of the Services by other users;
•	Promote an illegal or unauthorized copy of another person’s copyrighted work, such as providing pirated computer programs or videos or links to them, providing information to circumvent manufacture-installed copy-protect devices, or providing pirated music or links to pirated music files;
•	Submit Material, or engage in any activity, that is libelous, defamatory, obscene, pornographic, abusive, harassing, threatening, unlawful, or violates the rights of any third party (including their rights of privacy or publicity) or that promotes or encourages illegal activity, racism, bigotry, hatred, physical harm or discrimination of any kind against any group or individual; or
•	Harvest or collect email addresses or other contact information of other users by electronic or other means.
`,
`
Managing Content
Despite our rights outlined herein, you shall remain solely responsible for all Material you post. Tokenfy Technologies Limited has no obligation evaluate, to pre-screen and/or monitor the Material you post, however Tokenfy Technologies Limited reserves the right to edit or remove any Content or Material on the Services, in whole or in part, and to disclose any information or take any action necessary to satisfy any applicable law, regulation, legal process or governmental request or to protect the rights, property or safety of Tokenfy Technologies Limited, its users and the public. You understand and agree that you may be exposed to such matters and that you further waive your right to any damages (from any party) related to such exposure.
Tokenfy Technologies Limited has the right, but not the obligation, to take any of the following actions in our sole discretion at any time and for any reason without giving you any prior notice:
•	Restrict, suspend, or terminate your access to all or any part of our Services;
•	Change, suspend, or discontinue all or any part of our Services;
•	Refuse, move, edit or remove any Material for any reason;
•	Refuse, move, edit or remove any Content that is available on the Services;
•	Deactivate or delete your account(s) and all related information and files in your account(s);
•	Disclose your identity or other information about you to any third party who claims that Material posted by you violates their legal rights, including, but not limited to, their intellectual property rights or their rights of privacy or publicity;
•	Disclose your identity or other information about you to law enforcement officials upon request if Tokenfy Technologies Limited determines in its sole discretion that such disclosure is in the interest of protecting its users or the public;
•	Establish general practices and limits concerning use of our sites and Services.
You agree that Tokenfy Technologies Limited will not be liable to you or any third party for taking any of these actions. You understand and agree that our Services may include communications such as advertisements, service announcements and administrative messages from us or from our partners, and that these are considered part of the Services.
`,
`
•	Endorsement
Tokenfy Technologies Limited MAY RECEIVE A COMMISSION, FEE AND/OR OTHER COMPENSATION ON SOME CLICKS OR PURCHASES MADE ON, THROUGH OR LINKED FROM THE SERVICES.
THIS MEANS Tokenfy Technologies Limited MAY RECEIVE SOME FORM OF COMPENSATION THROUGH AN ARRANGEMENT IT HAS WITH A THIRD PARTY IF YOU (i) CLICK ON CERTAIN ADS OR LINKS ON OUR WEBSITES, EMAILS OR NEWSLETTERS, OR (ii) PURCHASE A PRODUCT OR SERVICE AFTER CLICKING A LINK.
Tokenfy Technologies Limited does not represent or endorse the accuracy or reliability of any Content or other material posted on any interactive area or elsewhere on the Services and you acknowledge that any reliance upon such Content or other material shall be at your sole risk. Any Content or other material placed on any interactive area by users represents the views of the user posting the statement, and does not represent the views of Tokenfy Technologies Limited.
The Services may contain links to sites on the Internet which are owned and operated by third parties (the “External Site(s)”). You acknowledge that Tokenfy Technologies Limited is not responsible for the availability of, or the content located on or through, any External Site, and that your use of such External Sites may be subject to a third party’s terms and conditions and/or privacy policy. Any third-party content or a link to a third-party site is not an endorsement of that content or third-party site. You should contact the site administrator or webmaster for those External Sites if you have any concerns regarding such links or the content located on such External Sites.
Typically, we do not directly sell, resell, or license any of the products or the services that we review, list, or advertise on our sites, and we disclaim any responsibility for or liability related to them. Your correspondence or related activities with third parties, including payment transactions and goods-delivery transactions, are solely between you and that third party. All product and deal information such as discount, price and availability are believed to be accurate as of the time of publication and are subject to change. Please verify these details with the merchant site and check the merchant’s terms and conditions before you buy. You agree that we will not be responsible or liable for any loss or damage of any sort incurred as the result of any of your transactions with third parties. Any questions, complaints, or claims related to any product or service should be directed to the appropriate vendor. However, in the event we do sell, resell, or license products or services, any terms and conditions related to your purchase or license of such products and services from us or any affiliates will be accessible on the applicable site page.
Please note that we may allow certain manufacturers to license, use and/or reprint a Tokenfy Technologies Limited trademark, logo, or a review in whole or in part, in its own marketing materials and advertisements in exchange for a license fee.
`,
`
•	Indemnification.
You agree to indemnify, defend and hold Tokenfy Technologies Limited and its affiliates, and their respective officers, directors, owners, agents, information providers and licensors (collectively, the “Tokenfy Technologies Limited Parties”) harmless from and against any and all claims, liability, losses, damages, costs and expenses (including attorneys’ fees) incurred by any Tokenfy Technologies Limited Party in connection with:
•			Your use of, or connection to, our Services;
•			Any use or alleged use of your accounts or your passwords by any person, whether or not authorized by you;
•			The content, the quality, or the performance of Material that you submit;
•			Your violation of the Agreement or the Tokenfy Technologies Limited Privacy Policy;
•			Your violation of the rights of any other person or entity; or
•			Your violation of any applicable laws, rules or regulations.
Tokenfy Technologies Limited reserves the right, at its own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, and in such case, you agree to cooperate with Tokenfy Technologies Limited’ defense of such claim
`,
`
•	Termination of Service
Tokenfy Technologies Limited reserves the right, in its sole discretion, to restrict, suspend or terminate your access to all or any part of the Services, including the comments areas, at any time for any reason without prior notice or liability. Conversely, you may terminate your access to the Services at any time by immediately ceasing use of the Services. Once your access terminates, you will have no right to use the Services. The terms of the Agreement shall survive any termination of your access. Tokenfy Technologies Limited may change, suspend or discontinue all or any aspect of the Services at any time, including the availability of any feature, database, or Content (including the comments areas), without prior notice or liability.
`,
`
•	Copyright Policy
You may not post, distribute, perform, display, transmit or reproduce in any way any copyrighted material, trademarks, or other proprietary information without obtaining the prior written consent of the owner of such proprietary rights. Tokenfy Technologies Limited has registered a designated agent with the Copyright Office pursuant to the laws of Nigeria. If you believe that your work has been copied and posted on the Services in a way that constitutes copyright or trademark infringement, please notify the designated agent, Tokenfy Technologies Limited, at 171 Obadina Street, Omole Phase 1, Ikeja, Lagos, Nigeria, or by email: terms@tokenfy.io and insert “Terms of Use” in the subject line.
A notification of claimed infringement must include the following:
(a) an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright or trademark interest;
(b) a description of the copyrighted work(s) or trademark(s) that you claim has been infringed;
(c) a description of where the material that you claim is infringing is located on the Services (including any additional identifying information such as URLs and post numbers to assist us in identifying the allegedly infringing material);
(d) your address, telephone number, and email address;
(e) a written statement by you that you have a good faith belief that the disputed use is not authorized by the copyright or trademark owner, its agent, or the law; and
(f) a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright or trademark owner or authorized to act on the copyright or trademark owner’s behalf.
If materials you have posted on the Services have been removed due to alleged infringement of a third party’s intellectual property rights, Tokenfy Technologies Limited will notify you. If you believe your materials have been wrongly removed, you may file a counter-notification containing the following:
(a) an electronic or physical signature of the person authorized to act on your behalf;
(b) a description of the material that has been removed or to which access has been disabled and where the material was located online before it was removed or access to it was disabled;
(c) a written statement by you that under penalty of perjury, that you have a good faith belief that the material was removed or disabled as a result of mistake or misidentification of the material to be removed or disabled; and
(d) your address, telephone number, and email address; and
(e) a statement that you consent to the jurisdiction of federal district court for the judicial district in which the address is located, or if your address is outside of Nigeria, for any judicial district in which the service provider may be found, and that you will accept service of process from the person who provided notification or an agent of such person.
Upon Tokenfy Technologies Limited’ receipt of such counter-notice, Tokenfy Technologies Limited will provide the complainant with your contact information so that you have the ability to resolve the issue. Please note that when we forward the counter-notification, it includes your personal information. If you are concerned about protecting your anonymity, please consult with an attorney about other options. If the matter has been resolved or the complainant does not seek a court order within the statutory time period, we generally will re-post the material.
Tokenfy Technologies Limited reserves the right to remove any materials or content alleged to be infringing without prior notice, at Tokenfy Technologies Limited’ sole discretion, and without liability to you.
In appropriate circumstances, Tokenfy Technologies Limited will also terminate your account if you are determined to be a repeat infringer.
Any person who knowingly misrepresents that material is infringing or that material was removed or disabled by mistake or misidentification may be subject to liability.
`,
`
•	Copyright Policy
You may not post, distribute, perform, display, transmit or reproduce in any way any copyrighted material, trademarks, or other proprietary information without obtaining the prior written consent of the owner of such proprietary rights. Tokenfy Technologies Limited has registered a designated agent with the Copyright Office pursuant to the laws of Nigeria. If you believe that your work has been copied and posted on the Services in a way that constitutes copyright or trademark infringement, please notify the designated agent, Tokenfy Technologies Limited, at 171 Obadina Street, Omole Phase 1, Ikeja, Lagos, Nigeria, or by email: terms@tokenfy.io and insert “Terms of Use” in the subject line.
A notification of claimed infringement must include the following:
(a) an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright or trademark interest;
(b) a description of the copyrighted work(s) or trademark(s) that you claim has been infringed;
(c) a description of where the material that you claim is infringing is located on the Services (including any additional identifying information such as URLs and post numbers to assist us in identifying the allegedly infringing material);
(d) your address, telephone number, and email address;
(e) a written statement by you that you have a good faith belief that the disputed use is not authorized by the copyright or trademark owner, its agent, or the law; and
(f) a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright or trademark owner or authorized to act on the copyright or trademark owner’s behalf.
If materials you have posted on the Services have been removed due to alleged infringement of a third party’s intellectual property rights, Tokenfy Technologies Limited will notify you. If you believe your materials have been wrongly removed, you may file a counter-notification containing the following:
(a) an electronic or physical signature of the person authorized to act on your behalf;
(b) a description of the material that has been removed or to which access has been disabled and where the material was located online before it was removed or access to it was disabled;
(c) a written statement by you that under penalty of perjury, that you have a good faith belief that the material was removed or disabled as a result of mistake or misidentification of the material to be removed or disabled; and
(d) your address, telephone number, and email address; and
(e) a statement that you consent to the jurisdiction of federal district court for the judicial district in which the address is located, or if your address is outside of Nigeria, for any judicial district in which the service provider may be found, and that you will accept service of process from the person who provided notification or an agent of such person.
Upon Tokenfy Technologies Limited’ receipt of such counter-notice, Tokenfy Technologies Limited will provide the complainant with your contact information so that you have the ability to resolve the issue. Please note that when we forward the counter-notification, it includes your personal information. If you are concerned about protecting your anonymity, please consult with an attorney about other options. If the matter has been resolved or the complainant does not seek a court order within the statutory time period, we generally will re-post the material.
Tokenfy Technologies Limited reserves the right to remove any materials or content alleged to be infringing without prior notice, at Tokenfy Technologies Limited’ sole discretion, and without liability to you.
In appropriate circumstances, Tokenfy Technologies Limited will also terminate your account if you are determined to be a repeat infringer.
Any person who knowingly misrepresents that material is infringing or that material was removed or disabled by mistake or misidentification may be subject to liability.
`,
`
•	Copyright Policy
You may not post, distribute, perform, display, transmit or reproduce in any way any copyrighted material, trademarks, or other proprietary information without obtaining the prior written consent of the owner of such proprietary rights. Tokenfy Technologies Limited has registered a designated agent with the Copyright Office pursuant to the laws of Nigeria. If you believe that your work has been copied and posted on the Services in a way that constitutes copyright or trademark infringement, please notify the designated agent, Tokenfy Technologies Limited, at 171 Obadina Street, Omole Phase 1, Ikeja, Lagos, Nigeria, or by email: terms@tokenfy.io and insert “Terms of Use” in the subject line.
A notification of claimed infringement must include the following:
(a) an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright or trademark interest;
(b) a description of the copyrighted work(s) or trademark(s) that you claim has been infringed;
(c) a description of where the material that you claim is infringing is located on the Services (including any additional identifying information such as URLs and post numbers to assist us in identifying the allegedly infringing material);
(d) your address, telephone number, and email address;
(e) a written statement by you that you have a good faith belief that the disputed use is not authorized by the copyright or trademark owner, its agent, or the law; and
(f) a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright or trademark owner or authorized to act on the copyright or trademark owner’s behalf.
If materials you have posted on the Services have been removed due to alleged infringement of a third party’s intellectual property rights, Tokenfy Technologies Limited will notify you. If you believe your materials have been wrongly removed, you may file a counter-notification containing the following:
(a) an electronic or physical signature of the person authorized to act on your behalf;
(b) a description of the material that has been removed or to which access has been disabled and where the material was located online before it was removed or access to it was disabled;
(c) a written statement by you that under penalty of perjury, that you have a good faith belief that the material was removed or disabled as a result of mistake or misidentification of the material to be removed or disabled; and
(d) your address, telephone number, and email address; and
(e) a statement that you consent to the jurisdiction of federal district court for the judicial district in which the address is located, or if your address is outside of Nigeria, for any judicial district in which the service provider may be found, and that you will accept service of process from the person who provided notification or an agent of such person.
Upon Tokenfy Technologies Limited’ receipt of such counter-notice, Tokenfy Technologies Limited will provide the complainant with your contact information so that you have the ability to resolve the issue. Please note that when we forward the counter-notification, it includes your personal information. If you are concerned about protecting your anonymity, please consult with an attorney about other options. If the matter has been resolved or the complainant does not seek a court order within the statutory time period, we generally will re-post the material.
Tokenfy Technologies Limited reserves the right to remove any materials or content alleged to be infringing without prior notice, at Tokenfy Technologies Limited’ sole discretion, and without liability to you.
In appropriate circumstances, Tokenfy Technologies Limited will also terminate your account if you are determined to be a repeat infringer.
Any person who knowingly misrepresents that material is infringing or that material was removed or disabled by mistake or misidentification may be subject to liability.
`,
`
•	International Use
We make no claims that any Material, Services or any Content is appropriate or may be downloaded outside of Nigeria or outside the country where such Material, Services or any Content was produced. Access to the Material, Services or any Content may not be legal in certain countries or for certain persons. If you access any Material, Services or Content from outside of Nigeria, you do so at your own risk and are responsible for compliance with the laws of your jurisdiction. You agree to comply with: (i) all local rules regarding user conduct on the Internet and acceptable content; and (ii) all applicable laws regarding the downloading and/or transmission of technical data and other material from or to Nigeria and the country in which you are located.
`,
`
•	No Submission of Unsolicited Ideas
Tokenfy Technologies Limited does not accept unsolicited ideas of any kind, including ideas for: advertising; promotions; new products, technologies, processes, or materials; marketing plans; or product names. Please do not send us any such ideas or materials relating to such ideas. This is to avoid any potential misunderstanding or dispute should our products or marketing strategies seem similar to unsolicited ideas submitted to us. If, despite this request, you do send us any unsolicited ideas or materials, we make no assurances that such ideas or materials will be treated as confidential or proprietary.
`,
`
•	Binding Arbitration
ARBITRATION NOTICE: Unless you opt out in accordance with this Section, any dispute, claim or controversy arising out of or relating to this Agreement or the breach, termination, enforcement, interpretation or validity thereof, including the determination of the scope or applicability of this agreement to arbitrate, shall be determined by arbitration in Nigeria,before a single arbitrator. Judgment on the arbitration award may be entered in any court having jurisdiction. This clause shall not preclude parties from seeking provisional remedies in aid of arbitration from a court of appropriate jurisdiction. Notwithstanding the foregoing, each party shall have the right to institute an action in a court of proper jurisdiction for injunctive or other equitable relief pending a final decision by the arbitrator.
TO THE FULLEST EXTENT PERMITTED BY LAW, YOU AGREE THAT (I) NO ARBITRATION SHALL BE JOINED WITH ANY OTHER; (II) THERE IS NO RIGHT OR AUTHORITY FOR ANY DISPUTE TO BE ARBITRATED ON A CLASS-ACTION BASIS OR TO UTILIZE CLASS ACTION PROCEDURES; AND (III) THERE IS NO RIGHT OR AUTHORITY FOR ANY DISPUTE TO BE BROUGHT IN A PURPORTED REPRESENTATIVE CAPACITY ON BEHALF OF THE GENERAL PUBLIC OR ANY OTHER PERSONS. If the specific provision of this paragraph is found to be unenforceable, then the entirety of this Section entitled “Binding Arbitration” shall be null and void.
YOU AGREE TO WAIVE YOUR RIGHT TO A JURY TRIAL AND UNDERSTAND THAT, ABSENT THIS PROVISION, YOU WOULD HAVE THE RIGHT TO SUE IN COURT. THE SCOPE OF THIS WAIVER IS INTENDED TO BE ALL-ENCOMPASSING OF ANY AND ALL DISPUTES THAT MAY BE FILED IN ANY COURT AND THAT RELATE TO THE SUBJECT MATTER OF THIS AGREEMENT, INCLUDING, WITHOUT LIMITATION, CONTRACT CLAIMS, TORT CLAIMS AND ALL OTHER COMMON LAW AND STATUTORY CLAIMS.
You may opt out of this agreement to arbitrate. If you opt out in accordance with this Section, neither you nor Tokenfy Technologies Limited can require the other to participate in an arbitration proceeding. To opt out, you must notify Tokenfy Technologies Limited in writing within thirty (30) days of the date that you first became subject to this arbitration provision. You must use this address to opt out:
Tokenfy Technologies Limited,
ATTN: LEGAL: Arbitration Opt Out
171 obadina street, 
Lagos state, Nigeria 100010
You must include your name and residence address, the email address, if any, you use for your respective Tokenfy Technologies Limited account, and a clear statement that you want to opt out of this arbitration agreement.
`,
`
•	Limitation Period for Claims.
You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of our sites or the Agreement must be filed within one (1) year after such claim or cause of action arose or be forever barred.
`,
`
•	Miscellaneous
We may be required by international, state or federal law to notify you of certain events. You hereby acknowledge and consent that such notices will be effective upon our posting them on our sites or delivering them to you through e-mail. Subject to the arbitration provision above and for all purposes of this Agreement, this Agreement shall be construed in accordance with the laws of Nigeria, without reference to principles of choice of law. You and Tokenfy Technologies Limited each irrevocably consent to the personal jurisdiction of the federal or state courts located in Nigeria.
This Agreement constitutes the entire agreement between the parties with respect to the subject matter hereof, and supersedes all previous written or oral agreements between the parties with respect to such subject matter. If any inconsistency exists between the terms of this Agreement and any additional terms and conditions posted on the Services, such terms shall be interpreted as to eliminate any inconsistency, if possible, and otherwise, the additional terms and conditions shall control. If any provision of this Agreement is held invalid, illegal or unenforceable in any respect, (i) such provision shall be interpreted in such a manner as to preserve, to the maximum extent possible, the intent of the parties, (ii) the validity, legality and enforceability of the remaining provisions shall not in any way be affected or impaired thereby, and (iii) such decision shall not affect the validity, legality or enforceability of such provision under other circumstances.
`,
`
•	Privacy Policy.
The Tokenfy Technologies Limited Privacy Policy will be made available on the property from which you accessed these Terms. Such Privacy Policy is incorporated by reference into these Terms.
QUESTIONS? Please contact contact@tokenfy.io and insert “Terms of Use” in the subject line if you have any questions or comments about the Agreement, or to report user conduct violating the Agreement.

`

                                ].map((item,index)=>(
                                    <p className="terms">{item}</p>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </section>
           <Footer />
        </>
    )
}