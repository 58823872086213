import React,{useState} from 'react'
import icons from './icons'
import MaterialTable, { Column } from 'material-table';
import { Paper } from '@material-ui/core';
import {Link} from 'react-router-dom'
import FormatDate from '../helpers/dateFormater'
import Img1 from '../Assets/Edit.png'
import Img2 from '../Assets/good.png'
import Img3 from '../Assets/Delete.png'
export default function Coupons(props) {
  const [popOver, setPopOver] = useState([])
  const [show, setShow] = useState(false)

    const handlePopOver= (id)=>{
      console.log('CLicking>>>');
      let cur = !show;
      setShow(cur);
      if (cur) {
        setPopOver([id])
      }
    }

    return (
        <div>
             <MaterialTable
             components={{
                Container: props => <Paper {...props} elevation={0}/>
           }}
                 icons={icons}
                  options={{
                    exportButton: true
                  }}
                  columns={[
                    { title: 'MERCHANTS', field: 'user' },
                    { title: 'APPROVED BY', field: 'approved' },
                    { title: 'COUPON TYPE', field: 'type' },
                    { title: 'TAG', field: 'tag' },
                    { title: ' LIMITS', field: 'limit' },
                    { title: 'CATEGORY', field: 'CATEGORY' },
                    { title: 'EXPIRING', field: 'EXPIRING' },
                    { title: 'STATUS', field: 'STATUS' },
                    { title: 'ACTIONS', field: 'dots' },
                   
                  
                  ]}

            data={props.data?props.data.map((data)=>{
                console.log(data.User)
                return(
                {

                    user: data.Merchant.merchantName,
                    approved:(`${data.User.firstName} ${data.User.lastName}`),
                    type:data.CouponType.couponType,
                    tag:data.Tag.type,
                    limit:data.limits,
                    CATEGORY:data.Category.category,
                    STATUS:<span className="restore-action-button">{data.status}</span>,
                    EXPIRING:FormatDate(data.endDate),
                    dots:<div className="showMore-dots">
                    <p style={{fontSize:"30px"}} onClick={()=>handlePopOver(data.id)}>...</p>
                    <div className={`${popOver.includes(data.id) && show === true ? "showOptions":"hideshowOptions"}`}>
                    <p><Link style={{color:"#1C9E61"}}  className="couponLink7" to={`/view_coupon/${data.id}`}>View <img style={{marginLeft:"31px"}} src={Img1}/></Link></p>
                    <p><Link  style={{color:"#2D4EF5"}} className="couponLink7" to={`/create_coupons/${data.id}/edit`}>Edit <img style={{marginLeft:"31px"}} src={Img1}/> </Link></p>
                    <p style={{color:"#1C9E61"}} className="couponLink7" onClick={()=>props.getStatus(data.status,data.id)} data-toggle="modal" data-target="#activateAndDeactivateModal" >{
                      data.status === "active" ? "Deactivate" : "Activate"
                    } <img style={{marginLeft:"5px"}} src={Img2}/></p>
                    <p style={{color:"#F28F8F"}} className="couponLink7"  onClick={()=>props.getId(data.id)} type="button" data-toggle="modal" data-target="#BlockModal">Delete <img src={Img3}/></p>
                    </div>
                    </div>,

                    })
            }):[]} 
        
          title="All Coupons"
          

        />
        </div>
    )
}
