import React from 'react'
import icons from './icons'
import MaterialTable, { Column } from 'material-table';
import {Link} from 'react-router-dom'
import { Paper } from '@material-ui/core';
import ForwardPNG from '../Assets/fwd.png';
export default function MerchantsTable(props) {
    return (
        <div>
             <MaterialTable
              components={{
                Container: props => <Paper {...props} elevation={0}/>
           }}
                 icons={icons}
                  options={{
                    exportButton: true
                  }}
                  columns={[
                    { title: 'USER', field: 'user' },
                    { title: 'EMAIL', field: 'email' },
                    { title: 'ID', field: 'id' },
                    { title: 'PHONE NUMBER', field: 'phone' },
                    { title: 'LOCATION', field: 'location' },
                    { title: 'ACTION', field: 'action' },
                  
                  ]}

            data={props.Merchants?props.Merchants.map((data)=>{
                console.log(data.id)
                return({
                    user: (`${data.merchantName}`),
                    email:(`${data.email}`),
                    id:(`${data.tokenfyId}`),
                    phone:(`${data.phoneNumber}`),
                    location: (`${data.location === null ? "Not Found": data.location}`),
                    action : (<Link to={`/merchants_overview/${data.id}`}>
                      <div>
                        <img src={`${ForwardPNG}`} className="icon-image-container" />
                      </div>
                  </Link>)
                  })
            }):[]} 
        
          title="All Merchants"
          

        />
        </div>
    )
}
