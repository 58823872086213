import React, { useEffect,useState } from 'react'
import {useRecoilState} from 'recoil'
import {toggleSidebar} from '../../globalState/localData';
import {useRecoilValue} from 'recoil';
import {getLocalItem} from '../../Components/helpers/authService';
import img1 from './img1.png'
import img2 from './img.png';
import GoBackPNG from '../Assets/icons/go_back.png';
import { useHistory } from "react-router-dom";

export const  Navbar=(props)=> {
  let [sidebarState, setSidebarState] = useRecoilState(toggleSidebar);
  let [userInformation,setUserInformation] = useState({})
  const locat = props.NavRouter
  const getUserInformation =  async () => {
    let res = await JSON.parse(getLocalItem('userInformation'));
    setUserInformation(res);
  }
  useEffect(()=>{
    getUserInformation();
  },[])
  const history = useHistory();
  return (
    <React.Fragment>

          <div style={{position:"relative"}}>
         <div id="apiDocs">
        <div className="navRoutesLogo">
          <nav className="nav-route-id">
           {
             history.goBack && (
              <span id="go-back-container" onClick={()=>history.goBack()}>
                <img src={GoBackPNG}/>
              </span>
             )
           }
            <span className="pl-1">{props.page}</span>
            <span>{props.subPage}</span>
          </nav>
        </div>

        <div className="routes-links">
          <ul>
            <li className="navbar-notifications">
            </li>
              <li className="user-name-pic">{`${userInformation.firstName} ${userInformation.lastName}`}</li>
            <li className="user-profile-pic" style={{backgroundImage: `url(${userInformation.image || userInformation.avatar})`}}>
            </li>
          </ul>
         
        </div>
       
        <div className="navRoutesActions">
        <i style={{fontSize:"24px"}} onClick={()=>setSidebarState({...sidebarState,openSidebar:!sidebarState.openSidebar,
          defaultSidebarApiState:"apiDocsCloseSidebar"})} className="fas fa-bars"></i>
  
        </div>
      </div>
      </div>
      
      
     
    </React.Fragment>
  )
}

export default Navbar
