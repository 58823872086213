import React, { Component } from 'react'
import './modal.css'
export default class ActivateAndDeactivateModal extends Component {
    constructor(props){
        super(props)
    }
    render() {
        return (
            <div>
     


<div class="modal fade" id="activateAndDeactivateModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document" style={{width:"375px"}}>
    <div class="modal-content">
     
      <div class="modal-body">
        <div className="confirmWrapper">
            <p>Are you sure you want to {this.props.CouponStatus === "active" || this.props.status === true ? "deactivate" : "activate"} this?</p>
        <button onClick={()=>this.props.handleActivate()} type="button" class="btn btn-danger" data-dismiss="modal">{this.props.CouponStatus === "active" || this.props.status === true ? "Deactivate" : "Activate"}</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
      </div>
      <div class="modal-footer">

      </div>
    </div>
  </div>
</div>
            </div>
        )
    }
}
