import React,{useState,useEffect} from 'react'
import {Layout} from '../../Components/Layout/layout'
import './cupon.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import calendar from '../../Components/Assets/calendar.png'
import Remove from '../../Components/Assets/close.png'
import {httpGet, httpPatch, httpPostFormData,httpPost, httpDelete, httpPut} from '../../Components/helpers/httpMethods'
import {hideLoader, showLoader} from '../../Components/helpers/loader'
import {NotificationManager} from 'react-notifications'
import axios from 'axios'
import moment from 'moment'
import { Filter } from '@material-ui/icons';
import ValidateImage from '../../Components/helpers/validateImage'
import ValidateVideo from '../../Components/helpers/validateVideo'
export default function CreateCupon(props) {
    const [activePage, setActivePage] = useState("Coupon_Properties")
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [tags,setTags] = useState([]);
    

    useEffect(() => {
        getData()
        if (props.match.params.type === "edit") {
            getCoupons() 
        }
    }, [])

    const [coupon, setCoupon] = useState([])

    const [createCoupon, setCreateCoupon] = useState({
        limits  : "",
        categoryId:"",
        restriction:"",
        couponType :"",
        couponWeblink:"",
        percentageAmount :"",
        code :"",
        mediaFile:"",
        merchantId:"",
        filesData:[],
        videoFile:[],
        description:"",
        editImageFile:"",
        editVideoFile:"",
        tag : "",
        redeem : "",
        pageType:"create"
    })


    const  getCoupons =async()=>{
        let id = props.match.params.id
        showLoader()
        try {
            let query = window.location.pathname.includes('submitted_coupon') ? `/admin/submitted/coupons/${id}` : `admin/coupon/${id}`;
          const res = await httpGet(query);
          let response = window.location.pathname.includes('submitted_coupon') ? res : res.coupon;
          setCreateCoupon({
                ...createCoupon,
                limits  : response.limits,
                description: response.description,
                categoryId:response.categoryId,
                restriction:response.restriction,
                couponType :response.couponTypeId,
                couponWeblink:response.couponWeblink,
                percentageAmount :response.percentageAmount,
                code :response.code,
                merchantId:response.merchantId,
                tag : response.tagId,
                redeem : response.redeem,
                pageType:"edit"

            })
            setStartDate(new Date(response.startDate || response.coupon.startDate));
            setEndDate(new Date(response.startDate || response.coupon.endDate))
            hideLoader()
        } catch (error) {
            hideLoader()
        }
    }



    const [getAllCouponType,setgetAllCouponType] = useState([])
    const [getAllcategory,setgetAllcategory] = useState([])
    const [Merchants, settMerchants] = useState([])
    const  getData =async()=>{

        try {
          showLoader()
            const res1 = await httpGet(`coupon_type/retrieve/`)
            const res2 = await httpGet(`admin/categories/`)
            const res3 = await httpGet(`admin/all_merchants?limit=1000`)
            const res4 = await httpGet(`/tag`);
            const all = await axios.all([res1, res2,res3,res4])
            setgetAllCouponType(all[0].couponType)
            setgetAllcategory(all[1].categories)
            settMerchants(all[2]);
            //console.log(all[3].tag)
            setTags(all[3].tag);
            hideLoader()
           
        } catch (error) {
          hideLoader()
        }
    }

    
    const handleSubmit=async(e)=>{
        if(createCoupon.merchantId == "" || createCoupon.categoryId == "" || createCoupon.restriction == ""
            || createCoupon.couponType == "" || createCoupon.couponWeblink == "" || createCoupon.description == ""
                || createCoupon.percentageAmount == "" || createCoupon.limits == "" || createCoupon.code == "" || createCoupon.tag == ""
                || createCoupon.redeem === ""
            ){
                return NotificationManager.error("Fields mark as * are required");
        }
        if (createCoupon.pageType==="edit"  && window.location.pathname.includes('submitted_coupon') === false){
            e.preventDefault();
            
            try {
              showLoader()
              const formData = new FormData();
              
              if (createCoupon.filesData.length > 0 ) {
                formData.append('image',  createCoupon.filesData[0])
            
            }

            if (createCoupon.videoFile.length > 0 ) {
                //formData.append('video',  createCoupon.videoFile[0])
            }
              formData.append('merchantId', createCoupon.merchantId );
              formData.append('categoryId',  createCoupon.categoryId);
              formData.append('restriction',  createCoupon.restriction);
              formData.append('couponTypeId',  createCoupon.couponType);
              formData.append('couponWeblink',  createCoupon.couponWeblink);
              formData.append('description',  createCoupon.description);
              formData.append('percentageAmount',  createCoupon.percentageAmount);
              formData.append('limits',  createCoupon.limits);
              formData.append('code',  createCoupon.code);
              formData.append('tagId',  createCoupon.tag);


              formData.append('startDate', moment(startDate).format("YYYY-MM-DD"));
              formData.append('endDate',  moment(endDate).format("YYYY-MM-DD"));
 
               let res = await httpPut(`admin/coupon/${props.match.params.id}/`,formData)
               console.log("res status",res) 
               if (res.status === 201 || res.status === 200) {
                       hideLoader()
                      
                       getCoupons() 
                       setActivePage("Coupon_Properties")
          
                NotificationManager.success(
                   "Coupon updated successfully",
                  "Yepp",
                  4000
              );
 
              
             
               }
               
                hideLoader()
          } catch (error) {
              console.log(error.response)
              NotificationManager.error(
                  error.message,
                 "Opps",
                 3000
             );
              hideLoader()
        
          }
        }
        else{
            e.preventDefault();
            try {
                showLoader()
                const formData = new FormData();
                if (createCoupon.filesData.length > 0 ) {
                    formData.append('image',  createCoupon.filesData[0])
                }
                if (createCoupon.videoFile.length > 0 ) {
                    //formData.append('video',  createCoupon.videoFile[0])
                }
                formData.append('merchantId', createCoupon.merchantId );
                formData.append('categoryId',  createCoupon.categoryId);
                formData.append('restriction',  createCoupon.restriction);
                formData.append('couponTypeId',  createCoupon.couponType);
                formData.append('couponWeblink',  createCoupon.couponWeblink);
                formData.append('description',  createCoupon.description);
                formData.append('percentageAmount',  createCoupon.percentageAmount);
                formData.append('limits',  createCoupon.limits);
                formData.append('code',  createCoupon.code);
                formData.append('startDate', moment(startDate).format("YYYY-MM-DD"));
                formData.append('endDate',  moment(endDate).format("YYYY-MM-DD"));
                formData.append('tagId',  createCoupon.tag);
                formData.append("redeem",createCoupon.redeem);
                let res = await httpPostFormData("admin/coupon/create/",formData)
                if (res.status === 201 || res.status === 200) {
                        hideLoader()
                        setCreateCoupon({
                        categoryId : "",
                        CouponName : "",
                        phoneNumber  : "",
                        email  : "",
                        location  : "",
                        description  : "",
                        logo:"",
                        filesData:[],
                        videoFile:[],
                        tag : ""
                    })
                    NotificationManager.success(
                        "Coupon created successfully",
                        "Yepp",
                        4000
                    ); 
                }
                hideLoader()
            } catch (error) {
                console.log(error.response)
                NotificationManager.error(
                    error.message,
                    "Opps",
                    3000
                );
                hideLoader();
            }
        }
    }
       
  

    
    
        const  handleChange=(e)=>{

            setCreateCoupon({...createCoupon, [e.target.name]: e.target.value })
            console.log(createCoupon)
  
       }

      const handleFileChange=async(e)=>{
            let fileType = e.target.files[0] ? e.target.files[0].type.split('/')[1] : '';
            if(fileType === ''){
                return false;
            }
            let filesDatas = createCoupon.filesData
            let VideoFileData = createCoupon.videoFile
            if (fileType === "mp4" || fileType === "mov" || fileType === "wmv" || 
                fileType === "flv") {
            if (VideoFileData.length  > 0) {
                NotificationManager.error(
                    "You can only upload not more than 1 Video",
                    "Opps",
                    4000
                );
                return
            }
          }

          else{
                if (filesDatas.length  > 0) {
                    NotificationManager.error(
                        "You can only upload not more than 1 image",
                        "Opps",
                        4000
                    );
                    return
                }
          }

       

     
        
        console.log(">>>",fileType)
        let check = await fileType === "mp4" || fileType === "mov" || fileType === "wmv" || 
        fileType === "flv" ? ValidateVideo(e.target.files) : ValidateImage(e.target.files)
        if (check === false) {
            return
        }

        else{
            fileType === "mp4" || fileType === "mov" || fileType === "wmv" || 
            fileType === "flv" ? 
            setCreateCoupon({...createCoupon,videoFile:[...createCoupon.videoFile,e.target.files[0]]}) :
            setCreateCoupon({...createCoupon,filesData:[...createCoupon.filesData,e.target.files[0]]})   
        }
      }

     const removeFile=(id,type)=>{
         if (type === "image") {
               let filesDatas = createCoupon.filesData
        let newArray = filesDatas.filter((data,index)=>{
            return(index !== id)
        })
        setCreateCoupon({...createCoupon,filesData:[...newArray]})
        console.log("filter", newArray)
         }

         else{

            let filesDatas = createCoupon.videoFile
            let newArray = filesDatas.filter((data,index)=>{
                return(index !== id)
            })
            setCreateCoupon({...createCoupon,videoFile:[...newArray]})
            console.log("filter", newArray)

         }
      

         }


    
    return (
        <div>
            <Layout pageName="Coupons" subPageName={`${createCoupon.pageType === "edit" ? "Edit Coupon": "Create Coupons"}`} 
            >
                <div className="page-router-display">

                    <div className="page-roter-list">
                        <div>
                            <div className="hsd">
                                <span
                                onClick={()=>setActivePage("Coupon_Properties")}
                                 className={`page-router-name 
                                ${activePage==="Coupon_Properties"?"page-router-name-active":""}`}>
                                    Coupon Properties</span>
                            </div>

                          <div className="hsd">
                                <span
                                onClick={(e)=>{setActivePage("Images_and_videos")}}
                                 className={`page-router-name 
                                ${activePage==="Images_and_videos"?"page-router-name-active":""}`}>
                                    Images and videos</span>
                            </div>
                        </div>
                        {/* <div><button></button></div> */}
                    </div>

                    <div className="pg-dimacation"></div>

                </div>


                {
                    activePage==="Coupon_Properties" ? (
                        <div className="create-cupon-page">

                        <div className="create-conpon-container">

                            <div className="cupon-input-box">
                                    <label htmlFor="">Merchant  <span>*</span></label>
                                    <div className="inputbox-cupon">
                                        <select required  onChange={handleChange} required name="merchantId" value={createCoupon.merchantId}>
                                         <option value="">Select</option>
                                         {
                                                Merchants?Merchants.map((data)=>{
                                                    return(
                                                <option value={data.id}>{data.merchantName}</option>)
                                                }):[]
                                            }
                                         </select>
                                         
                                    </div>

                        </div>
                        <div className="cupon-input-box">
                            <label htmlFor="">Coupon  Type <span>*</span></label>
                            <div className="inputbox-cupon">
                                <select required value={createCoupon.couponType} name="couponType" onChange={handleChange}>
                                    <option value="">Select</option>
                                    {
                                        getAllCouponType?getAllCouponType.map((data)=>{
                                            return(
                                        <option value={data.id}>{data.couponType}</option>)
                                        }):[]
                                    }
                                </select>
                            </div>        
                        </div>
                        <div className="cupon-input-box">
                                    <label htmlFor="">Percentage/Amount <span>*</span></label>
                                    <div className="inputbox-cupon">
                                    <input onChange={handleChange} required name="percentageAmount" value={createCoupon.percentageAmount} type="text"/>

                                    </div>
                                    
                        </div>


                        <div className="cupon-input-box">
                                    <label htmlFor="">Limits<span>*</span></label>
                                    <div className="inputbox-cupon">
                                        <input onChange={handleChange} required name="limits" value={createCoupon.limits} type="number"/>
                                       
                                    </div>
                                    
                        </div>

                        <div className="cupon-input-box">
                                    <label htmlFor="">Category <span>*</span></label>
                                    <div className="inputbox-cupon">
                                        <select onChange={handleChange} required name="categoryId" value={createCoupon.categoryId }>
                                        <option value="">Select</option>
                                            {
                                               getAllcategory?
                                               getAllcategory.map((data)=>{
                                                return(
                                            <option value={data.id}>{data.category}</option>)
                                            })
                                               : []
                                            }
                                         </select>
                                         
                                    </div>
                                    
                        </div>

                        
                        <div className="cupon-input-box">
                            <label htmlFor="">Start Date <span>*</span></label>
                            <div className="inputbox-cupon">
                                <DatePicker
                                selected={startDate}
                                onChange={date => setStartDate(date)}
                                minDate={new Date()}
                                />
                                <div className="icon"><img src={calendar}></img></div>      
                            </div>    
                        </div>
                        <div className="cupon-input-box">
                            <label htmlFor="">End Date <span>*</span></label>
                            <div className="inputbox-cupon">
                                <DatePicker
                                selected={endDate}
                                onChange={date => setEndDate(date)}
                                minDate={startDate}
                                />
                                <div  className="icon"><img src={calendar}></img></div>
                            </div> 
                        </div>


                        <div className="cupon-input-box">
                                    <label htmlFor="">Coupon Code<span>*</span></label>
                                    <div className="inputbox-cupon">
                                        <input onChange={handleChange} required name="code" value={createCoupon.code} type="text"/>
                                        
                                    </div>
                                    
                        </div>

                        <div className="cupon-input-box">
                                    <label htmlFor="">Coupon web link<span>*</span></label>
                                    <div className="inputbox-cupon">
                                        <input onChange={handleChange} required name="couponWeblink" value={createCoupon.couponWeblink} type="text"/>
                                        
                                    </div>
                                    
                        </div>

                        <div className="cupon-input-box">
                            <label htmlFor="">Tag<span>*</span></label>
                            <div className="inputbox-cupon">
                                <select required value={createCoupon.tag} name="tag" onChange={handleChange}>
                                    <option value="">Select</option>
                                   {
                                       tags.map(tag=>(
                                            <option value={tag.id}>{tag.type}</option>
                                       ))
                                   }
                                </select>
                            </div>        
                        </div>
                      
                                    
                      
                    </div>

                  
                    {/* CUPON DROPDOWNS END HERE */}
                    <div className="cupon-text-area-box">
                              <div className="cupon-input-box textareaCon">
                                    <label htmlFor="">Description<span>*</span></label>
                                    <div className="inputbox-cupon">
                                        <textarea onChange={handleChange} required name="description" value={createCoupon.description} type="text"/>
                                        <span className="cuponTextCount">500 <span>Characters left.</span></span>
                                    </div>
                                    
                        </div>

                        <div className="cupon-input-box textareaCon">
                                    <label htmlFor="">Restrictions<span>*</span></label>
                                    <div className="inputbox-cupon">
                                        <textarea onChange={handleChange} required name="restriction" value={createCoupon.restriction} type="text"/>
                                        <span className="cuponTextCount">500 <span>Characters left.</span></span>
                                    </div>
                                    
                        </div>
                        <div className="cupon-input-box textareaCon">
                                    <label htmlFor="">How to Redeem<span>*</span></label>
                                    <div className="inputbox-cupon">
                                        <textarea onChange={handleChange} required name="redeem" value={createCoupon.redeem} type="text"/>
                                        <span className="cuponTextCount">500 <span>Characters left.</span></span>
                                    </div>
                                    
                        </div>
          
                
            </div>


            <div className="submit-cupon-button">
                {/* <button className="activaeSubmit-cupon">Preview</button> */}
                <button onClick={(e)=>{setActivePage("Images_and_videos")}}  className="activaeSubmit-cupon">Next</button>
            </div>
                    

                  

                       

                            
            </div>
                        
                    ) :
                    
                    
                    ""
                }

              

            {
                activePage==="Images_and_videos"?
                
                <div>
                <div className="upload_form">
                    <div className="dragDropArea upload_col">
                        <form className="uploadCuponImage">
                            <span>Drag and drop file here</span>
                            <div className="upload97">or</div>
                    
                            <button className="uploadCupon-file" >Upload</button>
                            <input onChange={handleFileChange}  type="file" name="pictures"/>
                        </form>
                    </div>
    
                    <div class="upload_col viewUploadsv">

                        <div className="fileUploadinfo">
                            <h1>You can only upload not more than 2 files </h1>
                       <h2>(JPEG, MP4 of not more than 10MB)</h2>
                       {
                           createCoupon.pageType==="edit"?  <h2 style={{color:"rgba(255, 0, 0, 0.384)"}}> Warning, adding an image or a video automatically replaces the existing data </h2>
                     :""
                       }
                         </div>

                        <div className="uploads-section">
                            <h1>Uploads</h1>
                        </div>
                       
                    {
                         createCoupon.filesData.map((data,index)=>{
                            return(
                            <div className="viewUploads">
                            {/* <div className="fileType">{data?data.type.split('/'): [] }</div> */}
                            <div className="fileName">
                               <div>{data.name}</div>
                            <div>{ parseFloat(data.size)}</div>
                            </div>
                            <div className="RemoveFile">
                                <img onClick={()=>removeFile(index,"image")} src={Remove} alt="" srcset=""/>
                            </div>
                        </div>
                            )
                        
                        })
                    }

{
                        createCoupon.videoFile.map((data,index)=>{
                            return(
                            <div className="viewUploads">
                            {/* <div className="fileType">{data?data.type.split('/'): [] }</div> */}
                            <div className="fileName">
                               <div>{data.name}</div>
                            <div>{ parseFloat(data.size)}</div>
                            </div>
                            <div className="RemoveFile">
                                <img onClick={()=>removeFile(index,"video")} src={Remove} alt="" srcset=""/>
                            </div>
                        </div>
                            )
                        
                        })
                    }
                     

                      

                       
                    </div>
                </div>
                <div className="submit-cupon-button">
                    
                    <button onClick={handleSubmit}  className="publish_button">Publish</button>
                </div>
            </div>
            :""
        }
            </Layout>
        </div>
    )
}


