import React,{useState,useEffect} from 'react'
import {Layout} from '../../Components/Layout/layout'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import calendar from '../../Components/Assets/calendar.png';
import axios from 'axios';
import { httpGet, httpPost, httpPut } from '../../Components/helpers/httpMethods';
import {NotificationManager} from 'react-notifications';
import { hideLoader, showLoader } from '../../Components/helpers/loader';
import { useParams } from 'react-router-dom';
export default function AddReward(props) {
    const {user_id,reward_id} = useParams();
    const getData = async () => {
        try{
            showLoader();
            let res = httpGet('admin/all_merchants?limit=1000');
            let res1 = httpGet('/coupon_type/retrieve');
            let res2 = httpGet('/admin/categories');
            let all = await axios.all([res,res1,res2]);
            if(reward_id){
                await getReward();
            }
            setMerchants(all[0]);
            setCouponTypes(all[1].couponType);
            setCategories(all[2].categories);
            console.log('All>>>',all);
            hideLoader();
        }catch(error){
            hideLoader();
            NotificationManager.error(error.data.message);
        }
    }
    useEffect(()=>{
        getData();
    },[]);
    const [merchants,setMerchants] = useState([]);
    const [coupon_types,setCouponTypes] = useState([]);
    const [categories,setCategories] = useState([]);
    const [startDate,setStartDate] = useState('');
    const [endDate,setEndDate] = useState('');
    
    const [reward,setReward] = useState({
        merchantId: "",
        categoryId: "",
        userId: "",
        type: "",
        limit: "",
        restriction: "",
        rewardCode: ""
    });

    const onChangeHandler = (event) => {
        setReward({...reward,[event.target.name] : event.target.value})
    }
    const handleSubmit = async (event) =>{
        try{
            event.preventDefault();
            showLoader();
            if(
                reward.merchantId.trim() == "" || reward.categoryId.trim() == "" || reward.type.trim() == "" || startDate == ""
                || endDate == "" || reward.restriction.trim() == "" || reward.rewardCode.trim() == ""
             ){
                return NotificationManager.error("Fields marked with * must be filled");
            }
            let fd = {...reward,userId:user_id,startDate,endDate}
            if(reward_id){
                let res = await httpPut(`/admin/user/reward/${reward_id}`,fd);
                hideLoader();
                return NotificationManager.success("Reward successfully updated");
            }else{
                let res = await httpPost('/admin/user/reward',fd);
                setReward(
                    {
                        merchantId: "",
                        categoryId: "",
                        type: "",
                        limit: "",
                        startDate: "",
                        endDate: "",
                        restriction: "",
                        rewardCode: ""
                    }
                )
                hideLoader();
                NotificationManager.success("Reward successfully created");
            }
        }catch(error){
            hideLoader();
            console.log('Error>',error);
            error.data ?  NotificationManager.error(error.data.message) : NotificationManager.error("Netowork Error. Please check your internet");
        }
    }
    const getReward = async () => {
        try{
            let res =  await httpGet(`admin/user/reward/${reward_id}`);
            setReward({
                ...res
            });
            setStartDate(new Date(res.startDate));
            setEndDate(new Date(res.endDate));
        }catch(error){
            hideLoader();
            error.data ? NotificationManager.error(error.data.message) : NotificationManager.error('Please check your internet');;
        }
    }

    return (
        <div>
            <Layout pageName="Reward" subPageName="Add Reward">
            <form onSubmit={handleSubmit}>
                <div className="create-cupon-page">
                    <div className="create-conpon-container">
                        <div className="cupon-input-box">
                            <label htmlFor="">Merchant <span>*</span></label>
                            <div className="inputbox-cupon">
                                <select name="merchantId" value={reward.merchantId} required onChange={onChangeHandler} required>
                                    <option value=""></option>
                                {
                                        merchants.map((value,index)=>(
                                            <option value={value.id} key={index}>{value.merchantName}</option>
                                        ))
                                }
                                </select>
                            </div>   
                        </div>
                        <div className="cupon-input-box">
                            <label htmlFor="">Coupon  Type <span>*</span></label>
                            <div className="inputbox-cupon">
                                <select name="type" value={reward.type} onChange={onChangeHandler} required>
                                    <option value=""></option>
                                    {
                                        coupon_types.map((value,index)=>(
                                            <option value={value.id} key={index}>{value.couponType}</option>
                                        ))
                                    }
                                </select>
                            </div>    
                        </div>

                        <div className="cupon-input-box">
                            <label htmlFor="">Limits<span>*</span></label>
                            <div className="inputbox-cupon">
                                <input name="limit" type="text" value={reward.limit} onChange={onChangeHandler} required/>
                            </div>   
                        </div>

                        <div className="cupon-input-box">
                            <label htmlFor="">Category <span>*</span></label>
                            <div className="inputbox-cupon">
                                <select name="categoryId" onChange={onChangeHandler} value={reward.categoryId} required>
                                    <option value=""></option>
                                    {
                                        categories.map((value,index)=>(
                                            <option value={value.id} key={index}>{value.category}</option>
                                        ))
                                    }
                                </select>
                            </div>         
                        </div>


                        <div className="cupon-input-box">
                            <label htmlFor="">Start Date <span>*</span></label>
                            <div className="inputbox-cupon">
                                <DatePicker 
                                   selected={startDate} 
                                   onChange={date => setStartDate(date)  }
                                   minDate={new Date()}
                                />
                                <div className="icon"><img src={calendar}></img></div>
                            </div>      
                        </div>


                        <div className="cupon-input-box">
                            <label htmlFor="">End Date<span>*</span></label>
                            <div className="inputbox-cupon">
                                <DatePicker 
                                    selected={endDate}
                                    onChange={date => setEndDate(date)}
                                    minDate={new Date()}
                                />
                                <div  className="icon"><img src={calendar}></img></div>   
                            </div>
                        </div>


                        <div className="cupon-input-box">
                            <label htmlFor="">Coupon Code<span>*</span></label>
                            <div className="inputbox-cupon">
                                <input name="rewardCode" type="text" value={reward.rewardCode} required onChange={onChangeHandler}/>
                            </div>
                        </div>
                        
                    </div>

                    {/* CUPON DROPDOWNS END HERE */}
                    <div className="cupon-text-area-box">
                        <div className="cupon-input-box textareaCon">
                            <label htmlFor="">Restrictions<span>*</span></label>
                            <div className="inputbox-cupon">
                                <textarea type="text" name="restriction" value={reward.restriction} required onChange={onChangeHandler}/>
                                <span className="cuponTextCount">500 <span>Characters left.</span></span>
                            </div>    
                        </div>
                    </div>
                    <div className="submit-cupon-button">
                        <button className="activaeSubmit-cupon">Submit</button>
                    </div> 
                    </div>
            </form>
            </Layout>
        </div>
    )
}


