import React from 'react'
import icons from './icons'
import MaterialTable, { Column } from 'material-table';
import {Link} from 'react-router-dom'
import { Paper } from '@material-ui/core';
import ForwardPNG from '../Assets/fwd.png';
import moment from 'moment';
export default function SubscribersTable(props) {
    return (
        <div>
             <MaterialTable
              components={{
                Container: props => <Paper {...props} elevation={0}/>
           }}
                 icons={icons}
                  options={{
                    exportButton: true
                  }}
                  columns={[
                    { title: 'SN', field: 'id' },
                    { title: 'EMAIL', field: 'email' },
                    { title: 'CREATED', field: 'created_at' },
                  
                  ]}

            data={props.subscribers?props.subscribers.map((data)=>{
                return(
                {
                    id: (`${data.id}`),
                    email:(`${data.email}`),
                    created_at:(`${moment(data.created_at).format('DD MMM YYYY')}`),

                    })
            }):[]} 
        
          title="All Subscribers"
          

        />
        </div>
    )
}
